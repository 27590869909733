export default {
  "商品一括リサーチ": "Batch product research",
  "商品コード：": "Commodity code:",
  "JANコードまたはASINコード（改行区切り）": "EAN/UPC code or ASIN code (line feed delimited)",
  "※一度に入力できる文字数は最大で1000文字となります。": "The maximum number of characters that can be entered at one time is 1000.",
  "リセット": "Reset",
  "検索": "Search",
  "検索後にリセット": "Reset after search",
  "検索に一致する商品はありませんでした。": "No products matched your search.",
  "検索結果クリア": "Clear search results",
  "CSV出力": "CSV output",
  "※検索結果のうち最後に検索した100件分が履歴として保存されます。": "*The last 100 search results are saved as history.",
}
