export default {
  "Small standard-1": "小型標準-1",
  "Small standard-2": "小型標準-2",
  "Small standard-3": "小型標準-3",
  "Large standard-1": "大型標準-1",
  "Large standard-2": "大型標準-2",
  "Large standard-3": "大型標準-3",
  "Large standard-4": "大型標準-4",
  "Large standard-5": "大型標準-5",
  "Large standard-6": "大型標準-6",
  "Small oversize": "小型オーバーサイズ",
  "Medium oversize": "中型オーバーサイズ",
  "Large oversize": "大型オーバーサイズ",
  "Special oversize": "特別なオーバーサイズ",
  "FBA SAL": "小型軽量",
}
