<template>
  <div>
    <b-button ref="lockedButton" size="sm" class="btn lockedButton">{{ buttonText }}
      <font-awesome-icon v-if="icon != void 0" :icon="icon" style="color: #fff;" />
      <font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" />
    </b-button>
    <b-tooltip ref="tooltip" placement="bottom">
      {{ t('Available only in') }}
      <a v-if="nolink == void 0" :href="linkTo" class="link" target="_blank">{{ t('ERESA PRO版') }}</a><span v-else>{{ t('ERESA PRO版') }}</span>{{ t('でのみ利用可能な機能です。') }}
    </b-tooltip>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

const LINK_JP = 'https://eresa.jp/pro';
const LINK_COM = 'https://eresa.io/5zr9';

export default {
  name: "LockProButton",
  props: ['text', 'icon', 'sm', 'backgroundColor', 'nolink'],
  data() {
    return {
      linkTo: this.isComEresa() ? LINK_COM : LINK_JP,
      buttonText: '',
    };
  },
  mixins: [Utils],
  mounted() {
    if (this.sm != void 0) {
      this.$refs.lockedButton.className = 'btn lockedButton-sm';
    }
    if (this.backgroundColor != void 0) {
      this.$refs.lockedButton.style.backgroundColor = this.backgroundColor;
    }
    this.$refs.tooltip.target = this.$refs.lockedButton;
    this.buttonText = this.t(this.text);
  },
};
</script>

<style scoped>
.lockedButton, .lockedButton:hover, .lockedButton:focus {
  background-color: #376f99;
  color: #fff;
  opacity: 0.6;
}
.lockedButton-sm, .lockedButton-sm:hover, .lockedButton-sm:focus {
  background-color: #376f99;
  color: #fff;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
  opacity: 0.6;
}
</style>
<style>
.link, .link:hover {
  color: #fff;
  text-decoration: underline;
}
</style>
