// mixin/UserSettings.jsを読み込む
import { UserSettings } from '../mixins/UserSettings.js';

/**
 * 設定ページの情報を管理するクラス。
 */
export class Settings {
  
  /**
   * @constructor
   */
  constructor() {
    this.MY_SETTINGS_KEY = 'my_settings';
  }

  /**
   * localStorageに保存されている設定情報を削除します。
   */
  clearSettings() {
    localStorage.removeItem(this.MY_SETTINGS_KEY);
  }

  /**
   * 設定情報を取得します。
   * <p>localStorageに保存されている場合はそちらを返します。保存されていなければDBから取得します。</p>
   */
  async getSettings() {
    let settings = localStorage.getItem(this.MY_SETTINGS_KEY);
    if (!settings) {
      settings = await UserSettings.getValue(this.MY_SETTINGS_KEY);
      if (!settings) {
        settings = "{}";
      }
      localStorage.setItem(this.MY_SETTINGS_KEY, settings);
    }
    return JSON.parse(settings);
  }

  /**
   * 設定情報を保存します。
   * <p>localStorageおよびDBに保存します。引数がobjectの場合はjson文字列に変換されます</p>
   * @param settings
   */
  async saveSettings(settings) {
    if (typeof settings === 'object') {
      settings = JSON.stringify(settings);
    }
    localStorage.setItem(this.MY_SETTINGS_KEY, settings);
    await UserSettings.setValue(this.MY_SETTINGS_KEY, settings);
  }

  /**
   * 特定の設定を取得します。
   */
  async getSpecificSettings(key) {
    const settings = await this.getSettings();
    return settings[key] || {};
  }

  /**
   * 特定の設定を保存します。
   * @param key 保存する設定のキー
   * @param value 保存する設定の値
    */
  async saveSpecificSettings(key, value) {
    const settings = await this.getSettings();
    settings[key] = value;
    await this.saveSettings(settings);
  }

  /**
   * 検索設定を取得します。
   */
  async getSearchConditions() {
    return await this.getSpecificSettings('searchConditions');
  }

  /**
   * 検索設定を保存します。
   * @param settings 検索設定ページ設定情報
   */
  async saveSearchConditions(settings) {
    await this.saveSpecificSettings('searchConditions', settings);
  }

  /**
   * 商品詳細設定を取得します。
   */
  async getProductDetailSettings() {
    return await this.getSpecificSettings('productDetailSettings');
  }

  /**
   * 商品詳細設定を保存します。
   * @param settings 商品詳細設定ページ設定情報
   */
  async saveProductDetailSettings(settings) {
    await this.saveSpecificSettings('productDetailSettings', settings);
  }

  /**
   * 表示・並べ替え設定を取得します。
   */
  async getDisplayOrders() {
    return await this.getSpecificSettings('displayOrders');
  }

  /**
   * 表示・並べ替え設定を保存します。
   * @param settings 表示・並べ替えページ設定情報
   */
  async saveDisplayOrders(settings) {
    await this.saveSpecificSettings('displayOrders', settings);
  }

}
