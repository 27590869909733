export default {
  "アカウントを作成": "Create a new account",
  "メールアドレス": "Email address",
  "検証コード": "Verification code",
  "検証コードを入力してください": "Please enter the verification code",
  "検証コードを忘れた場合": "Lost your code?",
  "検証コードを再送信": "Resend code",
  "アカウントがある場合": "Have an account?",
  "ログインに戻る": "Back to sign in",
  "確認": "Confirmation",
}
