export const SizeTables = {
  //FBA手数料
  //単位はcm,kg,円
  sizeJp: [
    { key: 's', sname: '小型', name: '小型（厚さ2cm以下）', length: 25, width: 18, height: 2, weight: 0.25, fee: 288 },
    { key: 'm1', sname: '標準-1', name: '標準-1（厚さ3.3cm以下）', length: 35, width: 30, height: 3.3, weight: 1, fee: 318 },
    { key: 'm2', sname: '標準-2', name: '標準-2（60サイズ）', length: 45, width: 35, height: 20, size: 60, weight: 2, fee: 434 },
    { key: 'm3', sname: '標準-3', name: '標準-3（80サイズ）', length: 45, width: 35, height: 20, size: 80, weight: 5, fee: 514 },
    { key: 'm4', sname: '標準-4', name: '標準-4（100サイズ）', length: 45, width: 35, height: 20, size: 100, weight: 9, fee: 603 },
    { key: 'l1', sname: '大型-1', name: '大型-1（60サイズ）', size: 60, weight: 2, fee: 589 },
    { key: 'l2', sname: '大型-2', name: '大型-2（80サイズ）', size: 80, weight: 5, fee: 712 },
    { key: 'l3', sname: '大型-3', name: '大型-3（100サイズ）', size: 100, weight: 10, fee: 815 },
    { key: 'l4', sname: '大型-4', name: '大型-4（120サイズ）', size: 120, weight: 15, fee: 975 },
    { key: 'l5', sname: '大型-5', name: '大型-5（140サイズ）', size: 140, weight: 20, fee: 1020 },
    { key: 'l6', sname: '大型-6', name: '大型-6（160サイズ）', size: 160, weight: 25, fee: 1100 },
    { key: 'l7', sname: '大型-7', name: '大型-7（180サイズ）', size: 180, weight: 30, fee: 1532 },
    { key: 'l8', sname: '大型-8', name: '大型-8（200サイズ）', size: 200, weight: 40, fee: 1756 },
    { key: 'll1', sname: '特大型-1', name: '特大型-1（200サイズ）', size: 200, weight: 50, fee: 2755 },
    { key: 'll2', sname: '特大型-2', name: '特大型-2（220サイズ）', size: 220, weight: 50, fee: 3573 },
    { key: 'll3', sname: '特大型-3', name: '特大型-3（240サイズ）', size: 240, weight: 50, fee: 4496 },
    { key: 'll4', sname: '特大型-4', name: '特大型-4（260サイズ）', size: 260, weight: 50, fee: 5625 },
  ],
  //単位はinch,lb,ドル
  /*sizeCom: [
    { key: 'ss1', sname: 'Small standard-1', name: 'Small standard-1', length: 15, width: 12, height: 0.75, weight: 0.375, fee: 2.92 },
    { key: 'ss2', sname: 'Small standard-2', name: 'Small standard-2', length: 15, width: 12, height: 0.75, weight: 0.75, fee: 3.07 },
    { key: 'ss3', sname: 'Small standard-3', name: 'Small standard-3', length: 15, width: 12, height: 0.75, weight: 1, fee: 3.59 },
    { key: 'ls1', sname: 'Large standard-1', name: 'Large standard-1', length: 18, width: 14, height: 8, weight: 0.375, prevsize: "ss1", fee: 3.54 },
    { key: 'ls2', sname: 'Large standard-2', name: 'Large standard-2', length: 18, width: 14, height: 8, weight: 0.75, prevsize: "ss1", fee: 3.77 },
    { key: 'ls3', sname: 'Large standard-3', name: 'Large standard-3', length: 18, width: 14, height: 8, weight: 1, prevsize: "ss1", fee: 4.52 },
    { key: 'ls4', sname: 'Large standard-4', name: 'Large standard-4', length: 18, width: 14, height: 8, weight: 2, prevsize: "ss1", fee: 5.14 },
    { key: 'ls5', sname: 'Large standard-5', name: 'Large standard-5', length: 18, width: 14, height: 8, weight: 3, prevsize: "ss1", fee: 5.79 },
    { key: 'ls6', sname: 'Large standard-6', name: 'Large standard-6', length: 18, width: 14, height: 8, weight: 20, prevsize: "ss1", fee: 6.13, add: 0.3, addfirst: 3 },
    { key: 'so', sname: 'Small oversize', name: 'Small oversize', length: 60, width: 30, size: 130, weight: 70, fee: 8.94, add: 0.38, addfirst: 1 },
    { key: 'mo', sname: 'Medium oversize', name: 'Medium oversize', length: 108, size: 130, weight: 150, fee: 12.73, add: 0.44, addfirst: 1 },
    { key: 'lo', sname: 'Large oversize', name: 'Large oversize', length: 108, size: 165, weight: 150, fee: 82.58, add: 0.79, addfirst: 90 },
    { key: 'sp', sname: 'Special oversize', name: 'Special oversize', length: 108, fee: 150.94, add: 0.79, addfirst: 90 },
  ],*/
  //2022/4/28～Surcharge追加
  sizeCom: [
    { key: 'ss1', sname: 'Small standard-1', name: 'Small standard-1', length: 15, width: 12, height: 0.75, weight: 0.375, fee: 3.07 },
    { key: 'ss2', sname: 'Small standard-2', name: 'Small standard-2', length: 15, width: 12, height: 0.75, weight: 0.75, fee: 3.22 },
    { key: 'ss3', sname: 'Small standard-3', name: 'Small standard-3', length: 15, width: 12, height: 0.75, weight: 1, fee: 3.77 },
    { key: 'ls1', sname: 'Large standard-1', name: 'Large standard-1', length: 18, width: 14, height: 8, weight: 0.375, prevsize: "ss1", fee: 3.72 },
    { key: 'ls2', sname: 'Large standard-2', name: 'Large standard-2', length: 18, width: 14, height: 8, weight: 0.75, prevsize: "ss1", fee: 3.96 },
    { key: 'ls3', sname: 'Large standard-3', name: 'Large standard-3', length: 18, width: 14, height: 8, weight: 1, prevsize: "ss1", fee: 4.75 },
    { key: 'ls4', sname: 'Large standard-4', name: 'Large standard-4', length: 18, width: 14, height: 8, weight: 2, prevsize: "ss1", fee: 5.40 },
    { key: 'ls5', sname: 'Large standard-5', name: 'Large standard-5', length: 18, width: 14, height: 8, weight: 3, prevsize: "ss1", fee: 6.08 },
    { key: 'ls6', sname: 'Large standard-6', name: 'Large standard-6', length: 18, width: 14, height: 8, weight: 20, prevsize: "ss1", fee: 6.44, add: 0.32, addfirst: 3 },
    { key: 'so', sname: 'Small oversize', name: 'Small oversize', length: 60, width: 30, size: 130, weight: 70, fee: 9.39, add: 0.40, addfirst: 1 },
    { key: 'mo', sname: 'Medium oversize', name: 'Medium oversize', length: 108, size: 130, weight: 150, fee: 13.37, add: 0.46, addfirst: 1 },
    { key: 'lo', sname: 'Large oversize', name: 'Large oversize', length: 108, size: 165, weight: 150, fee: 86.71, add: 0.83, addfirst: 90 },
    { key: 'sp', sname: 'Special oversize', name: 'Special oversize', length: 108, fee: 158.49, add: 0.83, addfirst: 90 },
  ],

  //FBA小型軽量商品プログラム(FBA Small and Light)
  //単位はcm,kg,円
  salJp: [
    { key: 's', length: 25, width: 18, height: 2, weight: 0.25, fee: 208 },
    { key: 'm1', length: 35, width: 30, height: 3.3, weight: 1, fee: 220 },
  ],
  //単位はinch,lb,ドル
  /*salCom: [
    { key: 'ss1', length: 15, width: 12, height: 0.75, weight: 0.375, fee: 2.47 },
    { key: 'ss2', length: 15, width: 12, height: 0.75, weight: 0.75, fee: 2.61 },
    { key: 'ss3', length: 15, width: 12, height: 0.75, weight: 1, fee: 3.15 },
    { key: 'ls1', length: 18, width: 14, height: 8, weight: 0.375, fee: 2.66 },
    { key: 'ls2', length: 18, width: 14, height: 8, weight: 0.75, fee: 2.94 },
    { key: 'ls3', length: 18, width: 14, height: 8, weight: 1, fee: 3.77 },
    { key: 'ls4', length: 18, width: 14, height: 8, weight: 2, fee: 4.42 },
    { key: 'ls5', length: 18, width: 14, height: 8, weight: 3, fee: 5.19 },
  ]*/
};
