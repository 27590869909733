<template>
  <div class="buyboxrate">
    <template v-if="subscriber === true">
      <header class="header bg-eresa text-center w-100" v-b-toggle.collapse @click="changeIcon">
        <b-icon :icon="headerIcon"></b-icon>
        <span class="ml-4 mr-2">{{ t('カート取得率') }}</span>
        <a :href="manuallink" onclick="event.stopPropagation()" target="_blank" class="mr-4"><font-awesome-icon :icon="['fas', 'book']" style="color: #fff" /></a>
        <b-icon :icon="headerIcon"></b-icon>
      </header>
    </template>
    <template v-else-if="subscriber === false">
      <header class="header bg-eresa text-center w-100 locktitle" style="opacity: 0.6">
        <span>{{ t('カート取得率') }}<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></span>
        <lock-pro :nolink="nolink" noicon></lock-pro>
      </header>
    </template>
    <template v-if="subscriber != void 0">
      <b-collapse id="collapse">
        <div class="text-right small">
          <b-spinner v-if="loading" variant="primary" small></b-spinner>
          <span v-if="term === 30" class="term text-white ml-2 px-1">{{ t('30日') }}</span><a v-else href="javascript:void(0)" @click="selectTerm(30)" class="text-eresa ml-2">{{ t('30日') }}</a>
          <span v-if="term === 90" class="term text-white ml-2 px-1">{{ t('90日') }}</span><a v-else href="javascript:void(0)" @click="selectTerm(90)" class="text-eresa ml-2">{{ t('90日') }}</a>
          <span v-if="term === 180" class="term text-white ml-2 px-1">{{ t('180日') }}</span><a v-else href="javascript:void(0)" @click="selectTerm(180)" class="text-eresa ml-2">{{ t('180日') }}</a>
          <span v-if="term === 365" class="term text-white ml-2 px-1">{{ t('365日') }}</span><a v-else href="javascript:void(0)" @click="selectTerm(365)" class="text-eresa ml-2">{{ t('365日') }}</a>
        </div>
        <!-- PC -->
        <div class="d-none d-md-block">
          <div :class="t('buyboxtable')">
            <div class="th bg-eresa text-white text-center">{{ t('カート取得率') }}</div>
            <div class="th bg-eresa text-white pl-4">{{ t('セラーID') }}</div>
            <div class="th bg-eresa text-white text-center">{{ t('最新取得日') }}</div>
            <div class="th bg-eresa text-white text-right">{{ t('カート価格') }}</div>
            <template v-if="this.list != void 0">
              <template v-for="(listitem, i) in (showLimited ? list.slice(0, LIMITED_ROWS) : list)">
                <div class="text-right pr-4" :key="`rate${i}`">{{ listitem.rate }}%</div>
                <div class="seller pl-4" :key="`seller${i}`">
                  <div>
                    <a v-if="nolink == void 0" :href="listitem.noseller ? `https://www.amazon${domainName}/gp/product/${item.asin}` : `https://www.amazon${domainName}/sp?_encoding=UTF8&asin=${item.asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${listitem.sellerId}`" target="_blank">{{ listitem.name }}</a>
                    <div v-else>{{ listitem.name }}</div>
                  </div>
                  <div v-if="!listitem.noseller" class="d-flex">
                    <div class="sellerId small">{{ listitem.sellerId }}</div>
                    <div v-if="listitem.positiveRating != void 0" class="d-flex ml-2">
                      <div class="small">{{ t('過去12ヵ月間で{0}％が肯定的', [listitem.positiveRating]) }}</div>
                      <div class="small ml-2">{{ t('(評価：{0})', [listitem.ratingCount]) }}</div>
                    </div>
                    <b-button v-if="nolink == void 0" variant="white" class="border d-flex py-0 px-2 ml-auto mr-2" :title="t('セラーリサーチ')" @click="sellerResearch(listitem.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
                  </div>
                </div>
                <div class="pl-4" :key="`holddate${i}`">{{ listitem.holdDate }}</div>
                <div class="d-flex justify-content-end align-items-end text-right pl-4 border-left h-100" :key="`price${i}`">{{ listitem.noseller ? '' : price(listitem.price) }}</div>
              </template>
            </template>
          </div>
        </div>
        <!-- mobile -->
        <div class="d-block d-md-none">
          <div class="buyboxtable-mobile">
            <div class="th bg-eresa text-white text-center">{{ t('カート取得率') }}</div>
            <div class="th bg-eresa text-white pl-3">{{ t('セラーID') }}</div>
            <template v-if="this.list != void 0">
              <template v-for="(listitem, i) in (showLimited ? list.slice(0, LIMITED_ROWS) : list)">
                <div class="text-right pr-3" :key="`rate${i}`">{{ listitem.rate }}%</div>
                <div class="seller pl-3" :key="`seller${i}`">
                  <div>
                    <a v-if="nolink == void 0" :href="listitem.noseller ? `https://www.amazon${domainName}/gp/product/${item.asin}` : `https://www.amazon${domainName}/sp?_encoding=UTF8&asin=${item.asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${listitem.sellerId}`" target="_blank">{{ listitem.name }}</a>
                    <div v-else>{{ listitem.name }}</div>
                  </div>
                  <div v-if="!listitem.noseller" class="d-flex">
                    <div class="sellerId small">{{ listitem.sellerId }}</div>
                    <b-button v-if="nolink == void 0" variant="white" class="border d-flex py-0 px-2 ml-auto mr-2" :title="t('セラーリサーチ')" @click="sellerResearch(listitem.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
                  </div>
                  <div v-if="listitem.positiveRating != void 0" class="d-flex">
                    <div class="small">{{ t('過去12ヵ月間で{0}％が肯定的', [listitem.positiveRating]) }}</div>
                    <div class="small ml-2">{{ t('(評価：{0})', [listitem.ratingCount]) }}</div>
                  </div>
                  <div v-if="!listitem.noseller" class="small" :key="`holddate${i}`">{{ t('最新取得日：') }}{{ listitem.holdDate }} ({{ price(listitem.price) }})</div>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div class="text-right">
          <b-button v-if="list != void 0 && showLimited && list.length > LIMITED_ROWS" variant="link" @click="showLimited = false" class="readmore">{{ t('…10位以降を表示▼') }}</b-button>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import LockPro from "@/components/LockPro.vue";
import Utils from "@/mixins/utils";
import { ProductInfo } from "@/mixins/ProductInfo";

const MANUAL_JP = 'https://pro.eresa.jp/function/cartbox/';
const MANUAL_COM = 'https://eresa.io/function/cartbox/';

const AMAZON_IDS = [
'AN1VRQENFRJN5',
'A1VC38T7YXB528',
];

const AMAZONCOM_IDS = [
'ATVPDKIKX0DER',
];

const NOSELLER_IDS = [
'-1',
'-2',
];

const AMAZON_ID = 'Amazon';
const AMAZONCOM_ID = 'Amazon.com';
const NOSELLER_ID = 'カート取得者なし';

export default {
  name: 'BuyBoxRate',
  components: { LockPro },
  props: ['item', 'subscriber', 'nolink'],
  mixins: [Utils],
  data() {
    return {
      LIMITED_ROWS: 10, //初期表示件数
      domainName: this.getDomainName(this.item.domainId),
      list: null,
      headerIcon: 'chevron-down',
      loading: false,
      showLimited: true,
      term: 90,
      productInfo: new ProductInfo(this.item),
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  watch: {
    async buyBoxSellerIdHistory() {
      this.productInfo = new ProductInfo(this.item);
      if (this.list != void 0) {
        await this.makeList(this.term);
      }
    }
  },
  computed: {
    buyBoxSellerIdHistory() {
      return this.item.buyBoxSellerIdHistory;
    }
  },
  async mounted() {
  },
  methods: {
    async changeIcon() {
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
      this.showLimited = true;
      if (this.list == void 0) {
        await this.selectTerm(this.term);
      }
    },
    sellerResearch(sellerid) {
      this.$router.push({ name: "SellerResearch", params: { sellerid }, query: { domain: this.item.domainId } });
    },
    async selectTerm(days) {
      this.term = days;
      this.showLimited = true;
      this.loading = true;
      await new Promise(() => setTimeout(async () => {
        await this.makeList(this.term);
        this.loading = false;
      }, 100));
    },
    async makeList(days) {
      const map = new Map();
      const history = this.productInfo.getBuyBoxHistory(days);
      for (const sellerId of history.map(seller => seller[1].toString())) {
        let sid = sellerId;
        if (AMAZON_IDS.includes(sellerId)) {
          sid = AMAZON_ID;
        }
        else if (AMAZONCOM_IDS.includes(sellerId)) {
          sid = AMAZONCOM_ID;
        }
        else if (NOSELLER_IDS.includes(sellerId)) {
          sid = this.t(NOSELLER_ID);
        }
        if (map.has(sid)) {
          map.set(sid, map.get(sid) + 1);
        }
        else {
          map.set(sid, 1);
        }
      }
      const list = [];
      map.forEach((value, key) => list.push({
        name: [AMAZON_ID, AMAZONCOM_ID, this.t(NOSELLER_ID)].includes(key) ? key : '',
        sellerId: [AMAZON_ID, AMAZONCOM_ID, this.t(NOSELLER_ID)].includes(key) ? '' : key,
        rate: Math.round(value / history.length * 100),
        noseller: [AMAZON_ID, AMAZONCOM_ID, this.t(NOSELLER_ID)].includes(key),
        holdDate: '',
        price: null,
        ratingCount: null,
        positiveRating: null,
      }));
      const sellerList = list.filter(s => s.rate > 0).sort((a, b) => b.rate - a.rate).slice(0, 100);

      //セラー情報取得
      try {
        const sellers = sellerList.filter(s => !s.noseller);
        if (sellers.length > 0) {
          const ids = sellers.map(s => s.sellerId).join(',');
          const q = await API.graphql(graphqlOperation(queries.getSeller, { sellerId: ids, domain: this.item.domainId }));
          const sellerInfo = JSON.parse(q.data.getSeller);
          const rhist = _.chunk(this.item.buyBoxSellerIdHistory, 2).reverse();
          const rhistPrice = _.chunk(this.item.csv[ProductInfo.CSV_NAME_INDEXES.BUY_BOX_SHIPPING], 3).reverse();
          if (sellers.length === 1) {
            sellers[0].name = sellerInfo?.sellerName;
            const buyBoxSellerDatetime = rhist.find(v => v[1] === sellers[0].sellerId)[0];
            sellers[0].holdDate = moment(this.getDateFromKeepaTime(buyBoxSellerDatetime)).format(this.t('YYYY年MM月DD日'));
            sellers[0].price = this.getBuyboxPrice(rhistPrice, buyBoxSellerDatetime);
            sellers[0].ratingCount = sellerInfo?.ratingCount[2];
            sellers[0].positiveRating = sellerInfo?.positiveRating[2];
          }
          else {
            for (const seller of sellerList.filter(s => !s.noseller)) {
              seller.name = sellerInfo[seller.sellerId]?.sellerName;
              const buyBoxSellerDatetime = rhist.find(v => v[1] === seller.sellerId)[0];
              seller.holdDate = moment(this.getDateFromKeepaTime(buyBoxSellerDatetime)).format(this.t('YYYY年MM月DD日'));
              seller.price = this.getBuyboxPrice(rhistPrice, buyBoxSellerDatetime);
              seller.ratingCount = sellerInfo[seller.sellerId]?.ratingCount[2];
              seller.positiveRating = sellerInfo[seller.sellerId]?.positiveRating[2];
            }
          }
        }
      }
      catch(e) {
        console.error(e);
      }
      this.list = sellerList;
    },
    getBuyboxPrice(rhistPrice, buyBoxSellerDatetime) {
      //カート履歴（逆順）のリストから最新取得日の直近の価格（送料込み）を取得します。
      const datetimeDiff = rhistPrice.map(v => Math.abs(v[0] - buyBoxSellerDatetime));
      const min = Math.min(...datetimeDiff);
      const index = datetimeDiff.indexOf(min);
      return rhistPrice[index][1] + rhistPrice[index][2];
    },
    price(val) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
}
.buyboxtable {
  display:grid;
  grid-template-columns: 8em 1fr 10em 10em;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  align-items: end;
}
.buyboxtable-com {
  display:grid;
  grid-template-columns: 12em 1fr 12em 10em;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  align-items: end;
}
.buyboxtable > div, .buyboxtable-com > div {
  border-bottom:1px solid lightgray;
  padding: 3px;
}
.buyboxtable > div.seller, .buyboxtable-com > div.seller {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
.buyboxtable-mobile {
  display:grid;
  grid-template-columns: 7em 1fr;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  align-items: end;
}
.buyboxtable-mobile > div {
  border-bottom:1px solid lightgray;
  padding: 3px;
}
.buyboxtable-mobile > div.seller {
  border-left: 1px solid lightgray;
}
.searchicon {
  width: 10px;
}
.term {
  background-color: #b9575e;
  border-radius: 3px;
}
.sellerId {
  background-color: #f2f2f2;
  border-radius: 3px;
  padding: 2px;
}

@media (max-width: 575.98px) {
  .buyboxrate {
    font-size: 10pt;
  }
  .readmore {
    font-size: 10pt;
  }
}
</style>
