export default {
  "レビュー数": "Number of reviews",
  "レビューリサーチ": "Review research",
  "カテゴリー": "Category",
  "ランキング": "BSR",
  "JAN": "EAN/UPC",
  "コピーされました": "Copied.",
  "参考価格": "Reference price",
  "型番": "Model number",
  "メーカー": "Manufacturer",
  "発売日": "Release date",
  "取り扱い開始日": "Listed since",
  "梱包サイズ": "Packing size",
  "容積重量": "Volume weight (rounded up to the nearest 0.5 kg)",
  "バリエーション": "Variation",
  "あり": "Available",
  "なし": "Not available",
  "商品リサーチ": "Product research",
  "90日平均": "90-day average",
  "新品": "New",
  "中古": "Used",
  "AI分析": "AI Analysis",
  "productInfoText": `
#{0}Current data:

Category: {1}
Category Ranking: {2}
Amazon Price: {3}
Cartbox Price: {4}
New Lowest Price: {5}
Lowest Used Price: {6}
New Seller: {7}
Used Seller: {8}
`,
  "avg90Text": `
#Average data for the past 90 days:

Average category ranking in the last 90 days: {0}
Average lowest price of new items in the last 90 days: {1}
Average lowest price of used products in the last 90 days: {2}
Average new seller in the last 90 days: {3}
Average used sellers in the last 90 days: {4}
`,
};
