<template>
  <div id="app" v-if="/^\/applink\//.test(this.$route.path)">
    <router-view />
    <scroll-to-top></scroll-to-top>
  </div>
  <div v-else id="app">
    <b-navbar
      id="app-navbar"
      toggleable="lg"
      class="fixed-top navbar-expand-lg bg-light shadow py-2"
    >
      <div class="container">
        <b-navbar-brand href="/">
          <img v-if="isMobile" class="eresa-logo" src="/img/eresa-logo.svg" alt="" />
          <img v-else class="" src="/img/eresa-logo.svg" alt="" />
        </b-navbar-brand>

        <b-nav-form v-if="isMobile" class="ml-auto mr-3">
          <b-icon icon="search" @click="showSearch()"></b-icon>
        </b-nav-form>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="signedIn">
            <b-nav-item-dropdown :text="t('リサーチ')" class="menu-border">
              <b-dropdown-item to="/">{{ t('商品リサーチ') }}</b-dropdown-item>
              <b-dropdown-item to="/searchLite">{{ t('商品リサーチ（軽量版）') }}</b-dropdown-item>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/codeSearch">{{ t('商品一括リサーチ') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('商品一括リサーチ') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isOemUser != void 0">
                <b-dropdown-item v-if="this.$store.state.isOemUser" to="/titleSearch">{{ t('商品名一括リサーチ') }}</b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/sellerResearch">{{ t('セラーリサーチ') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('セラーリサーチ') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/seoResearch">{{ t('SEOリサーチ') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('SEOリサーチ') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/reviewResearch">{{ t('レビューリサーチ') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('レビューリサーチ') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isSubscriber != void 0 && this.subscriberType != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber && this.subscriberType.isEresaProYear" to="/keywordMining">{{ t('キーワードマイニング') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('キーワードマイニング') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon year></lock-pro></div></b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
            <b-nav-item to="/favorites" class="menu-border">{{ t('お気に入り') }}</b-nav-item>
            
            <b-nav-item-dropdown :text="t('menu_ランキング')" class="menu-border">
              <b-dropdown-item to="/categoryRanking">{{ t('TOP1000') }}</b-dropdown-item>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/categoryRankingPro">{{ t('TOP5000') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('TOP5000') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
            <template v-if="this.$store.state.isSubscriber != void 0">
              <b-nav-item v-if="this.$store.state.isSubscriber" to="/tracking" class="menu-border">{{ t('トラッキング') }}</b-nav-item>
              <b-nav-item v-else class="menu-border"><div class="locktitle">{{ t('トラッキング') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-nav-item>
              <b-nav-item v-if="this.$store.state.isSubscriber" to="/salesEstimation" class="menu-border">{{ t('売上個数予測') }}</b-nav-item>
              <b-nav-item v-else class="menu-border"><div class="locktitle">{{ t('売上個数予測') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-nav-item>
              <template v-if="!isComEresa() && $store.state.isSubscriber != void 0">
                <b-nav-item v-if="this.$store.state.isSubscriber" to="/aiAdvisor">{{ t('AIアドバイザー') }}</b-nav-item>
                <b-nav-item v-else><div class="locktitle">{{ t('AIアドバイザー') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-nav-item>
              </template>
            </template>
            <b-nav-item-dropdown class="menu-admin" v-if="this.admin" :text="t('管理')">
              <b-dropdown-item to="/admin/archive">{{ t('過去ランキング') }}</b-dropdown-item>
              <b-dropdown-item to="/admin/warning">{{ t('警告製品登録') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto" v-if="signedIn">
            <b-nav-item v-if="!isMobile"><b-icon icon="search" @click="showSearch()"></b-icon></b-nav-item>
            <b-nav-item-dropdown rigtht>
              <template #button-content>
                <b-icon icon="globe"></b-icon>
              </template>
              <b-dropdown-item @click="selectLanguage('en-US')"><b-icon v-if="languageSetting != void 0 && languageSetting.getLanguage() == 'en-US'" icon="check"></b-icon><b-icon v-else></b-icon>{{ t('英語') }}</b-dropdown-item>
              <b-dropdown-item @click="selectLanguage('ja')"><b-icon v-if="languageSetting != void 0 && languageSetting.getLanguage() == 'ja'" icon="check"></b-icon><b-icon v-else></b-icon>{{ t('日本語') }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown :text="useremail" rigtht>
              <b-dropdown-item :href="userManualLink" target="_blank">{{ t('利用マニュアル') }}</b-dropdown-item>
              <b-dropdown-item to="/account">{{ t('アカウント') }}</b-dropdown-item>
              <template v-if="this.$store.state.isSubscriber != void 0 && !isComEresa()">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/affiliate">{{ t('アフィリエイト') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('アフィリエイト') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <template v-if="this.$store.state.isSubscriber != void 0">
                <b-dropdown-item v-if="this.$store.state.isSubscriber" to="/settings">{{ t('各種設定') }}</b-dropdown-item>
                <b-dropdown-item v-else><div class="locktitle">{{ t('各種設定') }}<font-awesome-icon icon="fa-solid fa-lock" class="ml-1" /><lock-pro noicon></lock-pro></div></b-dropdown-item>
              </template>
              <b-dropdown-item @click="signOut">{{ t('ログアウト') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto" v-else>
            <b-nav-item to="/login">{{ t('ログイン') }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="page-content">
      <router-view />
    </div>
    <footer class="footer">
      <div class="container">
        <div class="d-none d-sm-block">
          <a href="/">{{ t('トップページ') }}</a> |
          <a :href="linkpages.pageTerms" target="_blank">{{ t('利用規約') }}</a> |
          <a :href="linkpages.pagePrivacyPolicy" target="_blank">{{ t('プライバシーポリシー') }}</a> |
          <a :href="linkpages.pageContactform" target="_blank">{{ t('お問い合わせ') }}</a> |
          <a :href="linkpages.pageTokushoho" target="_blank">{{ t('運営会社情報') }}</a> |
        </div>
        <div class="d-block d-sm-none">
          <a href="/">{{ t('トップページ') }}</a> |
          <a :href="linkpages.pageTerms" target="_blank">{{ t('利用規約') }}</a> |
          <a :href="linkpages.pagePrivacyPolicy" target="_blank">{{ t('プライバシーポリシー') }}</a> |
        </div>
        <div class="d-block d-sm-none">
          <a :href="linkpages.pageContactform" target="_blank">{{ t('お問い合わせ') }}</a> |
          <a :href="linkpages.pageTokushoho" target="_blank">{{ t('運営会社情報') }}</a> |
        </div>
        <div>
          <small>{{ t('Copyright (C) {0} ERESA（イーリサ） All Rights Reserved.', [new Date().getFullYear()]) }}</small>
        </div>
      </div>
    </footer>
    <select-organization ref="selectOrganization" okonly @organizationSelected="organizationSelected"></select-organization>
    <loading-screen v-if="isProcessing"></loading-screen>
    <search-control ref="searchControl"></search-control>
    <scroll-to-top></scroll-to-top>
  </div>
</template>

<script>
import { Auth, API, graphqlOperation, I18n } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import LockPro from "@/components/LockPro";
import LoadingScreen from "@/components/LoadingScreen.vue";
import SearchControl from "@/components/SearchControl.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";
import SelectOrganization, { ORG_ORGANIZATION, COM_ORG_PERSONAL, COM_ORG_ORGANIZATION } from "@/components/SelectOrganization";
import Utils, { HOSTNAME_COM } from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";

const PAGE_TERMS = 'https://eresa.jp/terms/';
const PAGE_PRIVACY_POLICY = 'https://eresa.jp/privacy-policy/';
const PAGE_CONTACTFORM = 'https://eresa.jp/contactform/';
const PAGE_TOKUSHOHO = 'https://eresa.jp/tokushoho/';
const PAGE_COM_TERMS = 'https://eresa.io/terms-condition/';
const PAGE_COM_PRIVACY_POLICY = 'https://eresa.io/privacy-policy';
const PAGE_COM_CONTACTFORM = 'https://eresa.io/contact-us';
const PAGE_COM_TOKUSHOHO = 'https://eresa.io/about';
const THNKS_PAGE_PERSONAL = 'https://eresa.jp/hyfy';
const THNKS_PAGE_ORGANIZATION = 'https://eresa.jp/9egx';
const THNKS_PAGE_COM_PERSONAL = 'https://eresa.io/vncz';
const THNKS_PAGE_COM_ORGANIZATION = 'https://eresa.io/g77r';

const USERMANUAL_JP = 'https://eresa.jp/user-manual/';
const USERMANUAL_COM = 'https://eresa.io/users-manual/';

const COM_SUCCESS_MONTH_URL = 'https://eresa.io/mz06';
const COM_SUCCESS_YEAR_URL = 'https://eresa.io/78n2';
const JP_SUCCESS_MONTH_URL = 'https://eresa.jp/mfj6';
const JP_SUCCESS_YEAR_URL = 'https://eresa.jp/3vub';

export default {
  data() {
    return {
      signedIn: false,
      subscriberType: null,
      isMobile: false,
      admin: false,
      registered: false,
      organization: null,
      membershipType: null,
      updated: false,
      linkpages: {
        pageTerms: PAGE_TERMS,
        pagePrivacyPolicy: PAGE_PRIVACY_POLICY,
        pageContactform: PAGE_CONTACTFORM,
        pageTokushoho: PAGE_TOKUSHOHO,
      },
      languageSetting: null,
      isProcessing: false,
      userManualLink: this.isComEresa() ? USERMANUAL_COM : USERMANUAL_JP,
    };
  },
  components: {
    LockPro,
    LoadingScreen,
    SelectOrganization,
    SearchControl,
    ScrollToTop,
  },
  mixins: [Utils, AuthUtil],
  computed: {
    useremail() {
      return this.$store.getters.getUser == void 0 ? '' : this.$store.getters.getUser.attributes.email;
    },
  },
  async beforeCreate() {
    AmplifyEventBus.$on("authState", async (info) => {
      if (info === "signedIn") {
        this.signedIn = true;
        this.$store.commit("setIsSubscriber", await this.isSubscriber());
        this.admin = await this.isAdmin(); 
        this.$store.commit("setIsOemUser", await this.isOemUser());
      } else {
        this.signedIn = false;
      }
    });
  },
  async created() {
    await this.initLanguage();
    document.title = I18n.get('ERESA (イーリサ)｜Amazonリサーチ・分析ツールの決定版！');
    document.description = I18n.get('ERESA (イーリサ)は、Amazonで販売されている商品のデータ(ランキング・価格推移・価格比較)を簡単に確認、分析を行うことができます。');
    if (this.isComEresa()) {
      this.linkpages.pageTerms = PAGE_COM_TERMS;
      this.linkpages.pagePrivacyPolicy = PAGE_COM_PRIVACY_POLICY;
      this.linkpages.pageContactform = PAGE_COM_CONTACTFORM;
      this.linkpages.pageTokushoho = PAGE_COM_TOKUSHOHO;
    }

    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.signedIn = true;
      this.$store.commit("setIsSubscriber", await this.isSubscriber());
      this.admin = await this.isAdmin();
      this.$store.commit("setIsOemUser", await this.isOemUser());
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  async mounted() {
    this.subscriberType = await this.getSubscriberType(false);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async updated() {
    if (this.updated) {
      return;
    }
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser == void 0) {
      return;
    }
    await this.loadUserAttributes(cognitoUser.username);
    if (this.organization == void 0) {
      this.$refs.selectOrganization.show();
    }
    else if (!this.registered) {
      API.graphql(
        graphqlOperation(mutations.updateUserInfo, {
          input: { owner: cognitoUser.username, registered: true, membershipType: null },
        })
      );
      if (this.membershipType === '1') {
        await this.checkout();
      }
      if (this.membershipType === '2') {
        await this.checkoutYear();
      }
      else {
        this.goThanksPageIfNotRegistered(cognitoUser.username);
      }
    }
    this.updated = true;
  },
  methods: {
    async signOut() {
      await Auth.signOut();
      (await this.$store.getters.getFavorites).clearCache();
      this.$store.commit('setFavorites', null);
      this.$store.dispatch("clearCache");
      this.$router.go({ path: "login", force: true });
    },
    showSearch() {
      this.$refs.searchControl.show();
    },
    handleResize() {
      const width = Math.min(window.innerWidth, window.screen.width);
      if (width <= 576) {
        this.isMobile = true;
        this.$store.commit("setIsMobile", true);
      } else {
        this.isMobile = false;
        this.$store.commit("setIsMobile", false);
      }
    },
    goThanksPageIfNotRegistered(username) {
      //if (window.location.hostname === 'localhost') return;
      if (!this.registered) {
        API.graphql(
          graphqlOperation(mutations.updateUserInfo, {
            input: { owner: username, registered: true },
          })
        );
        if (this.organization === ORG_ORGANIZATION) {
          window.location.href = THNKS_PAGE_ORGANIZATION;
        }
        else if (this.organization === COM_ORG_PERSONAL) {
          window.location.href = THNKS_PAGE_COM_PERSONAL;
        }
        else if (this.organization === COM_ORG_ORGANIZATION) {
          window.location.href = THNKS_PAGE_COM_ORGANIZATION;
        }
        else {
          window.location.href = THNKS_PAGE_PERSONAL;
        }
      }
    },
    async checkout() {
      this.isProcessing = true;
      try {
        const stripeDomain = this.$store.getters.getStripeDomain;
        const stripeInfo = this.$store.getters.getStripeInfo;
        const returnUrl = window.location.href.replace(/[?#].*$/, '');
        const successUrl = this.isComEresa() ? COM_SUCCESS_MONTH_URL : JP_SUCCESS_MONTH_URL;
        const portal = await API.graphql(graphqlOperation(queries.getStripeBillingPortal, { returnUrl, priceId: stripeInfo.priceIdMonth, successUrl, stripeDomain }));
        location.href = JSON.parse(portal.data.getStripeBillingPortal).url;
      }
      catch {
        this.isProcessing = false;
      }
    },
    async checkoutYear() {
      this.isProcessing = true;
      try {
        const stripeDomain = this.$store.getters.getStripeDomain;
        const stripeInfo = this.$store.getters.getStripeInfo;
        const returnUrl = window.location.href.replace(/[?#].*$/, '');
        const successUrl = this.isComEresa() ? COM_SUCCESS_YEAR_URL : JP_SUCCESS_YEAR_URL;
        const portal = await API.graphql(graphqlOperation(queries.getStripeBillingPortal, { returnUrl, priceId: stripeInfo.priceIdYear, successUrl, stripeDomain }));
        location.href = JSON.parse(portal.data.getStripeBillingPortal).url;
      }
      catch {
        this.isProcessing = false;
      }
    },
    async organizationSelected(value) {
      this.organization = value;
      const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
      API.graphql(graphqlOperation(mutations.updateUserAttributes, { organization: this.organization })).catch((err) => console.error(err));
      this.goThanksPageIfNotRegistered(cognitoUser.username);
    },
    async loadUserAttributes(username) {
      const query = await API.graphql(graphqlOperation(queries.getUserInfo, { owner: username }));
      const userInfo = query.data.getUserInfo;
      if (userInfo != void 0) {
        this.registered = userInfo.registered;
        this.organization = userInfo.organization;
        this.membershipType = userInfo.membershipType;
      }
    },
    async selectLanguage(lang) {
      if (this.languageSetting == void 0 || lang === this.languageSetting.getLanguage()) {
        return;
      }
      this.isProcessing = true;
      try {
        await this.languageSetting.setLanguage(lang);
        window.location.href = '/';
      }
      catch {
        this.isProcessing = false;
      }
    },
    async initLanguage() {
      this.languageSetting = new LanguageSetting();
      const lang = await this.languageSetting.initLanguage();
      if (lang == 'ja' || lang == 'en-US') {
        I18n.setLanguage(lang);
      }
    },
  }
};

class LanguageSetting {
  constructor() {
    this.key = 'app.language';
    this.lang = null;
  }

  async initLanguage() {
    //const navigatorLang = (navigator.language) ? navigator.language : navigator.userLanguage;
    const defaultLang = window.location.hostname === HOSTNAME_COM ? 'en-US' : 'ja';
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      const query = await API.graphql(graphqlOperation(queries.getUserSettings, { owner: cognitoUser.username }));
      this.lang = query?.data?.getUserSettings?.settings?.find(item => item.key == this.key)?.value ?? defaultLang;
    }
    return this.lang;
  }

  getLanguage() {
    return this.lang;
  }

  async setLanguage(lang) {
    await API.graphql(graphqlOperation(mutations.updateUserSetting, { key: this.key, value: lang })).catch((err) => console.error(err));
    this.lang = lang;
  }
}
</script>

<style scoped>
#nav-collapse {
  font-size: 0.9rem;
}

.footer {
  background-color: #376f99;
  color: white;
  margin-top: 10px;
  padding: 5px 0;
  text-align: center;
}
.footer a {
  color: white;
}

.menu-border {
  border-right: 2px solid #ccc
}

.menu-admin {
  border-left: 2px solid #ccc
}

.page-content {
  min-height: 480px;
}

@media (max-width: 575.98px) {
  .menu-border {
    border-right: 0px solid transparent
  }
  .page-content {
    min-height: 320px;
  }
  .footer {
    font-size: 0.75rem;
  }
}
</style>
