<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex max-w-md">
        <b-form-input class="newLink" v-model="newLink"></b-form-input>
        <b-button class="btn bg-eresa text-white ml-2 newLink text-nowrap" size="sm" @click="addSharedLink">{{ t('共有された条件を追加') }}</b-button>
      </div>
      <span class="text-secondary small">{{ t('※共有された条件のリンクを追加することで同じ内容の条件を作成することができます。') }}</span>
    </div>

    <div v-if="list != void 0 && list.length > 0" class="text-right mt-2">
      <b-button class="btn clear text-white mr-2" :disabled="disabledClear ? disabledClear : void 0" @click="clear"><font-awesome-icon icon="fa-sharp fa-reguler fa-eraser" style="color: #fff;" /> {{ t('検索条件をクリア') }}</b-button>
    </div>

    <div v-if="list != void 0">
      <div class="card shadow border-0 pb-4">
        <template v-for="(item, i) in list">
          <div :class="i > 0 ? 'd-flex justify-content-between mt-3 pt-3 border-top' : 'd-flex justify-content-between pt-3'" :key="`content-${i}`">
            <router-link :to="{ name: 'Default', query: { conditon: item.id, domain: $store.getters.getDomain } }">
              <div class="title">{{ item.name }}</div>
            </router-link>
            <div class="mr-4 d-flex align-items-center flex-end">
              <template v-if="item.shareVisible === true">
                <b-form-input class="mr-2 sharelink" style="width: 200px" :value="item.shareLink" readonly plaintext></b-form-input>
                <b-icon icon="clipboard" :id="`link-copy-${i}`" class="p2 mr-2" @click="copy(item, i)"></b-icon>
                <b-tooltip ref="tooltipCopy" :target="`link-copy-${i}`" :show.sync="tooltipCopy[i]" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
              </template>
              <b-button variant="white" class="text-eresa share mr-2" size="sm" @click="share(item)">{{ t('共有') }}</b-button>
              <b-button variant="white" class="text-eresa edit mr-2" size="sm" @click="editName(item)">{{ t('条件名を変更') }}</b-button>
              <b-button variant="white" class="text-eresa delete" size="sm" @click="deleteItem(item)">{{ t('削除') }}</b-button>
            </div>
          </div>
        </template>
      </div>
    </div>

    <b-modal ref="saveCondition" @ok="saveCondition" :title="t('条件名を変更')" :ok-title="t('保存する')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <b-form-group>
            <b-form-input class="mt-2" v-model="editItem.name" :placeholder="t('条件名を入力してください')" maxlength="100"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SearchConditions } from '@/store/SearchConditions';
import Utils from '@/mixins/utils';

export default {
  name: 'SearchConditionsPage',
  components: {
  },
  mixins: [Utils],
  props: ['show'],
  data() {
    return {
      list: null,
      disabledClear: true, // 検索条件をクリア非活性フラグ
      editItem: { name: '' },
      tooltipCopy: [],
      newLink: '',
    }
  },
  async mounted() {
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain() {
      if (this.list != void 0) {
        this.load();
      }
    },
    show() {
      if (this.show) {
        this.load();
      }
    }
  },
  methods: {
    async load() {
      this.disabledClear = true;
      const username = this.$store.state.user.username;
      const domain = this.$store.getters.getDomain;
      const conds = (await SearchConditions.load(username, domain));
      this.list = [];
      this.list.push(...conds.reverse());
      this.initConds(this.list);
      this.disabledClear = false;
    },
    initConds(list) {
      this.tooltipCopy = [];
      list.forEach(item => {
        item.shareVisible = false;
        this.tooltipCopy.push(false);
      });
    },
    async clear() {
      const username = this.$store.state.user.username;
      await SearchConditions.clear(username);
      this.list = [];
    },
    async editName(item) {
      this.editItem = { ...item };
      this.$refs.saveCondition.show();
    },
    async deleteItem(item) {
      const username = this.$store.state.user.username;
      const domain = this.$store.getters.getDomain;
      const conds = (await SearchConditions.load(username, domain)).filter(e => e.id !== item.id);
      await SearchConditions.update(username, conds);
      this.list = [];
      this.list.push(...conds.reverse());
      this.initConds(conds);
    },
    async saveCondition(bvModalEvent) {
      if (this.editItem.name.length > 0) {
        const username = this.$store.state.user.username;
        const domain = this.$store.getters.getDomain;
        const conds = (await SearchConditions.load(username, domain)).map(e => {
          if (e.id === this.editItem.id) {
            e.name = this.editItem.name;
          }
          return e;
        });
        await SearchConditions.update(username, conds);
        this.list = [];
        this.list.push(...conds.reverse());
        this.initConds(conds);
      }
      else {
        bvModalEvent.preventDefault();
      }
    },
    async share(item) {
      if (item.shareLink == void 0) {
        let port = '';
        if (location.port !== '' && location.port !== '80') {
          port = `:${location.port}`;
        }
        const newId = await SearchConditions.addSharedSearchCondition(this.$store.state.user.username, item.id);
        this.$set(item, 'shareLink', `${location.protocol}//${location.hostname}${port}/?shared_conditon=${newId}&domain=${item.domain}`);
        this.$set(item, 'shareVisible', true);
      }
    },
    copy(item, i) {
      navigator.clipboard.writeText(item.shareLink).catch((e) => console.error(e));
      setTimeout(() => this.$refs.tooltipCopy[i].show = false, 1000);
    },
    async addSharedLink() {
      if (this.newLink === '') {
        return;
      }
      const match = this.newLink.match(/shared_conditon=([^&]+)/);
      if (match == void 0) {
        return;
      }
      const shared_conditon = match[1];
      const r = await SearchConditions.loadSharedSearchCondition(shared_conditon);
      if (r != void 0) {
        await SearchConditions.add(this.$store.state.user.username, r.name, JSON.parse(r.condition), r.domain);
        await this.load();
      }
    },
  }
}
</script>
<style scoped>
.clear {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.title {
  font-weight: bold;
  margin-top: 6px;
  margin-left: 6px;
}

.share, .edit, .delete {
  border-color: #ddd;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.sharelink {
  font-size: 0.8rem;
}

.newLink {
  font-size: 0.8rem;
}
</style>
