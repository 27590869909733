/**
 * AiButtons定義
 * @param {string} name ボタン名
 * @param {string} label ショップラベル（便宜上定義してあるが、表示にはmessagesで定義したものを使用する）
 */

class AiButton {
    constructor(name, label) {
        this.name = name;
        this.label = label;
    }
}

const AiButtons = Object.freeze({
    optimizeProductTitles: new AiButton('optimizeProductTitles', '商品タイトルの最適化'),
    generateProductSpecificationText: new AiButton('generateProductSpecificationText', '商品仕様文の生成'),
    optimizeProductSpecificationText: new AiButton('optimizeProductSpecificationText', '商品仕様文の最適化'),
    suggestDifferentiation: new AiButton('suggestDifferentiation', '差別化ポイントの提案'),
    suggestSeoKeywords: new AiButton('suggestSeoKeywords', 'SEOキーワードの提案'),
});

export default AiButtons;
