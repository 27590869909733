<template>
  <div class="page-content">
    <div class="container">
      <h2 class="pb-1">{{ t('出品者一覧') }}</h2>
      <b-row align-h="start" class="pb-1">
        <b-col cols="3" sm="1">
          <img
            v-if="imageFile"
            width="80"
            :src="`https://images-na.ssl-images-amazon.com/images/I/${imageFile}`"
          />
        </b-col>
        <b-col>
          <h3 class="pb-1">
            <b-button
              variant="link"
              :href="`https://www.amazon${domainName}/dp/${item.asin}/ref=nosim`"
              target="_blank"
              class="text-left"
              >{{ item.title }}</b-button
            >
          </h3>
        </b-col>
      </b-row>
    </div>

    <div class="container mb-5">
      <div v-if="offers">
        <offer-list
          v-if="offers.length > 0"
          :offers="offers"
          :asin="asin"
          ref="offerList"
        ></offer-list>
        <div v-else>{{ t('対象なし') }}</div>
      </div>
      <loading v-else></loading>
    </div>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import OfferList from "@/components/OfferList";
import Loading from "@/components/Loading.vue";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";

export default {
  name: "Offers",
  components: { OfferList, Loading },
  data() {
    return {
      asin: "",
      imageFile: "",
      item: {},
      offers: null,
      selected: [],
      domainName: this.$store.getters.getDomainName,
    };
  },
  mixins: [Utils],
  filters: {},
  created() {
    const isCom = this.isComDomain(this.$route.query.domain);
    this.$store.commit("setDomainCom", isCom);
    this.domainName = this.$store.getters.getDomainName;
  },
  async mounted() {
    this.asin = this.$route.params.asin;
    const isAll = !!this.$route.query.isAll;
    const isUsed = !!this.$route.query.isConditionUsed;
    const isNew = !!this.$route.query.isConditionNew;
    /*
    const isPrime = !!this.$route.query.isPrime;
    const isFreeShipping = !!this.$route.query.isFreeShipping;
    const conditionFilter = ((query) => {
      if (query.isConditionNew) return 1;
      else if (query.isConditionUsedLikeNew) return 2;
      else if (query.isConditionUsedVeryGood) return 3;
      else if (query.isConditionUsedGood) return 4;
      else if (query.isConditionUsedAcceptable) return 5;
      else return 0;
    })(this.$route.query);
    */
    const domain = this.$store.getters.getDomain;
    this.getProductDetail(this.asin, domain)
      .then((item) => {
        if (item) this.item = item;
        if (item.imagesCSV) this.imageFile = item.imagesCSV.split(",")[0];
        if (item.liveOffersOrder) {
          const offers = item.liveOffersOrder.map((idx) => item.offers[idx]);
          if (isAll) {
            this.offers = offers;
          } else if (isUsed) {
            this.offers = offers.filter(
              (offer) => [2, 3, 4, 5].indexOf(offer.condition) > -1
            );
          } else if (isNew) {
            this.offers = offers.filter((offer) => offer.condition === 1);
          }
          /*
          console.log(offers);
          if (isPrime) this.offers = offers.filter((offer) => offer.isPrime);
          else if (isFreeShipping)
            this.offers = offers.filter(
              (offer) => this.getCurrentShipping(offer.offerCSV) === 0
            );
          else
            this.offers = offers.filter(
              (offer) => offer.condition === conditionFilter
            );
          */
        }
        if (this.offers === null) this.offers = [];
      })
      .catch((err) => console.error(err));
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  watch: {},
  computed: {},
  methods: {
    async getProductDetail(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: true, domain })
      ).then((query) =>
        query.data.getProductDetail.json
          ? JSON.parse(query.data.getProductDetail.json)[0]
          : {}
      );
    },
    getCurrentShipping(csv) {
      if (!csv) return "-";
      // console.log(csv[csv.length - 1] === 0);
      return csv[csv.length - 1];
    },
  },
};
</script>
<style scoped>
</style>