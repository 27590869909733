export default {
  "メールアドレス変更": "Change email address",
  "変更したいメールアドレスを入力してください。変更後のメールアドレス宛てに検証コードを送信します。": "Enter the email address you wish to change. A verification code will be sent to the changed e-mail address.",
  "新メールアドレス": "New email address",
  "検証コード送信": "Verification code transmission",
  "メールに記載されている検証コードを入力してください。メールアドレスの変更後は旧メールアドレスでのログインはできなくなります。": "Please enter the verification code provided in the email. After changing your e-mail address, you will not be able to log in with your old e-mail address.",
  "検証コード": "Verification code",
  "メールアドレスの変更が完了しました。": "Your email address has been changed.",
  "新メールアドレスを入力してください。": "Please enter your new email address.",
  "新メールアドレスが現在のメールアドレスと同じです。": "The new email address is the same as the current email address.",
  "メールアドレスの変更に失敗しました。": "Email address change failed."
}
