<template>
  <div class="page-content">
    <div class="container">
      <div class="d-flex">
        <manual-link :href="manuallink">{{ t('カテゴリーランキングTOP5000') }}</manual-link>
      </div>

      <div class="d-flex flex-wrap">
        <!--  カテゴリー -->
        <b-dropdown :text="category.name" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small m-2 d-sm-none">
          <b-dropdown-item v-for="item in categoryList" :key="item.key" class="small" @click="categorySelected(item)">
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
        <!--  期間 -->
        <b-dropdown :text="rangeLabelList[rangeIndex]" class="dropdown-eresa small m-2 d-sm-none">
          <b-dropdown-item v-for="(item, i) in rangeLabelList" :key="i" @click="rangeSelected(i)">
            {{ item }}
          </b-dropdown-item>
        </b-dropdown>
        <!--  カテゴリー -->
        <b-dropdown :text="category.name" split split-variant="outline-primary" variant="dark" class="dropdown-eresa m-2 d-none d-sm-inline-block">
          <b-dropdown-item v-for="item in categoryList" :key="item.key" @click="categorySelected(item)">
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
        <!--  期間 -->
        <b-dropdown :text="rangeLabelList[rangeIndex]" class="dropdown-eresa m-2 d-none d-sm-inline-block">
          <b-dropdown-item v-for="(item, i) in rangeLabelList" :key="i" @click="rangeSelected(i)">
            {{ item }}
          </b-dropdown-item>
        </b-dropdown>
        <!-- アーカイブ年月 -->
        <template v-if="isArchiveRange()"> 
          <b-dropdown :text="selectedYear || '対象年'" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small m-2 d-sm-none">
            <b-dropdown-item v-for="(item, i) in archivedYear" :key="i" @click="yearSelected(item)">
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown :text="selectedMonth || '対象月'" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small m-2 d-sm-none">
            <b-dropdown-item v-for="(item, i) in archivedMonth" :key="i" @click="monthSelected(item)">
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown :text="selectedYear || '対象年'" split split-variant="outline-primary" variant="dark" class="dropdown-eresa m-2 d-none d-sm-inline-block">
            <b-dropdown-item v-for="(item, i) in archivedYear" :key="i" @click="yearSelected(item)">
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown :text="selectedMonth || '対象月'" split split-variant="outline-primary" variant="dark" class="dropdown-eresa m-2 d-none d-sm-inline-block">
            <b-dropdown-item v-for="(item, i) in archivedMonth" :key="i" @click="monthSelected(item)">
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <button id="search-category" type="button" class="btn bg-eresa text-white px-3 btn-secondary mt-2 mb-2" @click="search">{{ t('検索') }}</button>
        <div class="updateAt text-secondary ml-2">{{ updateAtLabel }}</div>
      </div>
      <span class="text-secondary small">{{ t('※結果が表示されない場合は何回か検索ボタンをクリックしてください。') }}</span>
    </div>

    <div v-if="asins != void 0 && asins.length > 0" class="container">
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button v-if="subscriber === true" class="btn aianalysis bg-eresa text-white" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" sm></lock-pro-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button v-if="subscriber === true" class="btn aianalysis bg-eresa text-white" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" sm></lock-pro-button>
        </div>
      </div>
    </div>

    <div v-if="asins != void 0 && asins.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultList" :rankStart="pagination.start + 1" :rankName="[t('現在'), t('過去30日'), t('過去90日'), t('過去180日')][lastRangeIndex]" :asins="asins" :key="resultListKey" :domainId="lastCategory.domain" @loaded="loaded"></result-list>
        </div>
      </div>
    </div>

    <div v-if="asins != void 0 && asins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
    </div>

    <Loading v-else-if="loading"></Loading>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import {Auth, API, graphqlOperation} from 'aws-amplify';
import moment from 'moment'
import * as queries from '@/graphql/queries';
import ManualLink from '@/components/ManualLink.vue';
import ResultList from '@/components/ResultList.vue'
import LockProButton from '@/components/LockProButton.vue';
import Loading from "@/components/Loading.vue";
import AiChatWindow from "@/components/AiChatWindow";
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
import { AIChat } from "@/mixins/AIChat";
import { Category } from "@/mixins/Category.js";
import { CategoryRankingArchive } from "@/store/CategoryRankingArchive.js";

const MANUAL_JP = 'https://pro.eresa.jp/function/category-top1000/aboutcategory/';
const MANUAL_COM = 'https://eresa.io/function/category-top1000/aboutcategory/';

const MAX_ROWS = 50;
const DATA_LIMIT = 5000;
const categoryNameUnselected = 'カテゴリーを選択してください';

export default {
  name: 'CategoryRankingPro',
  components: {
    ManualLink,
    ResultList,
    LockProButton,
    Loading,
    AiChatWindow,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      page: this.$route.query.page ?? 1,
      pagination: {
        count: 0,
        countPerPage: MAX_ROWS,
        lastpage: 1,
        start: 0,
        end: 0,
      },
      category: { id: null, name: this.t(categoryNameUnselected), domain: null },
      asins: [],
      asinsAll: null,
      resultListKey: 0,
      loading: false,
      categoryList: null,
      rangeIndex: 1,
      rangeLabelList: [this.t('現在ランク'), this.t('過去30日平均'), this.t('過去90日平均'), this.t('過去180日平均'), this.t('アーカイブ')],
      rangeList: [0, 30, 90, 180, -1],
      archivedYear: [],
      archivedMonth: [],
      selectedYear: null,
      selectedMonth: null,
      monthIndex: {},
      archiveIdIndex: {},
      updateAtLabel: '',
      lastCategory: { id: null, name: null, domain: null },
      lastRangeIndex: 1,
      lastSelectedYear: null,
      lastSelectedMonth: null,
      disabledAiAnalysis: true, // AI分析非活性フラグ
      subscriber: null,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async created() {
    const domain = this.$store.getters.getDomain;
    await this.initializeCategoryList(domain);

    const categoryId = this.$route.params.categoryId ?? null;
    const target = this.categoryList.filter(item => item.key === categoryId);
    if (target.length > 0) {
      this.category = { id: categoryId, name: target[0].name, domain: target[0].domain };
      this.search();
    }
  },
  async mounted() {
    await this.validateSubscriber();
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
    const searchConditions = await this.$store.getters.getSearchConditions;

    //設定が存在する場合は設定を反映
    if (searchConditions) {
      this.selectedItemCount = searchConditions.defaultItemCount;
      this.rangeIndex = searchConditions.defaultRankingRange;
    }
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain(val) {
      this.initializeCategoryList(val);
    },
    async page() {
      if (this.category.id != this.lastCategory.id) {
        this.category = { id: this.lastCategory.id, name: this.lastCategory.name, domain: this.lastCategory.domain };
      }
      if (this.rangeIndex != this.lastRangeIndex) {
        this.rangeIndex = this.lastRangeIndex;
      }
      this.$store.commit("setDomain", this.category.domain);
      await this.showList();
    },
  },
  methods: {
    // rangeがアーカイブを選択しているか判定
    isArchiveRange() {
      return this.rangeList[this.rangeIndex] === -1;
    },
    async search() {
      if (this.category.id == void 0) {
        return;
      }
      
      // アーカイブを選択している場合は年月を選択しているか判定
      if (this.isArchiveRange() && (this.selectedYear == null || this.selectedMonth == null)) {
        return;
      }

      this.disabledAiAnalysis = true;
      this.page = 1;
      this.resultListKey++;
      this.asinsAll = null;
      this.asins = [];
      this.lastCategory = { id: this.category.id, name: this.category.name, domain: this.category.domain };
      this.lastRangeIndex = this.rangeIndex;
      this.lastSelectedYear = this.selectedYear;
      this.lastSelectedMonth = this.selectedMonth;
      this.$store.commit("setDomain", this.lastCategory.domain);
      this.showList();
    },
    async showList() {
      this.disabledAiAnalysis = true;
      if (this.asinsAll == void 0) {
        this.loading = true;
        try {
          if(this.isArchiveRange()) {
            const archiveIndexKey = `${this.lastSelectedYear}-${this.lastSelectedMonth}`;
            const archive = await CategoryRankingArchive.getCategoryRankingArchive(this.archiveIdIndex[archiveIndexKey]);
            this.asinsAll = archive.asins;
          }
          else {
            this.asinsAll = await this.getRankingAsinList(this.lastCategory, this.lastRangeIndex);
          }
        }
        finally {
          this.loading = false;
        }
      }
      this.setUpdatedAtLabel(this.lastCategory.id, this.rangeList[this.lastRangeIndex]);
      this.resultListKey++;
      this.asins = [];
      this.makePagination(this.asinsAll.length);
      this.asinsAll.slice(this.pagination.start, this.pagination.end).forEach(a => this.asins.push(a));
    },
    async getRankingAsinList(category, rangeIndex) {
      try {
        const range = this.rangeList[rangeIndex];
        const rslt = await API.graphql(
          graphqlOperation(queries.getCategoryRanking, { categoryId: category.id, range, domain: category.domain, limit:DATA_LIMIT })
        );
        const res = JSON.parse(rslt.data.getCategoryRanking)
        return res.map(asin => asin.asin);
      }
      catch(e) {
        //console.warn(e);
        return [];
      }
    },
    makePagination(count) {
      this.pagination.count = count;
      this.pagination.lastpage = parseInt(Math.ceil(this.pagination.count / this.pagination.countPerPage));
      this.pagination.start = (this.page - 1) * this.pagination.countPerPage;
      if (this.page == this.pagination.lastpage) {
        this.pagination.end = this.pagination.count;
      }
      else {
        this.pagination.end = this.pagination.start + this.pagination.countPerPage;
      }
    },
    categorySelected(item) {
      const categoryId = item.key;
      const target = this.categoryList.filter(item => item.key === categoryId);
      if (target.length > 0) {
        this.category = { id: categoryId, name: target[0].name, domain: target[0].domain };
        this.setUpdatedAtLabel();
        this.initializeArchiveRange(categoryId);
      }
    },
    rangeSelected(index) {
      this.rangeIndex = index;
      this.setUpdatedAtLabel();
    },
    yearSelected(item) {
      this.selectedYear = item;
      this.selectedMonth = null;      
      this.archivedMonth = this.monthIndex[item];
    },
    monthSelected(item) {
      console.log(item);
      this.selectedMonth = item;
    },
    async initializeArchiveRange(categoryId) {
      const archive = await CategoryRankingArchive.getCategoryRankingRegisteredMonths(categoryId);
      const registeredMonths = archive.registeredMonths;
      
      // monthでsort
      registeredMonths.sort((a, b) => {
        if (a.month < b.month) return -1;
        if (a.month > b.month) return 1;
        return 0;
      });

      // registeredMonths.monthをキーにしてarchiveIdのインデックスを貼る
      const archiveIdIndex = {};
      const monthIndex = {};
      const currentDomain = this.$store.getters.getDomain;
      for (let i = 0; i < registeredMonths.length; i++) {

        // domainが異なる場合はSkip
        if (registeredMonths[i].domain != currentDomain) {
          continue;
        }

        archiveIdIndex[registeredMonths[i].month] = registeredMonths[i].archiveId;
      
        const ym = registeredMonths[i].month.split('-');
        
        if (monthIndex[ym[0]] == void 0) {
          monthIndex[ym[0]] = [];
        }
        monthIndex[ym[0]].push(ym[1]);
      }

      this.archivedYear = Object.keys(monthIndex);
      this.archivedMonth = monthIndex[this.archivedYear[0]];
      this.archiveIdIndex = archiveIdIndex;
      this.monthIndex = monthIndex;
    },
    async setUpdatedAtLabel(categoryId, range) {
      if (categoryId == void 0) {
        this.updateAtLabel = '';
        return;
      }
      const query = await API.graphql(graphqlOperation(queries.getCategoryRankingRange, { categoryId, range, limit: DATA_LIMIT }));

      if (query.data.getCategoryRankingRange != void 0) {
        const updatedAt = moment(query.data.getCategoryRankingRange.updatedAt);
        this.updateAtLabel = `${this.t('ランキングデータの取得日時：')}${updatedAt.format('YYYY/MM/DD HH:mm:ss')}`;
        return;
      }
      this.updateAtLabel = '';
    },
    async initializeCategoryList(domain) {
      const categories = await Category.fetchCategoryListForCategoryRanking(this.$store.getters.getDomain);
      this.categoryList = [];
      for (const key in categories) {
        const name = categories[key].name;
        this.categoryList.push({ key, name, domain });
      }
    },
    loaded() {
      this.disabledAiAnalysis = false;
    },
    async aianalysis() {
      const titles = this.$refs.resultList.createTitleData();
      const message = AIChat.createMessageFromCategoryRanking(this.lastCategory.name, titles);
      this.$refs.aichat.show(message);
    }
  }
}
</script>
<style scoped>
.updateAt {
  font-size: 9pt;
  align-self: center;
}

.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
</style>
