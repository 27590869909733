<template>
  <div>
    <Loading
      v-if="!item"
      class="container mb-5"
    ></Loading>
    <product
      v-else
      :item="item"
      :csv="csv"
      :isDetail="isDetail"
      :keepaGraphUrl="keepaGraphUrl"
      @changeChartDataTerm="changeChartDataTerm"
      @changeHistoricalDataTerm="changeHistoricalDataTerm"
    ></product>
    <loading-screen v-if="loadingScreen"></loading-screen>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Product from "@/components/Product.vue";
import Loading from "@/components/Loading.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Utils from "@/mixins/utils";

export default {
  name: "Detail",
  components: {
    Product,
    Loading,
    LoadingScreen,
  },
  data: function () {
    return {
      item: null,
      csv: [],
      keepaGraphUrl: "",
      isDetail: false,
      loadedAll: false,
      loadingScreen: false,
    };
  },
  mixins: [Utils],
  filters: {},
  watch: {
    $route() {
      this.item = null;
      const code = this.$route.params.asin;
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit("setDomainCom", isCom);
      this.load(code, isCom);
    },
  },
  async created() {
    const code = this.$route.params.asin;
    const isCom = this.isComDomain(this.$route.query.domain);
    this.$store.commit("setDomainCom", isCom);
    this.load(code, isCom);
  },
  computed: {},
  methods: {
    async load(code, isCom) {
      document.body.style.backgroundColor = '#ffffff';
      if (!code) {
        console.log(`code is empty`);
        return;
      }
      const setItem = (item) => {
        this.item = item;
        this.csv = item.csv;
      };
      const domain = this.$store.getters.getDomain;
      await this.getProduct(code, domain)
        .then((item) => {
          setItem(item);
          return item;
        })
        .catch((err) => console.error(err));
  
      this.getProductDetail(code, domain)
        .then((item) => {
          setItem(item);
          //console.log(JSON.stringify(item));
          const path = isCom ? `${domain}/png/${item.asin}.png` : `png/${item.asin}.png`;
          Storage.get(path).then(
            (url) => (this.keepaGraphUrl = url)
          );
          this.isDetail = true;
        })
        .catch((err) => console.error(err));
    },
    async getProduct(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async getProductDetail(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: true, domain, countpv: true })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async getProductAll(asin, domain) {
      // 全期間データ読み込み
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain, nocache: true })
      ).then((query) => query.data.getProductDetail.json ? JSON.parse(query.data.getProductDetail.json)[0] : {});
    },
    async changeChartDataTerm(dataTerm) {
      if (!['MON12', 'ALL'].includes(dataTerm)  || this.loadedAll) {
        return;
      }
      this.loadingScreen = true;
      const setItem = (item) => {
        this.item = item;
        this.csv = item.csv;
      };
      await this.getProductAll(this.item.asin, this.item.domainId)
        .then((item) => {
          setItem(item);
          this.loadingScreen = false;
          return item;
        }).catch((err) => {
          console.error(err);
          this.loadingScreen = false;
        });
      this.loadedAll = true;
    },
    async changeHistoricalDataTerm(dataTerm) {
      await this.changeChartDataTerm(dataTerm);
    },
  },
};
</script>
<style scoped>
</style>