<template>
  <div class="conditionlist" id="conditionlist">
    <div class="groupheader">
      <h6 class="font-weight-bold"><div class="point bg-secondary"></div>{{ t('売れ筋ランキング') }}</h6>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="sales1" qkey="current_SALES" :title="t('現在ランク')"></range-condition>
      <range-condition class="mr-2" ref="sales2" qkey="avg30_SALES" :title="t('30日平均')"></range-condition>
      <range-condition class="mr-2" ref="sales3" qkey="avg90_SALES" :title="t('90日平均')"></range-condition>
      <range-condition class="mr-2" ref="sales4" qkey="avg180_SALES" :title="t('180日平均')"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="salesdp1" alias="_salesdp1" qkey="deltaPercent1_SALES" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="salesdp7" alias="_salesdp7" qkey="deltaPercent7_SALES" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="salesdp30" alias="_salesdp30" qkey="deltaPercent30_SALES" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="salesdp90" alias="_salesdp90" qkey="deltaPercent90_SALES" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title font-weight-bold"><div class="point bg-orange"></div>Amazon</h6>
      <div class="d-flex flex-wrap">
        <b-form-checkbox switch size="sm" v-model="amazonOutOfStock" class="switch mr-3"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
        <b-form-checkbox switch size="sm" v-model="amazonInStock" class="switch mr-3"><span class="small text-secondary">{{ t('在庫あり') }}</span></b-form-checkbox>
        <b-form-checkbox switch size="sm" v-model="amazonPreOrder" class="switch mr-3"><span class="small text-secondary">{{ t('発売前／予約注文可') }}</span></b-form-checkbox>
        <b-form-checkbox switch size="sm" v-model="amazonUnknown" class="switch mr-3"><span class="small text-secondary">{{ t('不明') }}</span></b-form-checkbox>
        <b-form-checkbox switch size="sm" v-model="amazonBackOrdered" class="switch mr-3"><span class="small text-secondary">{{ t('入荷待ち') }}</span></b-form-checkbox>
        <b-form-checkbox switch size="sm" v-model="amazonDelayed" class="switch"><span class="small text-secondary">{{ t('入荷遅延') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="amazon1" qkey="current_AMAZON" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="amazon2" qkey="avg30_AMAZON" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="amazon3" qkey="avg90_AMAZON" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="amazon4" qkey="avg180_AMAZON" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="amazondp1" qkey="deltaPercent1_AMAZON" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="amazondp7" qkey="deltaPercent7_AMAZON" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="amazondp30" qkey="deltaPercent30_AMAZON" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="amazondp90" qkey="deltaPercent90_AMAZON" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title font-weight-bold"><div class="point bg-black"></div>{{ t('新品') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="newOutOfStock" class="switch"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="new1" qkey="current_NEW" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="new2" qkey="avg30_NEW" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="new3" qkey="avg90_NEW" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="new4" qkey="avg180_NEW" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="newdp1" qkey="deltaPercent1_NEW" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newdp7" qkey="deltaPercent7_NEW" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newdp30" qkey="deltaPercent30_NEW" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newdp90" qkey="deltaPercent90_NEW" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title font-weight-bold"><div class="point bg-red"></div>{{ t('中古') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="usedOutOfStock" class="switch"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="used1" qkey="current_USED" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="used2" qkey="avg30_USED" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="used3" qkey="avg90_USED" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="used4" qkey="avg180_USED" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="useddp1" qkey="deltaPercent1_USED" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="useddp7" qkey="deltaPercent7_USED" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="useddp30" qkey="deltaPercent30_USED" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="useddp90" qkey="deltaPercent90_USED" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title font-weight-bold"><div class="point bg-eresa"></div>{{ t('カート') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="buyboxOutOfStock" class="switch"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="buybox1" qkey="current_BUY_BOX_SHIPPING" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="buybox2" qkey="avg30_BUY_BOX_SHIPPING" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="buybox3" qkey="avg90_BUY_BOX_SHIPPING" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="buybox4" qkey="avg180_BUY_BOX_SHIPPING" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="buyboxdp1" qkey="deltaPercent1_BUY_BOX_SHIPPING" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="buyboxdp7" qkey="deltaPercent7_BUY_BOX_SHIPPING" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="buyboxdp30" qkey="deltaPercent30_BUY_BOX_SHIPPING" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="buyboxdp90" qkey="deltaPercent90_BUY_BOX_SHIPPING" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title font-weight-bold"><div class="point bg-black"></div>{{ t('参考価格') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="listpriceOutOfStock" class="switch"><span class="small text-secondary">{{ t('現在価格なし ※検索結果の参考価格には直近の価格が表示される場合があります。') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="listprice" qkey="current_LISTPRICE" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>

    <div class="groupheader">
      <h6 class="cond-title-l font-weight-bold"><div class="point bg-secondary"></div>{{ t('新品(FBA)') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="newfbaOutOfStock" class="switch"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="newfba1" qkey="current_NEW_FBA" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="newfba2" qkey="avg30_NEW_FBA" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="newfba3" qkey="avg90_NEW_FBA" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="newfba4" qkey="avg180_NEW_FBA" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="newfbadp1" qkey="deltaPercent1_NEW_FBA" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newfbadp7" qkey="deltaPercent7_NEW_FBA" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newfbadp30" qkey="deltaPercent30_NEW_FBA" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="newfbadp90" qkey="deltaPercent90_NEW_FBA" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <div class="groupheader">
      <h6 class="cond-title-l font-weight-bold"><div class="point bg-secondary"></div>{{ t('中古(FBA)') }}</h6>
      <div>
        <b-form-checkbox switch size="sm" v-model="warehouseOutOfStock" class="switch"><span class="small text-secondary">{{ t('在庫なし') }}</span></b-form-checkbox>
      </div>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="warehouse1" qkey="current_WAREHOUSE" :title="t('現在価格')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="warehouse2" qkey="avg30_WAREHOUSE" :title="t('30日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="warehouse3" qkey="avg90_WAREHOUSE" :title="t('90日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
      <range-condition class="mr-2" ref="warehouse4" qkey="avg180_WAREHOUSE" :title="t('180日平均')" :digit="priceUnit.digit" :coef="priceUnit.coef"></range-condition>
    </div>
    <div class="pricegroup">
      <range-condition class="mr-2" ref="warehousedp1" qkey="deltaPercent1_WAREHOUSE" :title="t('1日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="warehousedp7" qkey="deltaPercent7_WAREHOUSE" :title="t('7日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="warehousedp30" qkey="deltaPercent30_WAREHOUSE" :title="t('30日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
      <range-condition class="mr-2" ref="warehousedp90" qkey="deltaPercent90_WAREHOUSE" :title="t('90日増減率')" hasSign minvalue="-99" maxvalue="1000" inv></range-condition>
    </div>
    <span class="small text-secondary">{{ t('※増減率は-99～1000の範囲を指定できます。') }}</span>

    <h6 class="font-weight-bold mt-4"><div class="point bg-secondary"></div>{{ t('詳細条件') }}</h6>
    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 mr-4">{{ t('カテゴリー') }}</div>
      <b-dropdown :text="category.name" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small mr-4" size="sm">
        <b-dropdown-item v-for="item in categoryList" :key="item.key" class="small" @click="categorySelected(item)">
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
      <b-form-checkbox switch size="sm" v-model="singleVariation" class="mt-2 mr-4 switch"><span class="text-secondary small">{{ t('単一バリエーションのみ検索') }}</span></b-form-checkbox>
    </div>
    <div class="detailitem mt-3">
      <b-spinner v-if="loadingSubCategories" variant="primary" small></b-spinner>
      <item-selector ref="categorySelector"></item-selector>
    </div>
    <div class="detailitem mt-3">
      <div class="condition mt-2 mt-sm-0">
        <div class="text-secondary stringtextlabel-xs small mt-2 mr-1 d-sm-none">{{ t('キーワード') }}</div>
        <div class="text-secondary stringtextlabel small  mt-2 mr-1 d-none d-sm-inline">{{ t('キーワード') }}</div>
        <input type="text" class="form-control stringtext ml-2 mr-2 mb-2" :placeholder="t('商品名・ブランド名など')" maxlength="200" :value="value" @input="$emit('input', $event.target.value)" />
        <b-form-checkbox switch size="sm" @input="updateValue" v-model="isSearchExactMatch" class="mt-2 mr-4 switch"><span class="text-secondary small">{{ t('完全一致') }}</span></b-form-checkbox>      
      </div>
    </div>

    <div class="detailitem mt-3">
      <div class="condition mt-2 mt-sm-0">
        <div class="text-secondary date-rangelabel-xs small mr-1 d-sm-none">{{ t('発売日') }}</div>
        <div class="text-secondary date-rangelabel small mr-1 d-none d-sm-inline">{{ t('発売日') }}</div>
        <b-form-datepicker class="mb-2 release-date mr-1" size="sm" :placeholder="t('から')" reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="releaseDateFrom"></b-form-datepicker>
        <b-form-datepicker class="mb-2 release-date mr-4" size="sm" :placeholder="t('まで')" reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="releaseDateTo"></b-form-datepicker>
      </div>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('90日間の在庫切れ率') }}</div>
      <range-condition class="mr-4" ref="outOfStockPercentage90" qkey="outOfStockPercentage90" :title="t('Amazon')" maxvalue="100"></range-condition>
      <range-condition class="mr-4" ref="outOfStockPercentage90_new" qkey="outOfStockPercentage90_NEW" :title="t('新品')" maxvalue="100"></range-condition>
      <range-condition class="mr-4" ref="outOfStockPercentage90_used" qkey="outOfStockPercentage90_USED" :title="t('中古')" maxvalue="100"></range-condition>
      <range-condition class="mr-4" ref="outOfStockPercentage90_bb" qkey="outOfStockPercentage90_BB" :title="t('カート')" maxvalue="100"></range-condition>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('出品者数') }}</div>
      <range-condition class="mr-2" ref="newCount" qkey="current_COUNT_NEW" :title="t('新品')"></range-condition>
      <range-condition class="mr-2" ref="usedCount" qkey="current_COUNT_USED" :title="t('中古')"></range-condition>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('売上ランキング降下') }}</div>
      <range-condition class="mr-2" ref="drop30" qkey="salesRankDrops30" :title="t('30日降下回数')"></range-condition>
      <range-condition class="mr-2" ref="drop90" qkey="salesRankDrops90" :title="t('90日降下回数')"></range-condition>
      <range-condition class="mr-2" ref="drop180" qkey="salesRankDrops180" :title="t('180日降下回数')"></range-condition>
    </div>
    <div class="detailitem mt-3">
      <range-condition class="mr-2" ref="droprate30" qkey="deltaPercent30_SALES" :title="t('30日降下率')" hasSign minvalue="-1000" maxvalue="99"></range-condition>
      <range-condition class="mr-2" ref="droprate90" alias="_droprate90" qkey="deltaPercent90_SALES" :title="t('90日降下率')" hasSign minvalue="-1000" maxvalue="99"></range-condition>
      <range-condition class="mr-2" ref="droprate180" qkey="deltaPercent180_SALES" :title="t('180日降下率')" hasSign minvalue="-1000" maxvalue="99"></range-condition>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('評価') }}</div>
      <range-condition class="mr-2" ref="rating" qkey="current_RATING" :title="t('評価(1.0～5.0)')" minvalue="1" maxvalue="5" digit="1" coef="10"></range-condition>
      <range-condition class="mr-2" ref="countreviews" qkey="current_COUNT_REVIEWS" :title="t('レビュー数')"></range-condition>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('セラー') }}</div>
      <div class="text-secondary stringtextlabel-xs small mt-2 mr-1 d-sm-none">{{ t('セラーID') }}</div>
      <div class="text-secondary stringtextlabel small  mt-2 mr-1 d-none d-sm-inline">{{ t('セラーID') }}</div>
      <input type="text" class="form-control stringtext ml-2 mr-2 mb-2" :placeholder="t('セラーID')" maxlength="20" v-model="sellerId" />
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 mr-4">{{ t('カート取得セラー種別') }}</div>
      <b-dropdown :text="[t('指定なし'), 'Amazon', t('Amazon以外（自己配送またはFBA）'), t('自己配送'), 'FBA'][selectedBuyboxIsFba]" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small mr-4" size="sm">
        <b-dropdown-item @click="selectedBuyboxIsFba = 0">{{ t('指定なし') }}</b-dropdown-item>
        <b-dropdown-item @click="selectedBuyboxIsFba = 1">Amazon</b-dropdown-item>
        <b-dropdown-item @click="selectedBuyboxIsFba = 2">{{ t('Amazon以外（自己配送またはFBA）') }}</b-dropdown-item>
        <b-dropdown-item @click="selectedBuyboxIsFba = 3">{{ t('自己配送') }}</b-dropdown-item>
        <b-dropdown-item @click="selectedBuyboxIsFba = 4">FBA</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="detailitem mt-3">
      <div class="text-secondary small mt-1 w-100"><div class="point small bg-secondary"></div>{{ t('パッケージ寸法') }}</div>
      <div class="text-secondary small mr-1 mt-2">{{ t('サイズ名') }}</div>
      <b-form-checkbox-group :options="packageSizeOptions" v-model="packageSize" buttons size="sm" class="bg-eresa btn-sm mr-4"></b-form-checkbox-group>
    </div>
    <div class="detailitem">
      <b-dropdown :text="t('サイズ区分')" class="mr-4 dropdown-eresa" size="sm">
        <b-dropdown-item v-for="item in sizeList" :key="item.key" class="small" @click="sizeSelected(item)">
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
      <range-condition class="mr-2" ref="length" qkey="packageLength" :title="t('長さ(cm)')" digit="1" coef="10"></range-condition>
      <range-condition class="mr-2" ref="width" qkey="packageWidth" :title="t('幅(cm)')" digit="1" coef="10"></range-condition>
      <range-condition class="mr-2" ref="height" qkey="packageHeight" :title="t('高さ(cm)')" digit="1" coef="10"></range-condition>
      <range-condition class="mr-2" ref="weight" qkey="packageWeight" :title="t('重量(kg)')" digit="2" coef="1000"></range-condition>
    </div>
    <div class="footer mt-4 mb-4">
      <b-button id="SearchConditions-save" class="btn bg-save text-white px-3 mr-2" :disabled="disabledSave === true" @click="$emit('save')">{{ t('検索条件を保存') }}</b-button>
      <b-tooltip target="SearchConditions-save" placement="top" :show.sync="showTooltipSave" triggers="manual">{{ t('検索条件を保存しました。お気に入りの検索条件からご利用いただけます。') }}</b-tooltip>
      <b-button class="btn bg-reset text-white px-3 mr-2" @click="clear">{{ t('リセット') }}</b-button>
      <b-button class="btn bg-eresa text-white px-3" @click="search">{{ t('検索') }}</b-button>
    </div>

    <b-modal ref="saveCondition" @ok="saveCondition" :title="t('検索条件を保存')" :ok-title="t('保存する')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <b-form-group>
            <b-form-input class="mt-2" v-model="conditionName" :placeholder="t('条件名を入力してください')" maxlength="100"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RangeCondition from "@/components/RangeCondition.vue";
import ItemSelector from "@/components/ItemSelector.vue";
import { SizeTables } from "@/store/size.js";
import { Category } from "@/mixins/Category.js";
import Utils from "@/mixins/utils";

const AMAZON_ID = 'AN1VRQENFRJN5';
const categoryNameUnselected = '未選択';

export default {
  name: 'SearchConditions',
  components: {
    RangeCondition,
    ItemSelector
  },
  mixins: [Utils],
  props: ['value', 'disabledSave','isSearchExactMatch'],
  data: function () {
    return {
      usdJpy: null,
      priceUnit: { digit: 0, coef: 1},
      list: [],
      amazonOutOfStock: false,
      amazonInStock: false,
      amazonPreOrder: false,
      amazonUnknown: false,
      amazonBackOrdered: false,
      amazonDelayed: false,
      newOutOfStock: false,
      usedOutOfStock: false,
      buyboxOutOfStock: false,
      listpriceOutOfStock: false,
      newfbaOutOfStock: false,
      newfbmOutOfStock: false,
      warehouseOutOfStock: false,
      categoryList: null,
      category: { id: null, name: this.t(categoryNameUnselected) },
      singleVariation: false,
      releaseDateFrom: void 0,
      releaseDateTo: void 0,
      sellerId: void 0,
      selectedBuyboxIsFba: 0,
      packageSize: [],
      packageSizeOptions: [ { text: 'S', value: '0' }, { text: 'M', value: '1' }, { text: 'L', value: '2' }, { text: 'LL', value: '3' }],
      sizeList: null,
      sizeListSrc: { jp: null, com: null },
      conditionName: '',
      showTooltipSave: false,
      loadingSubCategories: false,
    };
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain(val) {
      this.initializeCategoryList();
      this.sizeList = null;
      this.sizeList = val == this.$store.getters.getComDomain ? this.sizeListSrc.com : this.sizeListSrc.jp;
      this.sizeList.forEach(item => item.name = this.t(item.name));
      this.setPriceUnit(this.$store.getters.isDomainCom);
    },
  },
  async created() {
    await this.initializeCategoryList();
    this.sizeListSrc = { jp: SizeTables.sizeJp.map(s => Object.assign({}, s)), com: SizeTables.sizeCom.map(s => Object.assign({}, s)) };
    const mapJp = new Map();
    this.sizeListSrc.jp.forEach(s => mapJp.set(s.key, s));
    // サンプルサイズ設定
    mapJp.get('m2').size = undefined;
    mapJp.get('m2').length = 20;
    mapJp.get('m2').width = 20;
    mapJp.get('m3').size = undefined;
    mapJp.get('m3').length = 30;
    mapJp.get('m3').width = 30;
    mapJp.get('m4').size = undefined;

    const mapCom = new Map();
    this.sizeListSrc.com.forEach(s => mapCom.set(s.key, s));
    // サンプルサイズ設定
    mapCom.get('so').size = undefined;
    mapCom.get('so').height = 30;

    this.sizeList = this.$store.getters.getDomain == this.$store.getters.getComDomain ? this.sizeListSrc.com : this.sizeListSrc.jp;
    this.sizeList.forEach(item => item.name = this.t(item.name));
  },
  async mounted() {
    this.usdJpy = await this.getUsdJpy();
    this.setPriceUnit(this.$store.getters.isDomainCom);
    this.list = [
      this.$refs.sales1,
      this.$refs.sales2,
      this.$refs.sales3,
      this.$refs.sales4,
      this.$refs.salesdp1,
      this.$refs.salesdp7,
      this.$refs.salesdp30,
      this.$refs.salesdp90,
      this.$refs.amazon1,
      this.$refs.amazon2,
      this.$refs.amazon3,
      this.$refs.amazon4,
      this.$refs.amazondp1,
      this.$refs.amazondp7,
      this.$refs.amazondp30,
      this.$refs.amazondp90,
      this.$refs.new1,
      this.$refs.new2,
      this.$refs.new3,
      this.$refs.new4,
      this.$refs.newdp1,
      this.$refs.newdp7,
      this.$refs.newdp30,
      this.$refs.newdp90,
      this.$refs.used1,
      this.$refs.used2,
      this.$refs.used3,
      this.$refs.used4,
      this.$refs.useddp1,
      this.$refs.useddp7,
      this.$refs.useddp30,
      this.$refs.useddp90,
      this.$refs.buybox1,
      this.$refs.buybox2,
      this.$refs.buybox3,
      this.$refs.buybox4,
      this.$refs.buyboxdp1,
      this.$refs.buyboxdp7,
      this.$refs.buyboxdp30,
      this.$refs.buyboxdp90,
      this.$refs.listprice,
      this.$refs.newfba1,
      this.$refs.newfba2,
      this.$refs.newfba3,
      this.$refs.newfba4,
      this.$refs.newfbadp1,
      this.$refs.newfbadp7,
      this.$refs.newfbadp30,
      this.$refs.newfbadp90,
      this.$refs.warehouse1,
      this.$refs.warehouse2,
      this.$refs.warehouse3,
      this.$refs.warehouse4,
      this.$refs.warehousedp1,
      this.$refs.warehousedp7,
      this.$refs.warehousedp30,
      this.$refs.warehousedp90,
      this.$refs.outOfStockPercentage90,
      this.$refs.outOfStockPercentage90_new,
      this.$refs.outOfStockPercentage90_used,
      this.$refs.outOfStockPercentage90_bb,
      this.$refs.newCount,
      this.$refs.usedCount,
      this.$refs.drop30,
      this.$refs.drop90,
      this.$refs.drop180,
      this.$refs.droprate30,
      this.$refs.droprate90,
      this.$refs.droprate180,
      this.$refs.rating,
      this.$refs.countreviews,
      this.$refs.length,
      this.$refs.width,
      this.$refs.height,
      this.$refs.weight,
    ];
    this.$emit('initialized');
  },
  methods: {
    updateValue(newValue) {
      this.$emit('update:isSearchExactMatch', newValue);
    },
    show() {
      document.getElementById('conditionlist').style['display'] = 'block';
    },
    hide() {
      document.getElementById('conditionlist').style['display'] = 'none';
    },
    query() {
      let q = {};
      // Amazon offer
      const availabilityAmazon = [];
      if (this.amazonOutOfStock) {
        availabilityAmazon.push(-1);
      }
      if (this.amazonInStock) {
        availabilityAmazon.push(0);
      }
      if (this.amazonPreOrder) {
        availabilityAmazon.push(1);
      }
      if (this.amazonUnknown) {
        availabilityAmazon.push(2);
      }
      if (this.amazonBackOrdered) {
        availabilityAmazon.push(3);
      }
      if (this.amazonDelayed) {
        availabilityAmazon.push(4);
      }
      if (availabilityAmazon.length > 0 && availabilityAmazon.length < 6) {
        q.availabilityAmazon = availabilityAmazon;
      }
      // 在庫なし
      if (this.newOutOfStock) {
        q.current_NEW_gte = -1;
        q.current_NEW_lte = -1;
      }
      if (this.usedOutOfStock) {
        q.current_USED_gte = -1;
        q.current_USED_lte = -1;
      }
      if (this.buyboxOutOfStock) {
        q.current_BUY_BOX_SHIPPING_gte = -1;
        q.current_BUY_BOX_SHIPPING_lte = -1;
      }
      if (this.listpriceOutOfStock) {
        q.current_LISTPRICE_gte = -1;
        q.current_LISTPRICE_lte = -1;
      }
      if (this.newfbaOutOfStock) {
        q.current_NEW_FBA_gte = -1;
        q.current_NEW_FBA_lte = -1;
      }
      if (this.newfbmOutOfStock) {
        q.current_NEW_FBM_SHIPPING_gte = -1;
        q.current_NEW_FBM_SHIPPING_lte = -1;
      }
      if (this.warehouseOutOfStock) {
        q.current_WAREHOUSE_gte = -1;
        q.current_WAREHOUSE_lte = -1;
      }

      // カテゴリー
      if (this.category.id != void 0) {
        q.rootCategory = this.category.id;
      }
      // サブカテゴリー
      const sub = this.$refs.categorySelector.getItems();
      if (sub.length > 0) {
        q.categories_include = sub.map(cat => cat.id);
      }

      // バリエーション
      if (this.singleVariation) {
        q.singleVariation = true;
      }
      // 発売日
      if (this.releaseDateFrom != void 0) {
        q.releaseDate_gte = new Date(this.releaseDateFrom).getTime() / 60000 - 21564000;
      }
      if (this.releaseDateTo != void 0) {
        q.releaseDate_lte = new Date(this.releaseDateTo).getTime() / 60000 - 21564000;
      }
      // セラー
      if (this.sellerId != void 0) {
        q.sellerIds = this.sellerId;
      }
      if (this.selectedBuyboxIsFba === 1) {
        q.buyBoxSellerId = [AMAZON_ID];
        q.current_BUY_BOX_SHIPPING_gte = 0;
      }
      else if (this.selectedBuyboxIsFba === 2) {
        q.buyBoxSellerId = [`-${AMAZON_ID}`];
        q.current_BUY_BOX_SHIPPING_gte = 0;
      }
      else if (this.selectedBuyboxIsFba === 3) {
        q.buyBoxSellerId = [`-${AMAZON_ID}`];
        q.current_BUY_BOX_SHIPPING_gte = 0;
        q.buyBoxIsFBA = false;
      }
      else if (this.selectedBuyboxIsFba === 4) {
        q.buyBoxSellerId = [`-${AMAZON_ID}`];
        q.current_BUY_BOX_SHIPPING_gte = 0;
        q.buyBoxIsFBA = true;
      }
      // パッケージ寸法
      const mapsize = new Map();
      mapsize['0'] = ['small', 's'];
      mapsize['1'] = ['medium', 'm'];
      mapsize['2'] = ['large', 'l'];
      mapsize['3'] = ['x-large', 'll'];
      for (const ps of this.packageSize) {
        if (q.size == void 0) {
          q.size = [];
        }
        mapsize[ps].forEach(s => q.size.push(s));
      }
      for (const item of this.list) {
        q = Object.assign(q, item.query);
      }
      if (Object.keys(q).length <= 0) {
        return q;
      }

      //alert(JSON.stringify(q));
      return q;
    },
    async initializeCategoryList() {
      const categories = await Category.fetchCategoryListForSearch(this.$store.getters.getDomain);
      this.categoryList = [{key: null, name: this.t(categoryNameUnselected) }];
      for (const key in categories) {
        const name = categories[key].name;
        this.categoryList.push({key: key, name: name});
      }
      this.category = { id: null, name: this.t(categoryNameUnselected) };
    },
    async categorySelected(item) {
      try {
        this.loadingSubCategories = true;
        this.$refs.categorySelector.clear();
        this.category.id = item.key;
        this.category.name = item.name;
        if (this.category.id != void 0) {
          const cat = await Category.fetchCategoryList(this.$store.getters.getDomain, this.category.id);
          for (const catid in cat) {
            const subcat = await Category.fetchCategoryList(this.$store.getters.getDomain, catid);
            for (const key in subcat) {
              const id = subcat[key].catId;
              const name = subcat[key].name;
              this.$refs.categorySelector.add(id, name);
            }
          }
        }
      }
      finally {
        this.loadingSubCategories = false;
      }
    },
    sizeSelected(item) {
      const size_lte = item.size == void 0 ? 0 : Math.floor(item.size / 3 * 10) / 10;
      const length_lte = size_lte > 0 ? size_lte : item.length;
      const width_lte = size_lte > 0 ? size_lte : item.width;
      const height_lte = size_lte > 0 ? size_lte : item.height;
      const weight_lte = item.weight;
      const lengthTo = length_lte;
      const widthTo = width_lte;
      const heightTo = height_lte;
      const weightTo = weight_lte;
      let lengthFrom = null;
      let widthFrom = null;
      let heightFrom = null;
      let weightFrom = null;
      const index = this.sizeList.findIndex(v => v.key === item.key);
      if (index > 0) {
        const pitem = item.prevsize == void 0 ? this.sizeList[index - 1] : this.sizeList.find(v => v.key === item.prevsize);
        const size_gte= pitem.size == void 0 ? 0 : Math.floor(pitem.size / 3 * 10) / 10;
        const length_gte = size_gte > 0 ? size_gte :  pitem.length;
        const width_gte = size_gte > 0 ? size_gte : pitem.width;
        const height_gte = size_gte > 0 ? size_gte : pitem.height;
        lengthFrom = length_lte == void 0 || length_lte > length_gte ? length_gte : null;
        widthFrom = width_lte == void 0 || width_lte > width_gte ? width_gte : null;
        heightFrom = height_lte == void 0 || height_lte > height_gte ? height_gte : null;
        weightFrom = null;
      }
      this.$refs.length.setText(lengthFrom, lengthTo);
      this.$refs.width.setText(widthFrom, widthTo);
      this.$refs.height.setText(heightFrom, heightTo);
      this.$refs.weight.setText(weightFrom, weightTo);
    },
    setPriceUnit(isCom) {
      this.priceUnit.digit = isCom ? 2 : 0;
      this.priceUnit.coef = isCom ? 100 : 1;
      const priceFields = [
        'amazon1', 'amazon2', 'amazon3', 'amazon4',
        'new1', 'new2', 'new3', 'new4',
        'used1', 'used2', 'used3', 'used4',
        'buybox1', 'buybox2', 'buybox3', 'buybox4',
        'listprice',
        'newfba1', 'newfba2', 'newfba3', 'newfba4',
        'warehouse1', 'warehouse2', 'warehouse3', 'warehouse4',
      ];
      for (const f of priceFields) {
        const [from, to] = this.$refs[`${f}`].getText();
        let newFrom = null;
        let newTo = null;
        if (from != void 0) {
          newFrom = Math.round(from * (isCom ? 1.0 / this.usdJpy : this.usdJpy) * this.priceUnit.coef) / this.priceUnit.coef;
        }
        if (to != void 0) {
          newTo = Math.round(to * (isCom ? 1.0 / this.usdJpy : this.usdJpy) * this.priceUnit.coef) / this.priceUnit.coef;
        }
        this.$refs[`${f}`].setText(newFrom, newTo);
      }
    },
    clear() {
      for (const item of this.list) {
        item.clear();
      }
      this.amazonOutOfStock = false;
      this.amazonInStock = false,
      this.amazonPreOrder = false,
      this.amazonUnknown = false,
      this.amazonBackOrdered = false,
      this.amazonDelayed = false,
      this.newOutOfStock = false;
      this.usedOutOfStock = false;
      this.buyboxOutOfStock = false;
      this.listpriceOutOfStock = false;
      this.newfbaOutOfStock = false;
      this.newfbmOutOfStock = false;
      this.warehouseOutOfStock = false;
      this.category = { id: null, name: this.t(categoryNameUnselected) };
      this.singleVariation = false;
      this.releaseDateFrom = void 0;
      this.releaseDateTo = void 0;
      this.selectedBuyboxIsFba = 0;
      this.packageSize = [];
    },
    async search() {
      this.$emit('search');
    },
    async setValues(q) {
      if (q == void 0) {
        return;
      }
      for (const item of this.list) {
        item.setValue(q);
      }
      // Amazon offer
      if (q.availabilityAmazon != void 0) {
        if (q.availabilityAmazon.includes(-1)) {
          this.amazonOutOfStock = true;
        }
        if (q.availabilityAmazon.includes(0)) {
          this.amazonInStock = true;
        }
        if (q.availabilityAmazon.includes(1)) {
          this.amazonPreOrder = true;
        }
        if (q.availabilityAmazon.includes(2)) {
          this.amazonUnknown = true;
        }
        if (q.availabilityAmazon.includes(3)) {
          this.amazonBackOrdered = true;
        }
        if (q.availabilityAmazon.includes(4)) {
          this.amazonDelayed = true;
        }
      }
      // 在庫なし
      if (q.current_NEW_lte == -1) {
        this.newOutOfStock = true;
      }
      if (q.current_USED_lte == -1) {
        this.usedOutOfStock = true;
      }
      if (q.current_BUY_BOX_SHIPPING_lte == -1) {
        this.buyboxOutOfStock = true;
      }
      if (q.current_LISTPRICE_lte == -1) {
        this.listpriceOutOfStock = true;
      }
      if (q.current_NEW_FBA_lte == -1) {
        this.newfbaOutOfStock = true;
      }
      if (q.current_NEW_FBM_SHIPPING_lte == -1) {
        this.newfbmOutOfStock = true;
      }
      if (q.current_WAREHOUSE_lte == -1) {
        this.warehouseOutOfStock = true;
      }
      // カテゴリー
      if (q.rootCategory != void 0) {
        const cat = this.categoryList.find(item => item.key == q.rootCategory);
        if (cat != void 0) {
          this.category.id = cat.key;
          this.category.name = cat.name;
        }
      }
      // サブカテゴリー
      if (q.categories_include != void 0 && this.category.id != void 0) {
        try {
          this.loadingSubCategories = true;
          const cat = await Category.fetchCategoryList(this.$store.getters.getDomain, this.category.id);
          for (const catid in cat) {
            const subcat = await Category.fetchCategoryList(this.$store.getters.getDomain, catid);
            for (const key in subcat) {
              const id = subcat[key].catId;
              const name = subcat[key].name;
              if (q.categories_include.includes(id)) {
                this.$refs.categorySelector.add(id, name);
              }
            }
          }
        }
        finally {
          this.loadingSubCategories = false;
        }
      }
      // バリエーション
      if (q.singleVariation) {
        this.singleVariation = true;
      }
      // 発売日
      if (q.releaseDate_gte != void 0) {
        this.releaseDateFrom = new Date((q.releaseDate_gte + 21564000) * 60000);
      }
      if (q.releaseDate_lte != void 0) {
        this.releaseDateTo = new Date((q.releaseDate_lte + 21564000) * 60000);
      }
      // セラー
      if (q.sellerIds != void 0) {
        this.sellerId = q.sellerIds;
      }
      if (q.buyBoxSellerId != void 0) {
        if (q.buyBoxSellerId[0] === AMAZON_ID) {
          this.selectedBuyboxIsFba = 1;
        }
        else if (q.buyBoxIsFBA == void 0) {
          this.selectedBuyboxIsFba = 2;
        }
        else {
          this.selectedBuyboxIsFba = q.buyBoxIsFBA ? 4 : 3;
        }
      }
      else {
        this.selectedBuyboxIsFba = 0;
      }
      // パッケージ寸法
      if (q.size != void 0) {
        if (q.size.includes('small') || q.size.includes('s')) {
          this.packageSize.push('0') ;
        }
        if (q.size.includes('medium') || q.size.includes('m')) {
          this.packageSize.push('1') ;
        }
        if (q.size.includes('large') || q.size.includes('l')) {
          this.packageSize.push('2') ;
        }
        if (q.size.includes('x-large') || q.size.includes('ll')) {
          this.packageSize.push('3') ;
        }
      }
    },
    showSaveCondition(conditionName) {
      this.conditionName = conditionName;
      this.$refs.saveCondition.show();
    },
    saveCondition(bvModalEvent) {
      if (this.conditionName != void 0 && this.conditionName.length > 0) {
        this.$emit('saveCondition', this.conditionName);
        this.showTooltipSave = true;
        setTimeout(() => this.showTooltipSave = false, 5000);
      }
      else {
        bvModalEvent.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.point {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.point.small {
  height: 8px;
  width: 8px;
}
.bg-black {
  background-color: black;
}
.bg-orange {
  background-color: orange;
}
.bg-red {
  background-color: red;
}
.conditionlist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.groupheader {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.cond-title {
  margin-right: 2em;
  width: 176px;
}
.cond-title-l {
  margin-right: 2em;
  width: 220px;
}
.pricegroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.detailitem {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}
.stringtext {
  width: 200px;
  padding: 4px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance:none;
  font-size: 10pt;
}
.release-date {
  width: 120px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance:none
}
.rangelabel {
  margin-top: 10px;
}
.rangelabel-xs {
  margin-top: 10px;
  min-width: 120px;
}
.date-rangelabel, stringtextlabel {
  margin-top: 10px;
}
.date-rangelabel-xs, stringtextlabel-xs {
  margin-top: 10px;
  min-width: 80px;
}
.condition {
  display: flex;
  flex-wrap: nowrap;
}
.footer {
  width: 100%;
  text-align: center;
}
.bg-save, .bg-reset {
  background-color: #379992;
}
.bg-eresa.btn-group {
  background-color: transparent;
}
::placeholder {
  font-size: 8pt;
}
</style>
