// App.vue and common messeges
export default {
  "リサーチ": "Research",
  "商品リサーチ": "Product research",
  "商品リサーチ（軽量版）": "Product research (light mode)",
  "商品一括リサーチ": "Batch product research",
  "商品名一括リサーチ": "Batch product title research",
  "SEOリサーチ": "SEO research",
  "お気に入り": "Favorite",
  "セラーリサーチ": "Seller research",
  "レビューリサーチ": "Review research",
  "キーワードマイニング": "Keyword Mining",
  "menu_ランキング": "Ranking",
  "トラッキング": "Tracking",
  "売上個数予測": "Sales Estimator",
  "AIアドバイザー": "AI Advisor",
  "利用マニュアル": "User Manual",
  "アカウント": "Account",
  "アフィリエイト": "Affiliate",
  "設定": "Settings",
  "各種設定": "Settings",
  "言語": "Language",
  "英語": "English",
  "日本語": "Japanese",
  "ログアウト": "Logout",
  "ログイン": "Login",
  "トップページ": "Top page",
  "利用規約": "Terms of use",
  "プライバシーポリシー": "Privacy policy",
  "お問い合わせ": "Contact us",
  "運営会社情報": "Company",
  "Copyright (C) {0} ERESA（イーリサ） All Rights Reserved.": "Copyright (C) {0} ERESA All Rights Reserved.",
  "円": "Yen",
  "{0}円": "{0}Yen",
  "{0}位": "No.{0}",
  "{0}個": "{0} pcs.",
  "YYYY年MM月DD日": "MM/DD/YYYY",
  "YYYY年MM月DD日 HH時mm分": "MM/DD/YYYY HH:mm",
  "{0}件中 {1} ～ {2}件を表示": "Showing {1} - {2} of {0}",
  "{0} ／ {1} ページ": "Page {0} of {1}",
  "{0}を正しく入力してください。": "Please enter the {0} correctly.",
  "{0}を正しく選択してください。": "Please select the {0} correctly.",
  "登録に成功しました。": "Successfully registered.",
  "登録に失敗しました。": "Registration failed.",
  "はい": "Yes",
  "いいえ": "No",
  "あり": "Yes",
  "なし": "No",
}
