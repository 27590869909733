export default {
  "商品リサーチ（軽量版）": "Product research (light mode)",
  "商品名・ASIN・JAN": "Keyword, ASIN, EAN/UPC",
  "検索": "Search",
  "商品一括リサーチ": "Batch product research",
  "完全一致": "Precise",
  "詳細検索": "Advanced",
  "※複数のJANやASINをまとめて検索したい場合は": "*If you want to search multiple EAN/UPC or ASINs at once",
  "（PRO版のみ）をご利用ください。": "(PRO version only).",
  "CSV出力": "CSV output",
  "{0}の検索に一致する商品はありませんでした。": "No items matching your search for {0} were found.",
  "検索に一致する商品はありませんでした。": "No products matched your search.",
  "本日の検索上限を超えたため結果を表示できません。": "The search limit for today's search has been exceeded and results cannot be displayed.",
  "キーワードに関する商品のトレンドをAI分析": "AI analysis of product trends related to keywords",
}
