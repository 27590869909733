import moment from 'moment';
import utils from '@/mixins/utils';
import { DOMAIN_COM, WSS } from '@/mixins/utils'

/**
 * AIChat
 */
export class AIChat {
  /**
   * streamモードでChatGPT APIの結果を取得。
   * @param message string ChatGPT APIへの質問メッセージ
   * @param usegpt4 boolean モデルにGPT4を使用
   * @param receive 結果を取得するコールバック関数
   * @param complete 完了時コールバック関数
   * @returns WebSocket
   */
  static chatCompletionsStream(message, usegpt4, receive, complete) {
    const sock = new WebSocket(WSS);
    sock.addEventListener('open', () => {
      const params = { message, temperature: 0 };
      if (usegpt4 === true) {
        params.model = 'gpt-4o';
      }
      const data = JSON.stringify(params);
      sock.send(JSON.stringify({ action: 'request.body.action', data }));
    });
    //sock.addEventListener('close', () => {
    //  console.log('close');
    //});
    sock.addEventListener('error', e => {
      console.error(e);
    });
    let i = 0;
    sock.addEventListener('message', e => {
      if (e.data === '[DONE]') {
        complete();
        sock.close();
      }
      else {
        if (i === 0 && e.data.trim().length <= 0) {
          // 最初の行には改行が入っているので無視する。
          return;
        }
        if (e.data.startsWith('{"message":')) {
          // タイムアウトメッセージを無視
          console.warn(JSON.parse(e.data).message);
        }
        else {
          receive(e.data);
        }
      }
      i++;
    });
    return sock;
  }

  /**
   * 商品キーワードを元にChatGPT APIへの質問メッセージを作成します。
   * @param keyword string 商品キーワード
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromKeyword(keyword) {
    const t = (text, newTexts) => utils.methods.t(text, newTexts);
    let message = t('searchKeyword', [moment().format('YYYY/MM/DD')]);
    message += '\n\n';
    const geo = utils.methods.isComEresa() ? 'US' : 'JP';
    // Googleショッピングトレンド
    message += `<script type="text/javascript" src="https://ssl.gstatic.com/trends_nrtr/3261_RC08/embed_loader.js"></script> <script type="text/javascript"> trends.embed.renderExploreWidget("TIMESERIES", {"comparisonItem":[{"keyword":"${keyword}","geo":"${geo}","time":"today 12-m"}],"category":0,"property":"froogle"}, {"exploreQuery":"geo=${geo}&gprop=froogle&q=${encodeURIComponent(keyword)}&date=today 12-m","guestPath":"https://trends.google.com:443/trends/embed/"}); </script>`;
    return message;
  }

  /**
   * 商品情報を元にChatGPT APIへの質問メッセージを作成します。
   * @param item object 商品オブジェクト
   * @param productInfoText string
   * @param rankingDropText string
   * @param avg90Text string
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromProductInfo(item, productInfoText, rankingDropText, avg90Text) {
    const t = (text, newTexts) => utils.methods.t(text, newTexts);
    const p = val => this._price(item, val);

    let message = t('productInfo', [item.title, p(utils.methods.getListPrice(item))]);
    message += '\n\n';
    message += productInfoText;
    message += '\n\n';
    message += rankingDropText;
    message += '\n\n';
    message += avg90Text;
    return message;
  }

  /**
   * 商品タイトルを元にChatGPT APIへの質問メッセージを作成します。
   * @param title string 商品タイトル
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromTitle(title) {
    const t = (text, newTexts) => utils.methods.t(text, newTexts);
    let message = t('productTitle', [title]);
    return message;
  }

  /**
   * 詳細画面の価格表を元にChatGPT APIへの質問メッセージを作成します。
   * @param item object 商品オブジェクト
   * @param dataType string 価格表の表示タイプ
   * @param historicalData [object] 価格表のデータ
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromHistoricalData(item, dataType, historicalData) {
    const t = (text, newTexts) => utils.methods.t(text, newTexts);
    const p = val => this._price(item, val);
    const ns = val => `${val != void 0 && val >= 0 ? val : '-'}`;

    let message = '';
    if (dataType === 'NEW') {
      message = t('historicalDataNew', [item.title]);
    }
    else if (dataType === 'USED') {
      message = t('historicalDataUsed', [item.title]);
    }
    else {
      message = t('historicalDataDefault', [item.title]);
    }
    message += '\n';

    let table = '';
    for (const row of historicalData) {
      if (dataType === 'NEW') {
        table += `${row['日付']}\t${ns(row['ランキング'])}\t${ns(row['新品出品数'])}\t${p(row['プライム'])}\t${p(row['プライム以外（送料込）'])}\t${p(row['Amazon価格'])}\t${p(row['カート価格'])}\n`;
      }
      else if (dataType === 'USED') {
        table += `${row['日付']}\t${ns(row['ランキング'])}\t${ns(row['中古出品数'])}\t${p(row['ほぼ新品'])}\t${p(row['非常に良い'])}\t${p(row['良い'])}\t${p(row['可'])}\n`;
      }
      else if (dataType === 'COLLECTOR') {
        table += `${row['日付']}\t${ns(row['ランキング'])}\t${ns(row['コレクター出品数'])}\t${p(row['ほぼ新品'])}\t${p(row['非常に良い'])}\t${p(row['良い'])}\t${p(row['可'])}\n`;
      }
      else {
        table += `${row['日付']}\t${ns(row['ランキング'])}\t${p(row['新品最安値'])}\t${ns(row['新品出品数'])}\t${p(row['中古最安値'])}\t${ns(row['中古出品数'])}\n`;
      }
    }
    message += table;
    return message;
  }

  /**
   * セラーリサーチの商品タイトルを元にChatGPT APIへの質問メッセージを作成します。
   * @param titles [string] 商品タイトル
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromSellerResearch(titles) {
    return `${utils.methods.t('sellerResearch')}\n\n${titles.map(t => this._shortTitle(t)).join('\n')}`;
  }

  /**
   * カテゴリーランキングの商品タイトルを元にChatGPT APIへの質問メッセージを作成します。
   * @param catName string カテゴリー名
   * @param titles [string] 商品タイトル
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromCategoryRanking(catName, titles) {
    return `${utils.methods.t('categoryRanking', [catName])}\n\n${titles.map(t => this._shortTitle(t)).join('\n')}`;
  }

  /**
   * SEOリサーチの商品タイトルを元にChatGPT APIへの質問メッセージを作成します。
   * @param keyword string キーワード
   * @param titles [string] 商品タイトル
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromSeoResearch(keyword, titles) {
    return `${utils.methods.t('seoResearch', [keyword])}\n\n${titles.join('\n')}`;
  }

  /**
   * SEOリサーチのキーワードを元にChatGPT APIへの質問メッセージを作成します。
   * @param keyword string キーワード
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromSeoResearchProduct(keyword) {
    return `${utils.methods.t('seoResearchProduct', [keyword])}`;
  }

  /**
   * レビューリサーチの商品タイトルを元にChatGPT APIへの質問メッセージを作成します。
   * @param keyword string キーワード
   * @param titles [string] 商品タイトル
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromReviewResearch(title, reviews) {
    return `${utils.methods.t('reviewResearch', [title])}\n\n${reviews.map(t => t.substring(0, 200)).join('\n\n')}`;
  }

  /**
   * 商品詳細画面のAI分析ボタン用。商品情報を元にChatGPT APIへの質問メッセージを作成します。
   * @param item object 商品オブジェクト
   * @param type string AIChat.jsに定義されているキー名
   * @returns string ChatGPT APIへの質問メッセージ
   */
  static createMessageFromProductAiButtons(item, type) {
    if (['optimizeProductSpecificationText', 'suggestDifferentiation'].includes(type)) {
      let desc = item.description;
      if (item.features != void 0) {
        desc = item.features.join('\n');
      }
      return utils.methods.t(type, [item.title, desc]);
    }
    else if (['optimizeProductTitles', 'generateProductSpecificationText', 'suggestSeoKeywords'].includes(type)) {
      return utils.methods.t(type, [item.title]);
    }
    throw new Error();
  }

  static _price(item, val) {
    const t = (text, newTexts) => utils.methods.t(text, newTexts);
    if (item.domainId == DOMAIN_COM) {
      return `$${val !== null && val >= 0 ? Number(val / 100).toFixed(2) : '-'}`;
    }
    return t('{0}円', [`${val !== null && val >= 0 ? val : '-'}`]);
  }

  static _shortTitle(title, maxlen = 50) {
    let i = title.length;
    if (title.length > maxlen) {
      i = maxlen;
      while (i < title.length) {
        if (title[i].trim().length <= 0) {
          break;
        }
        i++;
      }
    }
    return title.substring(0, i);
  }
}
