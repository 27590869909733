<script>
/**
 * このディレクティブは、要素がビューポートに入ったときに指定された関数を呼び出します。
 * 
 * 使用方法:
 * 1. コンポーネント内で `v-in-view` ディレクティブを使用します。
 * 2. ディレクティブに関数をバインドします。
 * 
 * 例:
 * <div v-in-view="onInView">...</div>
 * 
 * methods: {
 *   onInView() {
 *     // 要素がビューポートに入ったときの処理
 *   }
 * }
 */

export default {
  inserted(el, binding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          binding.value();
          observer.unobserve(el);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  }
};
</script>