import Vue from 'vue';
import Vuex from 'vuex';
import { DOMAIN_COM, DOMAIN_COJP, DOMAIN_COM_NAME, DOMAIN_COJP_NAME, HOSTNAME_COM, COM_STRIPE_INFO, JP_STRIPE_INFO,
  STRIPE_DOMAIN_JP, STRIPE_DOMAIN_COM } from '@/mixins/utils';
import { Favorites } from '@/store/Favorites';
import { Settings } from '@/store/Settings';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    favorites: null,
    settings: null,
    results: [],
    isMobile: false,
    isSubscriber: null,
    domain: window.location.hostname === HOSTNAME_COM ? DOMAIN_COM : DOMAIN_COJP,
    //stripe情報
    stripeDomain: window.location.hostname === HOSTNAME_COM ? STRIPE_DOMAIN_COM : STRIPE_DOMAIN_JP,
    stripeInfo: window.location.hostname === HOSTNAME_COM ? COM_STRIPE_INFO : JP_STRIPE_INFO,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    async getFavorites(state) {
      if (state.favorites == void 0) {
        const newFav = new Favorites(DOMAIN_COJP, state.user.username);
        await newFav.getFavorites();
        state.favorites = newFav;
      }
      return state.favorites;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isSubscriber(state) {
      return state.isSubscriber;
    },
    getJpDomain() {
      return DOMAIN_COJP;
    },
    getComDomain() {
      return DOMAIN_COM;
    },
    getDomain(state) {
      return state.domain;
    },
    getDomainName(state) {
      return state.domain === DOMAIN_COM ? DOMAIN_COM_NAME : DOMAIN_COJP_NAME;
    },
    isDomainCom(state) {
      return state.domain === DOMAIN_COM;
    },
    getStripeDomain(state) {
      return state.stripeDomain;
    },
    getStripeInfo(state) {
      return state.stripeInfo;
    },
    async getUserSettings(state) {
      if (!state.settings) {
        const userSettings = new Settings();
        const settings = await userSettings.getSettings();
        state.settings = settings;
      }

      return state.settings;
    },
    async getProductDetailSettings(state,getters) {
      // state.settings.productDetailSettingsが存在したらそれを返す
      if (state.settings?.productDetailSettings) {
        return state.settings.productDetailSettings;
      }
      // state.settings.productDetailSettingsが存在しない場合は、getUserSettings()を呼び出して、
      // state.settings.productDetailSettingsを返す
      const userSettings = await getters.getUserSettings;
      const productDetailSettings = userSettings?.productDetailSettings;

      return productDetailSettings;
    },
    async getSearchConditions(state,getters) {
      if (state.settings?.searchConditions) {
        return state.settings.searchConditions;
      }
      const userSettings = await getters.getUserSettings;
      const searchConditions = userSettings?.searchConditions;

      return searchConditions;
    },
    async getDisplayOrders(state,getters) {
      if (state.settings?.searchConditions) {
        return state.settings.displayOrders;
      }
      const userSettings = await getters.getUserSettings;
      const displayOrders = userSettings?.displayOrders;

      return displayOrders;
    },

  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setFavorites(state, favorites) {
      state.favorites = favorites
    },
    setResults(state, results) {
      state.results = results
    },
    setIsMobile(state, flag) {
      state.isMobile = flag
    },
    setIsSubscriber(state, flag) {
      state.isSubscriber = flag;
    },
    setIsOemUser(state, flag) {
      state.isOemUser = flag;
    },
    setDomain(state, domain) {
      if (domain == DOMAIN_COM) {
        state.domain = DOMAIN_COM;
        return;
      }
      state.domain = DOMAIN_COJP;
    },
    setDomainCom(state, flag) {
      state.domain = flag ? DOMAIN_COM : DOMAIN_COJP;
    },
    setUserSettings(state, settings) {
      state.userSettings = settings;
      const userSettings = new Settings();
      userSettings.saveSettings(settings);
    },
    setProductDetailSettings(state, settings) {
      if(!state?.userSettings?.productDetailSettings) {
        state.userSettings = {};
      }
      state.userSettings.productDetailSettings = settings;
    },
    setSearchConditions(state, settings) {
      if(!state?.userSettings?.searchConditions) {
        state.userSettings = {};
      }
      state.userSettings.searchConditions = settings;
    },
    setDisplayOrders(state, settings) {
      if(!state?.userSettings?.displayOrders) {
        state.userSettings = {};
      }
      state.userSettings.displayOrders = settings;
    },
  },
  actions: {
    clearCache(){
      // 「設定」情報をクリア
      const userSettings = new Settings();
      userSettings.clearSettings();
    },
    async saveProductDetailSettings(context, settings) {
      const userSettings = new Settings();
      await userSettings.saveProductDetailSettings(settings);
      context.commit('setProductDetailSettings', settings);
    },
    async saveSearchConditions(context, settings) {
      const userSettings = new Settings();
      await userSettings.saveSearchConditions(settings);
      context.commit('setSearchConditions', settings);
    },
    async saveDisplayOrders(context, settings) {
      const userSettings = new Settings();
      await userSettings.saveDisplayOrders(settings);
      context.commit('setDisplayOrders', settings);
    },
  },
  modules: {
  }
})
