<template>
  <div>
    <div v-if="asins != void 0 && asins.length > 0" class="text-right">
      <b-button class="btn clear text-white mr-2" :disabled="disabledClear ? disabledClear : void 0" @click="clear"><font-awesome-icon icon="fa-sharp fa-reguler fa-eraser" style="color: #fff;" /> {{ t('閲覧履歴をクリア') }}</b-button>
    </div>

    <div v-if="asins != void 0">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultList" :asins="asins" :key="resultListKey" :domainId="domain" @loaded="loaded"></result-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import ResultList from '@/components/ResultList.vue'
import Utils from '@/mixins/utils';

export default {
  name: 'BrowsingHistoryPage',
  components: {
    ResultList,
  },
  mixins: [Utils],
  props: ['show'],
  data() {
    return {
      asins: null,
      resultListKey: 0,
      disabledClear: true, // 閲覧履歴をクリア非活性フラグ
    }
  },
  async mounted() {
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain() {
      if (this.asins != void 0) {
        this.load();
      }
    },
    show() {
      if (this.show) {
        this.load();
      }
    }
  },
  methods: {
    async load() {
      this.disabledClear = true;
      const domain = this.$store.getters.getDomain;
      this.asins = (await this.loadAsins(this.$store.state.user.username))
        .filter(item => this.getDomainId(item.domain) == domain)
        .map(item => item.asin);
      this.resultListKey++;
    },
    async loadAsins(username) {
      const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: username }));
      if (query.data.getFavorite != void 0) {
        return query.data.getFavorite.browsingAsins ?? [];
      }
      return [];
    },
    loaded() {
      this.disabledClear = false;
    },
    async clear() {
      this.$refs.resultList.clear();
      await API.graphql(
        graphqlOperation(mutations.updateFavorite, {
          input: {
            owner: this.$store.state.user.username,
            browsingAsins: [],
          },
        })
      ).catch((err) => console.error(err));
    },
  }
}
</script>
<style scoped>
.clear {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
</style>
