import moment from 'moment';
import { Auth, API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations';

/**
 * Favoritesを管理するクラス
 */
export class Favorites {
  /**
   * @constructor
   * @param defaultDomain
   */
  constructor(defaultDomain, username) {
    this.KEY = `${username}_favorites`;
    this._defaultDomain = defaultDomain;
  }

  /**
   * お気に入りを取得します。
   */
  async getFavorites() {
    const value = localStorage.getItem(this.KEY);
    if (value == void 0 || value == '') {
      this.saveCache(await Favorites._loadFavorites());
    }
    return JSON.parse(localStorage.getItem(this.KEY));
  }

  /**
   * asinがお気に入りにあるかどうかを返します。
   * @param asin
   * @param domain
   * @returns
   */
  async hasFavorite(asin, domain) {
    const favorites = await this.getFavorites();
    return favorites.findIndex(v => (v.domain ?? this._defaultDomain) == domain && v.asin === asin) > -1;
  }

  /**
   * お気に入りを追加します。
   * @param username
   * @param asin
   * @param title
   * @param domain
   */
  async addFavorite(username, asin, title, domain) {
    const curFavorites = await Favorites.loadFavorites(username);
    const newFavorites = Favorites._excludeFav(curFavorites, asin, domain, this._defaultDomain);
    const date = moment().utc().format();
    newFavorites.push({ asin, title, domain, date });
    try {
      await API.graphql(
        graphqlOperation(mutations.updateFavorite, {
          input: {
            owner: username,
            asins: newFavorites,
          },
        })
      );
    }
    catch {
      console.warn('no favorite');
      await API.graphql(
        graphqlOperation(mutations.createFavorite, {
          input: {
            owner: username,
            asins: newFavorites,
          },
        })
      ).catch((err) => console.error(err));
    }
    this.saveCache(newFavorites);
  }

  /**
   * お気に入りを削除します。
   * @param username
   * @param asin
   * @param domain
   */
  async removeFavorite(username, asin, domain) {
    const curFavorites = await Favorites.loadFavorites(username);
    const newFavorites = Favorites._excludeFav(curFavorites, asin, domain, this._defaultDomain);
    await API.graphql(
      graphqlOperation(mutations.updateFavorite, {
        input: {
          owner: username,
          asins: newFavorites,
        },
      })
    ).catch((err) => console.error(err));
    this.saveCache(newFavorites);
  }

  /**
   * お気に入りをクリアします。
   * @param username
   */
  async clear(username) {
    await API.graphql(
      graphqlOperation(mutations.updateFavorite, {
        input: {
          owner: username,
          asins: [],
        },
      })
    ).catch((err) => console.error(err));
    this.saveCache([]);
  }

  /**
   * キャッシュにお気に入りを保存します。
   */
  saveCache(newFavorites) {
    //asinとdomainのみキャッシュする
    localStorage.setItem(this.KEY, JSON.stringify(newFavorites.map(item => ({ asin: item.asin, domain: item.domain }))));
  }

  /**
   * キャッシュをクリアします。
   */
  clearCache() {
    localStorage.removeItem(this.KEY);
  }

  /**
   * お気に入り一覧を取得します。
   * @param username
   * @returns
   */
  static async loadFavorites(username) {
    const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: username }));
    if (query.data.getFavorite != null && query.data.getFavorite.asins != null) {
      return query.data.getFavorite.asins.map(item => ({ asin: item.asin, domain: item.domain, title: item.title, memo: item.memo, date: item.date })) ?? [];
    }
    return [];
  }

  /**
   * お気に入りセラーを取得します。
   * @param username
   * @returns
   */
  static async loadFavoriteSellers(username) {
    const query = await API.graphql(graphqlOperation(queries.getFavoriteSeller, { owner: username }));
    const sellers = query.data?.getFavoriteSeller?.sellers || [];
    return sellers.map(item => ({ id: item.id, domain: item.domain, displayName: item.displayName, memo: item.memo, date: item.date })) ?? [];    
  }

  /**
   * お気に入りセラーを追加します。
   * @param username
   * @param sellers - FavoriteSellerInfo型の配列
   */
  static async addFavoriteSellers(username, sellers) {
    const curFavorites = await Favorites.loadFavoriteSellers(username);
    // 既存のお気に入りセラーIDに新しいセラーIDを追加
    const updatedSellers = [...(curFavorites || []), ...sellers];
    await Favorites.updateFavoriteSellers(username, updatedSellers);
  }

  /**
   * 指定されたdomainとidを持つアイテムをセラーのリストから削除し、更新します。
   * @param username
   * @param removeItem - FavoriteSellerInfo型
   */
  static async removeFavoriteSeller(username, removeItem) {
    // セラーのリストを取得
    const sellers = await this.loadFavoriteSellers(username);

    // 条件に一致するアイテムを削除
    const updatedSellers = sellers.filter(item => !(item.id === removeItem.id && item.domain === removeItem.domain));

    // 更新されたリストでセラー情報を更新
    await this.updateFavoriteSellers(username, updatedSellers);
  }

  
  /**
   * 指定されたdomainとidを持つアイテムを置き換え、更新します。
   * @param username
   * @param editItem - FavoriteSellerInfo型
   */
  static async editFavoriteSeller(username, editItem) {
    // セラーのリストを取得
    const sellers = await this.loadFavoriteSellers(username);

    // 条件に一致するアイテムを更新
    const updatedSellers = sellers.map(item => {
      if (item.id === editItem.id && item.domain === editItem.domain) {
        return editItem;
      }
      return item;
    });

    // 更新されたリストでセラー情報を更新
    await this.updateFavoriteSellers(username, updatedSellers);
  }

  /**
   * お気に入りのセラーを更新・作成します。
   * @param username 
   * @param updatedSellers 
   */
  static async updateFavoriteSellers(username, updatedSellers) {
    try {
      await API.graphql(
        graphqlOperation(mutations.updateFavoriteSeller, {
          input: {
            owner: username,
            sellers: updatedSellers, // お気に入りセラーIDを更新
          },
        })
      );
    } catch {
      console.warn('Favorite not found. Creating a new favorite record.');
      await API.graphql(
        graphqlOperation(mutations.createFavoriteSeller, {
          input: {
            owner: username,
            sellers: updatedSellers, // 新しいお気に入りレコードを作成
          },
        })
      ).catch((err) => console.error(err));
    }
  }

  static async _loadFavorites() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => {});
    if (cognitoUser != void 0) {
      const query = await API.graphql(graphqlOperation(queries.getFavorite, { owner: cognitoUser.username }));
      if (query.data.getFavorite != void 0 && query.data.getFavorite.asins != void 0) {
        return query.data.getFavorite.asins.map(item => ({ asin: item.asin, domain: item.domain, title: item.title, memo: item.memo, date: item.date })) ?? [];
      }
    }
    return [];
  }

  static _excludeFav(favorites, asin, domain, defaultDomain) {
    return favorites.filter(v => (v.domain ?? defaultDomain) != domain || v.asin !== asin);
  }
}
