/**
 * Shop定義
 * @param {string} name ショップ名
 * @param {string} label ショップラベル（便宜上定義してあるが、表示にはmessagesで定義したものを使用する）
 * @param {string[]} features ショップの機能
 */

class Shop {
    constructor(name, label, regions = [],features = []) {
        this.name = name;
        this.label = label;
        this.regions = new Set(regions); 
        this.features = features;
    }

    isTargetRegion(targetRegion) {
        return this.regions.has(targetRegion);
    }
}

// シ
const Shops = Object.freeze({
    AMAZON: new Shop('amazon', 'Amazon', ["JP","COM"], ['出品一覧', 'レビュー', '出品規制']),
    MERCARI: new Shop('mercari', 'メルカリ', ["JP"]),
    RAKUMA: new Shop('rakuma', 'ラクマ', ["JP"]),
    RAKUTEN: new Shop('rakuten', '楽天', ["JP"]),
    YAHOO_AUCTION: new Shop('yahoo_auction', 'ヤフオク', ["JP"]),
    YAHOO_SHOP: new Shop('yahoo_shop', 'Y!ショ', ["JP"]),
    AU_PAY: new Shop('au_pay', 'AU Pay', ["JP"]),
    YAHOO_MALL: new Shop('yahoo_mall', 'Yモール', ["JP"]),
    QOO10: new Shop('qoo10', 'Qoo10', ["JP"]),
    GOOGLE: new Shop('google', 'Google', ["JP","COM"]),
});

export default Shops;