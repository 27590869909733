export default {
  "コンディション": "Condition",
  "価格": "Price",
  "（出品数）": "(No. of products)",
  "損益分岐点": "BEP",
  "自社": "FBM",
  "新品": "New",
  "プライム": "Prime",
  "プライム以外": "Non-Prime",
  "※送料込の価格になります。": "*Price includes shipping.",
  "中古 ": "Used",
  "ほぼ新品": "Almost new",
  "非常に良い": "Very good",
  "良い": "Good",
  "可": "Acceptable",
  "カート": "Cart",
  "参考価格": "Reference price",
  "利益計算": "Profit calculation",
  "※FBA手数料が正常に取得ができないため、損益分岐点の計算が行われていません。お手数ですがFBA料金シミュレーターをご利用ください。": "*The break-even point has not been calculated because FBA fees cannot be obtained correctly. Please use the FBA fee simulator.",
  "displayInYenEn": "Display in Yen",
  "displayInYenEnL": " ",
  "displayInYenEnR": " ",
  "（※{0}円）": "(*{0} Yen)",
};
