<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('売上個数予測') }}</manual-link>

      <div class="controls d-flex flex-column m-2">
        <div class="search input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
              </svg>
            </span>
          </div>
          <b-dropdown :text="condition.category.name" class="input-group-append dropdown-search d-sm-none" size="sm">
            <b-dropdown-item v-for="item in categoryList" :key="item.key" class="small" @click="categorySelected(item)">
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown :text="condition.category.name" class="input-group-append dropdown-search d-none d-sm-flex">
            <b-dropdown-item v-for="item in categoryList" :key="item.catid" @click="categorySelected(item)">
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>

          <input type="number" min="1" class="form-control py-4 text-right" :placeholder="t('予測ランキング')" :aria-label="t('予測ランキング')" aria-describedby="search-rank" v-model="condition.rank" @keydown.enter="searchRank" />
          <div class="input-group-append">
            <b-button class="btn bg-eresa text-white px-3" type="button" @click="searchRank">{{ t('検索') }}</b-button>
          </div>
        </div>
      </div>
      <span class="small text-secondary m-2">{{ t('※予測ランキングは1～10000の範囲を指定できます。') }}</span>
    </div>

    <div v-if="salesEstimations != void 0 && salesEstimations.length > 0" class="container sales-estimate-table mt-5">
      <div class="font-weight-bold border-bottom">{{ t('カテゴリー') }}</div>
      <div class="font-weight-bold border-bottom">{{ t('予測ランキング') }}</div>
      <div class="font-weight-bold border-bottom">{{ t('週間売上個数') }}</div>
      <div class="font-weight-bold border-bottom">{{ t('月間売上個数') }}</div>
      <template v-for="item in salesEstimations">
        <div class="border-bottom" :key="`${item.key}-category`">{{ item.categoryName }}</div>
        <div class="border-bottom text-right" :key="`${item.key}-rank`">{{ salesRanking(item.rank) }}</div>
        <div class="border-bottom text-right" :key="`${item.key}-weekly`">{{ salesCount(item.weekly_sales_estimate) }}</div>
        <div class="border-bottom text-right" :key="`${item.key}-monthly`">{{ salesCount(item.monthly_sales_estimate) }}</div>
      </template>
    </div>
    <Loading v-else-if="loading"></Loading>
  </div>
</template>

<script>
import { RainforestConstants } from "@/store/rainforest";
import ManualLink from "@/components/ManualLink.vue";
import Loading from "@/components/Loading.vue";
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";

const MANUAL_JP = 'https://pro.eresa.jp/function/sales-quantity-function/';
const MANUAL_COM = 'https://eresa.io/function/sales-quantity-function/';

const categoryNameAll = 'すべて';

export default {
  name: 'SalesEstimation',
  components: {
    ManualLink,
    Loading,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      condition: {
        asin: null,
        category: { catid: null, name: this.t(categoryNameAll), rainforestCat: null },
        rank: null,
        domain: null,
      },
      categoryList: null,
      salesEstimations: null,
      loading: false,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async mounted() {
    const domain = this.$store.getters.getDomain;
    this.condition.domain = domain;
    await this.initializeCategoryList(domain);
    await this.validateSubscriber();
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain(val) {
      this.initializeCategoryList(val);
      this.condition.domain = val;
    },
  },
  methods: {
    searchRank() {
      if (this.condition.rank == void 0 || this.condition.rank <= 0) {
        return;
      }
      this.salesEstimations = [];
      this.loading = true;
      try {
        this.salesEstimationRank(this.condition);
      }
      finally {
        this.loading = false;
      }
    },
    categorySelected(item) {
      const categoryId = item.catid;
      const target = this.categoryList.filter(c => c.catid === categoryId);
      if (target.length > 0) {
        this.condition.category = Object.assign({}, target[0]);
      }
    },
    async initializeCategoryList(domain) {
      this.categoryList = [];
      const iscom = domain === this.$store.getters.getComDomain;
      this.categoryList.push({ catid: null, name: this.t(categoryNameAll), rainforestCat: null });
      const catsrc = iscom ? RainforestConstants.CatalogCom : RainforestConstants.CatalogJp;
      this.categoryList.push(...catsrc.filter(c => c.catid !== null && c.rainforestCat !== null));
    },
    async salesEstimationRank(condition) {
      const catid = condition.category.catid;
      const category = condition.category.rainforestCat;
      const name = condition.category.name;
      const rank = condition.rank;
      const domain = condition.domain;

      if (catid == void 0) {
        //すべて
        for (const item of this.categoryList.slice(1)) {
          const r = this.estimateSales(item.catid, domain, rank);
          this.salesEstimations.push({ category: item.rainforestCat, categoryName: item.name, rank, weekly_sales_estimate: r == void 0 ? '-' : Math.floor(r / 4), monthly_sales_estimate: r ?? '-' });
        }
      }
      else {
        const r = this.estimateSales(catid, domain, rank);
        this.salesEstimations.push({ category, categoryName: name, rank, weekly_sales_estimate: r == void 0 ? '-' : Math.floor(r / 4), monthly_sales_estimate: r ?? '-' });
      }
    },
    salesCount(val) {
      return this.t('{0}個', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    salesRanking(val) {
      return this.t('{0}位', [val === null || val === undefined || val < 0 ? '-' : val]);
    },
  },
}
</script>

<style scoped>
.controls {
  max-width: 576px;
}
.sales-estimate-table {
  display:grid;
  grid-template-columns: 20em 10em 10em 10em;
}
.sales-estimate-table div {
  padding: 10px;
}
@media (max-width: 575.98px) {
  .sales-estimate-table {
    grid-template-columns: 1fr 8em 8em 8em;
    font-size: 9pt;
  }
}
</style>
