export default {
  "共有された条件を追加": "Add shared conditions",
  "※共有された条件のリンクを追加することで同じ内容の条件を作成することができます。": "*You can create a condition with the same content by adding a link to a shared condition.",
  "検索条件をクリア": "Clear search criteria",
  "共有": "Share",
  "条件名を変更": "Change condition name",
  "保存する": "Save",
  "キャンセル": "Cancel",
  "削除": "Delete",
  "条件名を入力してください": "Please enter a condition name.",
  "コピーされました": "Copied.",
}
