export default {
  "ログイン": "Login",
  "メールアドレス": "Email address",
  "メールアドレスを入力してください": "Please enter your email address",
  "パスワード": "Password",
  "パスワードを入力してください": "Please enter your password",
  "パスワードを忘れた場合": "Forgot your password?",
  "パスワードのリセット": "Reset password",
  "まだアカウントがない場合": "If you do not have an account yet",
  "アカウント作成": "Create Account",
  "他のアカウントでログイン": "Log in with another account",
  "Google アカウントでログイン": "Sign in with your Google Account",
}
