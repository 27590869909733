<template>
  <div class="page-content">
    <div class="container">
      <h1 class="font-weight-bold pb-5">{{ t('トラッキング') }}</h1> <b-alert show variant="warning">{{ t('ベータ版機能テスト中') }}</b-alert>
    </div>

    <loading-screen v-if="isProcessing"></loading-screen>

    <div v-if="item != void 0" class="container d-flex flex-wrap pb-4">
      <!-- Hide this on mobile ( less than 576px ) -->
      <b-row class="d-none d-md-flex flex-wrap px-4">
        <b-col cols="2">
          <product-image :item="item" sm></product-image>
          <img :src="item.keepaGraphUrl" class="w-100 mt-5" />
        </b-col>
        <b-col>
          <router-link :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: isDefaultDomain(domain) ? void 0 : domain } }" class="text-eresa">
            <h6 class="headertitle">{{ item.title }}</h6>
          </router-link>
          <b-row class="text-sm">
            <b-col cols="12" lg="7" class="mb-4 mb-lg-0">
              <ranking-drop :item="item" :subscriber="true" class="text-secondary mb-2"></ranking-drop>
              <price-table-sm :item="item" :subscriber="true"></price-table-sm>
            </b-col>
            <b-col class="mx-3">
              <div class="d-flex flex-wrap">
                <h6 class="header text-uppercase text-secondary mb-1 font-weight-bold mr-3">Asin: {{ item.asin }}</h6><h6 class="header text-uppercase text-secondary mb-1 font-weight-bold">{{ t('JAN') }}: {{ item.eanList ? item.eanList[0] : '-' }}</h6>
              </div>
              <div>
                <h6 class="header text-secondary mb-1">{{ t('メーカー') }}: {{ item.brand }}</h6>
              </div>
              <div>
                <h6 class="header text-secondary mb-1">{{ t('取り扱い開始日') }}: {{ item | listedSinceString }}</h6>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Hide this on desktop ( above 576px ) -->
      <div class="d-flex d-md-none flex-wrap">
        <b-row class="mb-4">
          <b-col cols="6">
            <product-image :item="item" sm class="text-center"></product-image>
          </b-col>
          <b-col cols="6">
            <img :src="item.keepaGraphUrl" class="w-100 mt-3" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <router-link :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: isDefaultDomain(domain) ? void 0 : domain } }" class="text-eresa">
              <h6 class="headertitle">{{ item.title }}</h6>
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="text-sm">
              <b-col cols="12" class="mb-4">
                <ranking-drop :item="item" :subscriber="true" class="text-secondary mb-2"></ranking-drop>
                <price-table-sm :item="item" :subscriber="true"></price-table-sm>
              </b-col>
              <b-col>
                <div class="d-flex flex-wrap">
                  <h6 class="header text-uppercase text-secondary mb-1 font-weight-bold mr-3">Asin: {{ item.asin }}</h6><h6 class="header text-uppercase text-secondary mb-1 font-weight-bold">Jan: {{ item.eanList ? item.eanList[0] : '-' }}</h6>
                </div>
                <div>
                  <h6 class="header text-secondary mb-1">{{ t('メーカー') }}: {{ item.brand }}</h6>
                </div>
                <div>
                  <h6 class="header text-secondary mb-1">{{ t('取り扱い開始日') }}: {{ item | listedSinceString }}</h6>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="container tracking pb-5">
      <h6 class="font-weight-bold"><div class="point bg-secondary"></div>{{ t('価格') }}</h6>
      <div class="condgroup">
        <tr-value-condition class="mr-2" ref="amazonPrice" csv="0" :domain="domain" title="Amazon"></tr-value-condition>
        <div class="d-flex flex-column">
          <tr-value-condition class="mr-2" ref="newPrice" csv="1" :domain="domain" :title="t('新品')"></tr-value-condition>
          <tr-value-condition class="mr-2" ref="newFbaPrice" csv="10" :domain="domain" :title="t('新品(FBA)')"></tr-value-condition>
          <tr-value-condition class="mr-2" ref="newFbmPrice" csv="7" :domain="domain" :title="t('新品（送料込）')"></tr-value-condition>
        </div>
        <div class="d-flex flex-column">
          <tr-value-condition class="mr-2" ref="usedPrice" csv="2" :domain="domain" :title="t('中古')"></tr-value-condition>
          <tr-value-condition class="mr-2" ref="warehousePrice" csv="9" :domain="domain" :title="t('中古(FBA)')"></tr-value-condition>
          <tr-value-condition class="mr-2" ref="usedShippingPrice" csv="19,20,21,22" :domain="domain" :title="t('中古（送料込）')"></tr-value-condition>
        </div>
        <tr-value-condition class="mr-2" ref="buyboxPrice" csv="18" :domain="domain" :title="t('カート')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="listPrice" csv="4" :domain="domain" :title="t('参考価格')"></tr-value-condition>
      </div>

      <div class="condgroup mt-2">
        <tr-value-condition class="mr-2" ref="collectiblePrice" csv="5" :domain="domain" :title="t('コレクター商品')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="refurbishedPrice" csv="6" :domain="domain" :title="t('整備済み品')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="lightningDealPrice" csv="8" :domain="domain" :title="t('タイムセール')"></tr-value-condition>
      </div>

      <div class="condgroup mb-1">
        <b-form-checkbox switch size="sm" v-model="notifyLightningDeal" class="switch mt-2 mr-4"><span class="text-secondary small">{{ t('タイムセールが開始されたら通知を受け取る') }}</span></b-form-checkbox>
      </div>

      <h6 class="font-weight-bold"><div class="point bg-secondary mt-4"></div>{{ t('アイテム数') }}</h6>
      <div class="condgroup">
        <tr-value-condition class="mr-2" ref="newCount" csv="11" :domain="domain" :title="t('新品')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="usedCount" csv="12" :domain="domain" :title="t('中古')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="collectibleCount" csv="14" :domain="domain" :title="t('コレクター商品')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="refurbishedCount" csv="13" :domain="domain" :title="t('整備済み品')"></tr-value-condition>
      </div>

      <h6 class="font-weight-bold"><div class="point bg-secondary mt-4"></div>{{ t('評価') }}</h6>
      <div class="condgroup">
        <tr-value-condition class="mr-2" ref="sales" csv="3" :domain="domain" :title="t('売れ筋ランキング')"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="rating" csv="16" :domain="domain" :title="t('評価(1.0～5.0)')" digit="1" coef="10" minvalue="1" maxvalue="5"></tr-value-condition>
        <tr-value-condition class="mr-2" ref="reviewCount" csv="17" :domain="domain" :title="t('レビュー数')"></tr-value-condition>
      </div>

      <h6 class="font-weight-bold"><div class="point bg-secondary mt-4"></div>{{ t('その他') }}</h6>
      <div class="condgroup">
        <div class="condition">
          <div class="text-secondary valuelabel-xs small d-sm-none">{{ t('メモ') }}</div>
          <div class="text-secondary valuelabel small d-none d-sm-inline">{{ t('メモ') }}</div>
          <input type="text" class="form-control stringtext ml-2 mr-2 mb-2" maxlength="20" v-model="memo" />
        </div>
      </div>
      <div class="condgroup">
        <div class="condition">
          <div class="text-secondary valuelabel-xs small d-sm-none">{{ t('タグ') }}</div>
          <div class="text-secondary valuelabel small d-none d-sm-inline">{{ t('タグ') }}</div>
          <input type="text" class="form-control stringtext ml-2 mr-2 mb-2" maxlength="20" v-model="tag" />
        </div>
      </div>
      <div class="condgroup">
        <div class="text-secondary small mt-1 mr-4">{{ t('トラッキング期間') }}</div>
        <b-dropdown :text="ttl.name" split split-variant="outline-primary" variant="dark" class="dropdown-eresa small mr-4" size="sm">
          <b-dropdown-item v-for="item in ttlList" :key="item.key" class="small" @click="ttlSelected(item)">
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="footer mt-4">
        <b-button class="btn bg-reset text-white px-3 mr-2" @click="clear">{{ t('リセット') }}</b-button>
        <b-button class="btn bg-eresa text-white px-3" :disabled="isProcessing" @click="start">{{ t('トラッキング開始') }}</b-button>
      </div>
    </div>
    <b-modal id="errorModal" ok-only title="ERROR">{{ t('トラッキング開始に失敗しました。') }}</b-modal>
    <b-modal id="errorCount" ok-only title="ERROR">{{ t('これ以上登録できません。') }}</b-modal>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import TrValueCondition from "@/components/TrackingValueCondition.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import ProductImage from "@/components/Product/ProductImage";
import RankingDrop from "@/components/Product/RankingDrop";
import PriceTableSm from "@/components/Product/PriceTableSm.vue";
import Utils from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";
import { ProductInfo } from "@/mixins/ProductInfo";

const LIGHTNING_DEAL = 8;
const BACK_IN_STOCK = 1;

const MAX_TRACKING = 5000;

export default {
  name: 'TrackingDetail',
  components: {
    TrValueCondition,
    LoadingScreen,
    ProductImage,
    RankingDrop,
    PriceTableSm,
  },
  mixins: [Utils, AuthUtil],
  filters: {
    listedSinceString(item) {
      return new ProductInfo(item).listedSinceString;
    },
  },
  data() {
    return {
      item: null,
      list: [],
      asin: null,
      domain: this.$store.getters.getDomain,
      notifyLightningDeal: false,
      memo: null,
      tag: null,
      ttlList: [
        { value: 720, name: this.t('30日') },
        { value: 2160, name: this.t('90日') },
        { value: 4320, name: this.t('180日') },
        { value: 8760, name: this.t('1年') },
      ],
      ttl: { value: 8760, name: this.t('1年') },
      isNew: false,
      isProcessing: false,
    }
  },
  async created() {
    const isCom = this.isComDomain(this.$route.query.domain);
    this.$store.commit("setDomainCom", isCom);
    this.domain = this.$store.getters.getDomain;
    this.asin = this.$route.params.asin;
  },
  async mounted() {
    this.isProcessing = true;
    this.list = [
      this.$refs.amazonPrice,
      this.$refs.newPrice,
      this.$refs.usedPrice,
      this.$refs.buyboxPrice,
      this.$refs.listPrice,
      this.$refs.newFbaPrice,
      this.$refs.newFbmPrice,
      this.$refs.warehousePrice,
      this.$refs.usedShippingPrice,
      this.$refs.collectiblePrice,
      this.$refs.refurbishedPrice,
      this.$refs.lightningDealPrice,
      this.$refs.newCount,
      this.$refs.usedCount,
      this.$refs.collectibleCount,
      this.$refs.refurbishedCount,
      this.$refs.sales,
      this.$refs.rating,
      this.$refs.reviewCount,
    ];
    await this.setTracking();
    this.isProcessing = false;
    await this.validateSubscriber();
  },
  computed: {
  },
  methods: {
    clear() {
      for (const item of this.list) {
        item.clear();
        this.notifyLightningDeal = false;
        this.memo = null;
        this.tag = null;
        this.ttl = { value: 8760, name: this.t('1年') };
      }
    },
    ttlSelected(item) {
      this.ttl = { value: item.value, name: item.name };
    },
    async setTracking() {
      this.item = await this.getProduct(this.asin, this.domain);
      const res = await this.getTracking(this.asin, this.domain);
      if (!res) {
        this.$store.commit("setIsSubscriber", false);
        this.refreshToken();
        this.$router.push({ name: "Default" });
        return;
      }
      if (res.trackings == void 0) {
        this.isNew = true;
        return;
      }
      const tracking = res.trackings[0];
      for (const item of this.list) {
        item.setValue(tracking);
      }
      //タイムセール開始通知
      if (tracking.notifyIf != void 0) {
        for (const item of tracking.notifyIf) {
          if (item.csvType === LIGHTNING_DEAL) {
            this.notifyLightningDeal = item.notifyIfType === BACK_IN_STOCK;
            break;
          }
        }
      }
      //メモ・タグ
      if (tracking.metaData != void 0) {
        const metaData = JSON.parse(tracking.metaData);
        this.memo = unescape(metaData.memo);
        this.tag = unescape(metaData.tag);
      }
      //トラッキング期間
      if (tracking.ttl != void 0) {
        for (const ttl of this.ttlList) {
          if (ttl.value === tracking.ttl) {
            this.ttl = { value: ttl.value, name: ttl.name };
            break;
          }
        }
      }
    },
    async start() {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;

      let thresholdValues = [];
      for (const item of this.list) {
        const q = item.query;
        if (q != void 0) {
          thresholdValues = thresholdValues.concat(q);
        }
      }

      let notifyIf = [];
      if (this.notifyLightningDeal) {
        notifyIf.push({
          domain: this.domain,
          csvType: LIGHTNING_DEAL,
          notifyIfType: BACK_IN_STOCK,
        });
      }

      if (thresholdValues.length === 0 && notifyIf.length === 0) {
        this.isProcessing = false;
        return;
      }

      const metaData = JSON.stringify({ memo: escape(this.memo ?? ''), tag: escape(this.tag ?? '') });
      const ttl = this.ttl.value;

      //トラッキング追加
      await API.graphql(graphqlOperation(mutations.removeTracking, { asin: this.asin, domain: this.domain })).catch(e => e);
      {
        //件数チェック
        const query = await API.graphql(graphqlOperation(queries.getTracking, { domain: this.domain }));
        const res = JSON.parse(query.data.getTracking);
        if (!res) {
          this.$store.commit("setIsSubscriber", false);
          this.refreshToken();
          this.$router.push({ name: "Default" });
          return;
        }
        if (res.count >= MAX_TRACKING) {
          this.isProcessing = false;
          this.$bvModal.show("errorCount");
          return;
        }
      }
      const mutation = await API.graphql(graphqlOperation(mutations.addTracking,
        { asin: this.asin, ttl, thresholdValues: JSON.stringify(thresholdValues), notifyIf: JSON.stringify(notifyIf), metaData, domain: this.domain })).catch(e => e);
      //console.log(mutation);
      if (mutation.errors != void 0 && mutation.errors.length > 0) {
        this.isProcessing = false;
        this.$bvModal.show("errorModal");
        return;
      }
      const res = JSON.parse(mutation.data.addTracking);
      if (!res) {
        this.$store.commit("setIsSubscriber", false);
        this.refreshToken();
        this.$router.push({ name: "Default" });
        return;
      }
      if (res.trackings == void 0) {
        this.$bvModal.show("errorModal");
        this.isProcessing = false;
        return;
      }
      this.$router.push({ name: "TrackingList" });
    },
    async getProduct(asin, domain) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain })
      ).then(async (rslt) => {
        if (rslt.data.getProductDetail == void 0) {
          return null;
        }
        const item = JSON.parse(rslt.data.getProductDetail.json)[0];
        const path = item.domainId == this.$store.getters.getComDomain ? `${item.domainId}/png/${item.asin}.png` : `png/${item.asin}.png`;
        await Storage.get(path).then(url => (item.keepaGraphUrl = url));
        return item;
      });
    },
    async getTracking(asin, domain) {
      const query = await API.graphql(graphqlOperation(queries.getTracking, { asin, domain }));
      return JSON.parse(query.data.getTracking);
    },
  }
}
</script>
<style scoped>
.productimg {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.tracking {
  padding: 50px;
}
.condgroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.footer {
  width: 100%;
  text-align: center;
}
.bg-reset {
  background-color: #379992;
}
.point {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.condition {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.valuelabel {
  margin-top: 10px;
}
.valuelabel-xs {
  margin-top: 10px;
  min-width: 120px;
}
.stringtext {
  width: 200px;
  padding: 4px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance:none;
  font-size: 10pt;
}
.headertitle {
  font-size: 12pt;
}
.header {
  font-size: 10pt;
}
@media (max-width: 575.98px) {
  .tracking {
    padding: 15px;
  }
  .headertitle {
    font-size: 10pt;
  }
}
</style>