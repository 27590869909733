export default {
  "アフィリエイト": "Affiliate",
  "ERESAアフィリエイトプログラムのご案内": "ERESA Affiliate Program Information",
  "ERESAアフィリエイトプログラムとは？": "What is the ERESA Affiliate Program?",
  "いつもERESAをご利用頂きましてありがとうございます。ERESAでは、あなたのお友達、ブログ・メルマガの読者さまにERESAをご紹介頂くことで、報酬を受け取ることが出来るアフィリエイトプログラムをご用意しております。": "Thank you for visiting ERESA, we have an affiliate program that allows you to earn commissions by referring your friends, blog and newsletter readers to ERESA.",
  "ユーザー1名様をご紹介頂くと、ERESA PRO版（月額プラン）で796円、ERESA PRO版（年額プラン）で7,960円を契約更新毎にお支払いいたします。": "If you refer one user, you will receive 796 yen for ERESA PRO version (monthly plan) and 7,960 yen for ERESA PRO version (annual plan) for each contract renewal.",
  "是非、ご協力・ご登録をよろしくお願いいたします。": "We appreciate your cooperation and registration.",
  "※ERESA PRO会員向けのプログラムとなります。ERESA PRO会員をキャンセルしますと報酬が発生しなくなりますのでご注意ください。": "*Please note that this program is for ERESA PRO members, and if you cancel your ERESA PRO membership, you will not receive any commissions.",
  "アフィリエイトURL": "Affiliate URL",
  "下記URLがあなたのアフィリエイトリンクとなります。": "The URL below will be your affiliate link.",
  "コピーされました": "Copied.",
  "ERESAアフィリエイトプログラムに参加する": "Join the ERESA Affiliate Program",
  "ERESAアフィリエイトプログラムに参加しますか？": "Would you like to join the ERESA Affiliate Program?",
  "報酬振込先口座": "Account to which remuneration should be transferred",
  "銀行名 / 銀行コード": "Bank name / Bank code",
  "銀行名を選択": "Select a bank name",
  "支店名 / 支店コード": "Branch name / Branch code",
  "支店名を選択": "Select a branch name",
  "口座種類": "Account type",
  "普通": "Ordinary savings account",
  "当座": "Checking account",
  "貯蓄": "Savings account",
  "口座番号": "Account number",
  "口座名義": "Account name",
  "インボイス発行事業者登録番号": "Invoice issuer registration number",
  "インボイス発行事業者ではありません": "Not an invoice issuing business",
  "<a href=\"https://eresa.jp/affiliate-terms/\" target=\"_blank\">ERESAアフィリエイトセンター会員利用規約</a>に同意して登録します。": "I agree to the <a href=\"https://eresa.jp/affiliate-terms/\" target=\"_blank\">ERESA Affiliate Center Membership Terms</a> and Conditions and register.",
  "ヤマダ　タロウ": "Taro Yamada",
  "口座情報を更新する": "Update account information",
  "報酬条件・成約状況": "Commission terms and conditions of contract",
  "プラン": "plan",
  "報酬": "Commission",
  "成約数 / 紹介無料会員": "Number of contracts / Invited free members",
  "円 / 1ヶ月": "Yen / 1 month",
  "円 / 1年": "Yen / 1 year",
  "アカウント": "Account",
  "報酬履歴": "Commission history",
  "残高": "Balance",
  "入出金履歴": "Deposit and withdrawal history",
  "入出金日": "Deposit and withdrawal date",
  "入出金額": "Deposit and withdrawal of money",
  "摘要": "Summary",
  "特筆事項": "Special mention",
  "報酬は毎月1日に前月の成約数に基づいて確定し、報酬残高に追加されます。確定した報酬の振込は翌月の10日に行われます。（例：10月分のアフィリエイト報酬は11月1日に確定し、12月10日に振込が行われます。）": "Rewards are determined on the first of each month based on the number of contracts signed during the previous month and are added to the reward balance. The confirmed commissions will be transferred on the 10th of the following month (e.g. October commissions are confirmed on November 1st and transferred on December 10th). (e.g., October commissions are finalized on November 1 and transferred on December 10).",
  "口座情報に誤りがある場合は組み戻しとなります。その際の振込手数料は返金致しません。": "If the account information is incorrect, it will be reclassified. Transfer fees will not be refunded in such cases.",
  "残高が5,000円未満の場合お振り込みせず、翌月へ持ち越しとなります。": "If the balance is less than 5,000 yen, it will not be transferred and will be carried over to the next month.",
  "振込手数料は270円です。": "The transfer fee is 270 yen.",
  "決済時にクーポンが適用されることで報酬額が減額されることがあります。": "The reward amount may be reduced by the application of coupons at the time of settlement.",
  "インボイス発行事業者登録番号を記入されていない場合、アフィリエイト報酬から10%を差し引いた金額のお振込みとなります。": "If you do not provide your invoice issuing business registration number, 10% will be deducted from your affiliate commission.",
}
