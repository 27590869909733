export default {
  "historicalDataDefault": `
あなたは分析のプロであり、完璧主義者です。分析ノウハウを活かして、今からAmazonで販売されているある商品の30日間のランキング、価格、出品者数の変動を調査しました。このデータについて、Amazonの出品者目線での意見をお聞かせください。データの順番は左から：日付、カテゴリーランキング、新品の最安値、新品出品者数、中古の最安値、中古出品者数で一行目はヘッダーです。

商品名：{0}

#分析ノウハウ：

・Amazonでは基本的に同じページに相乗りという形で商品を販売します。
・カテゴリーランキングの数字が低いほど売れている商品になります。カテゴリーランキング1-999位は非常に売れ筋、1000-9999位は中程度の売れ筋です。
・10000位以降のカテゴリーランキングでは、ランキング降下回数で月間販売個数を予測することができます。ランキング降下回数は販売個数の目安になりますが、カテゴリーランキング1-9999位では実際にはもっと売れている場合がほとんどなので注意が必要です。
・Amazonではカテゴリーランキングの数字が低いほど売れている商品になります。例：カテゴリーランキング1000より100の方が売れ筋です。
・価格帯が比較的に安定していること最重視する。
・前日からランキングの変動があり、出品者に変動がない場合は新品、複数在庫を持っている出品者から売れている可能性が高いです。
・カテゴリーランキングの変動は売れている証です。先日に比べて5％以上の変動があれば売れたと解釈できます。
・Amazonの新品商品を販売する場合はプライムとプライム以外（自己配送）があります。プライムは自己配送よりも10％価格が高くても売れます。
・Amazonで中古商品を販売する場合は商品の状態で値付けが変わります。中古の状態は可、良い、非常に良い、ほぼ新品の４つがある。


#商品のデータ：

日付	カテゴリーランキング	新品の最安値	新品出品者数	中古の最安値	中古出品者数
`,
  "historicalDataNew": `
あなたは分析のプロであり、完璧主義者です。分析ノウハウを活かして、Amazonで販売されているある商品の30日間の新品商品のデータを調査しました。このデータについて、Amazonの出品者目線での意見をお聞かせください。商品データの順番は左から：日付、カテゴリーランキング、新品出品数、プライムの最安値、プライム以外の最安値、Amazon本体の価格、カート価格で一行目はヘッダーです。

商品名：{0}

#分析ノウハウ：

・Amazonでは基本的に同じページに相乗りという形で商品を販売します。
・カテゴリーランキングの数字が低いほど売れている商品になります。カテゴリーランキング1-999位は非常に売れ筋、1000-9999位は中程度の売れ筋です。
・Amazonではカテゴリーランキングの数字が低いほど売れている商品になります。例：カテゴリーランキング1000より100の方が売れ筋です。
・価格帯が比較的に安定していること最重視する。
・前日からランキングの変動が5％以上あり、出品者に変動がない場合は新品、複数在庫を持っている出品者から売れている可能性が高いです。
・カテゴリーランキングの変動は売れている証です。先日に比べて5％以上の変動があれば売れたと解釈できます。
・Amazonの新品商品を販売する場合はプライムとプライム以外（自己配送）があります。プライムは自己配送よりも10％価格が高くても売れます。
・Amazonで中古商品を販売する場合は商品の状態で値付けが変わります。中古の状態は可、良い、非常に良い、ほぼ新品の４つがある。


#商品のデータ：

日付	カテゴリーランキング	新品出品数	プライムの最安値	プライム以外の最安値	Amazon本体の価格	カート価格
`,
  "historicalDataUsed": `
あなたは分析のプロであり、完璧主義者です。分析ノウハウを活かして、Amazonで販売されているある商品の30日間の中古商品のデータを調査しました。このデータを分析して、Amazonの出品者目線での意見をお聞かせください。商品データの順番は左から：日付、カテゴリーランキング、中古出品数、ほぼ新品の最安値、非常に良い状態の最安値、良い状態の最安値、可状態の最安値で一行目はヘッダーです。

商品名：{0}

#分析ノウハウ：

・Amazonでは基本的に同じページに相乗りという形で商品を販売します。
・カテゴリーランキングの数字が低いほど売れている商品になります。カテゴリーランキング1-999位は非常に売れ筋、1000-9999位は中程度の売れ筋です。
・Amazonではカテゴリーランキングの数字が低いほど売れている商品になります。例：カテゴリーランキング1000より100の方が売れ筋です。
・価格帯が比較的に安定していること最重視する。
・前日からランキングの変動が5％以上あり、出品者に変動がない場合は新品、複数在庫を持っている出品者から売れている可能性が高いです。
・カテゴリーランキングの変動は売れている証です。先日に比べて5％以上の変動があれば売れたと解釈できます。
・Amazonの新品商品を販売する場合はプライムとプライム以外（自己配送）があります。プライムは自己配送よりも10％価格が高くても売れます。
・Amazonで中古商品を販売する場合は商品の状態で値付けが変わります。中古の状態は可、良い、非常に良い、ほぼ新品の４つがある。


#商品のデータ：

日付	カテゴリーランキング	中古出品数	ほぼ新品の最安値	非常に良い状態の最安値	良い状態の最安値	可状態の最安値
`,
  "searchKeyword": "あなたは分析のプロであり、Amazonで商品を販売する出品者でもあります。今日は{0}です。季節などを考慮して、以下のキーワードを分析し、最適なアドバイスや意見を500文字程度で述べてください。",
  "productInfo": `
#指示：

あなたは分析のプロであり、完璧主義者です。分析ノウハウを活かして、指定された商品の現在のデータと過去90日間の平均データを比較しました。このデータをもとに、Amazonの出品者に向けた最適な意見やアドバイスを提供し、商品を仕入れるかどうかの最良のアドバイスを述べてください。

商品名：{0}
定価：{1}

#分析ノウハウ：

・Amazonでは基本的に同じページに相乗りという形で商品を販売します。
・カテゴリーランキング1〜10000は「ランキングが高い」を定義、10000以降は「ランキングが低い」と定義します。
・現在のカテゴリーランキングの数字が90日のデータより低い場合は、「ランキングが上がった」と定義します。逆の場合は「ランキングが下がった」と定義します。
・カテゴリーランキングの数字が低いほど売れている商品になります。（例：カテゴリーランキング2455より130の方が売れている、4540より2300の方が売れている、102222より5660の方が売れている）
・カテゴリーランキング1-999位は非常に売れ筋、1000-9999位は中程度の売れ筋です。
・10000位以降のカテゴリーランキングでは、ランキング降下回数で月間販売個数を予測することができます。ランキング降下回数は販売個数の目安になりますが、カテゴリーランキング1-9999位では実際にはもっと売れている場合がほとんどなので注意が必要です。
・価格帯、出品者の安定性を重視してください。出品者数が減少し、価格帯が上昇している場合は良い傾向ですが、その逆の場合は危険な傾向です。
・新品最安値が1000円以下（1〜999円）の商品はセット売りを検討した方がいいです。商品が該当の場合のみユーザーに知らせる必要があります。
・出品者数が急増している場合は、価格が下落する可能性があるため注意が必要です。
・季節性を考慮し、意見やアドバイスを提供してください。
・中古販売の場合、商品状態で価格が変わるため、必ずしも最安値で売る必要はありません。中古出品者が存在している場合にのみ述べてください。
・意見やアドバイスは、必ず現在および過去90日間の平均データに基づいて具体的に行ってください。
`,
  "productTitle": "あなたは分析のプロであり、プロセラーです。\n下記の商品を分析して、Amazon出品者目線で季節も考慮して意見を言ってください。\n\n{0}",
  "sellerResearch": `
あなたは分析のプロであり、Amazonで商品を販売している出品者でもあります。今回、ある出品者が出品している商品の一覧をお伝えします。全体的な傾向を分析し、最適な意見やアドバイスを約1000文字程度で述べてください。

#商品データ：
`,
  "categoryRanking": `
あなたは分析のプロであり、かつAmazonの商品出品者でもあります。今回、あるAmazonカテゴリーの商品一覧をお伝えします。商品のランキングは高い順に表示されています。全体的な傾向を分析、グルーピングし、最良の意見やアドバイスを約1000文字で述べてください。
カテゴリー：{0}

#商品データ：
`,
  "postfix": `
－－－

なお、文章の最後には「※この分析結果はAIによって生成されています。ベータ版であるため、分析結果が正確でない可能性があります。最終的な判断は、データを確認してからご自身で行ってください。」と必ず付け加えてください。
`,
  "seoResearch": `
あなたはAmazonのプロの出品者です。ある検索キーワードに対して、AmazonでSEO上位商品のタイトルの一覧をお伝えします。これらをすべて分析し、商品名ルール、禁止ワード、ノウハウに基づいて魅力的かつ最適な商品タイトルとSEOキーワードを1パターンを提案してください。またその理由も述べてください。

#商品名ルール

・文字数は全角50文字以内（アパレル系やジュエリーなどは全角65文字以内）
・商品タイトルは「メーカー+ブランド名+商品名+仕様(色・サイズ・タイプなど)+型番」で記載
・各項目は半角スペースで区切る

#商品名禁止ワード：

・英数字/ハイフンは半角での入力、半角カタカナ
・商品名と関係ない言葉や記号
・特殊文字や特殊記号、機種依存文字（㎏, ㍑,＃,＄,②,Ⅱ,☆など）
・使用禁止単語・フレーズ（セール、値引き率、送料無料など）
・最上級表現（最安値、日本一、世界一など）
・断定的表現（最高、完全、完璧など）
・時間的優位性表現（最新、Newなど）
・限定表現（期間限定、今だけなど）

#ノウハウ

・商品タイトルの長さは40文字前後で
・ビックワードを商品タイトルの先頭に寄せる
・一般的に良く検索されている名称にする

#キーワード：

{0}

#現在の上位商品タイトル（上から1位、2位、3位と続きます。）：
`,
  "seoResearchProduct": `
あなたはAmazonでプロの出品者です。以下のキーワードに関連するキーワードや商品を、箇条書きで提案してください。

#キーワード：

{0}
`,
  "reviewResearch": `
あなたはプロのAmazon出品者分析家です。以下の商品のレビュー一覧を分析し、ライバル出品者に対する意見やアドバイス、差別化ポイントを提案してください。回答は日本語で行ってください。

#商品名

{0}

#出力形式

【分析結果】

・（分析結果1）
・（分析結果2）
・（分析結果3）

【ライバル出品者へのアドバイス】

・（アドバイス1）
・（アドバイス2）
・（アドバイス3）

【差別化ポイントの提案】

・（提案1）
・（提案2）
・（提案3）

#レビュー一覧
`,
  "optimizeProductTitles": `
あなたはAmazonのプロの出品者です。Amazonで売られているある商品タイトルをお伝えします。これを分析し、商品名ルール、禁止ワード、ノウハウに基づいて商品タイトルを改善して、理由も述べてください。

#商品名ルール

・文字数は全角50文字以内（アパレル系やジュエリーなどは全角65文字以内）
・商品タイトルは「メーカー+ブランド名+商品名+仕様(色・サイズ・タイプなど)+型番」で記載
・各項目は半角スペースで区切る

#商品名禁止ワード：

・英数字/ハイフンは半角での入力、半角カタカナ
・商品名と関係ない言葉や記号
・特殊文字や特殊記号、機種依存文字（㎏, ㍑,＃,＄,②,Ⅱ,☆など）
・使用禁止単語・フレーズ（セール、値引き率、送料無料など）
・最上級表現（最安値、日本一、世界一など）
・断定的表現（最高、完全、完璧など）
・時間的優位性表現（最新、Newなど）
・限定表現（期間限定、今だけなど）

#ノウハウ

・商品タイトルの長さは40文字前後で
・ビックワードを商品タイトルの先頭に寄せる
・一般的に良く検索されている名称にする

＃商品タイトル

{0}
`,
  "generateProductSpecificationText": `
あなはたAmazonのプロの出品者です。商品タイトルとノウハウを元にAmazonの商品仕様文を5つの箇条書きで作成してください。

＃ノウハウ

・単に商品の特徴を列挙するのではなく、商品が生み出す結果に焦点を当ててください。
・わかりやい言葉を使ってください。
・特徴となる言葉を【】で文頭に記載しておくと魅力を伝えやすくなる
・適切な文字数：各150〜250字（文章が長すぎると、ユーザーがストレスを感じ読まれないので要注意）

＃商品タイトル

{0}
`,
  "optimizeProductSpecificationText": `
あなたはAmazonのプロの出品者です。Amazonで売られているある商品タイトルと仕様文をお伝えします。これを分析し、ノウハウに基づいて商品仕様文を改善して、理由とともに述べてください。

＃ノウハウ

・単に商品の特徴を列挙するのではなく、商品が生み出す結果に焦点を当ててください。
・わかりやい言葉を使ってください。
・特徴となる言葉を【】で文頭に記載しておくと魅力を伝えやすくなる
・適切な文字数：各150〜250字（文章が長すぎると、ユーザーがストレスを感じ読まれないので要注意）

＃商品タイトル

{0}

＃商品仕様文

{1}
`,
  "suggestDifferentiation": `
あなはたAmazonのプロの出品者です。ライバル出品者が販売している商品タイトルと商品仕様文を分析して、この出品者に勝つにために考えられる差別化ポイントと理由を述べてください。

＃商品タイトル

{0}

＃商品仕様文

{1}
`,
  "suggestSeoKeywords": `
あなはたAmazonのプロの出品者です。Amazonで売られているある商品タイトルをお伝えします。そのタイトルを元に上位表示を狙うためのSEOキーワードを箇条書きで提案してください。

＃商品タイトル

{0}
`,
}
