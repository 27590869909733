//import browseNodes from '@/assets/browseNodes.json'
import moment from 'moment'
import _ from "lodash";
import Encoding from 'encoding-japanese'
import { API, graphqlOperation, I18n } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { SizeTables } from "@/store/size.js";
import { RainforestConstants } from '@/store/rainforest.js';
import { CommissionTables } from '@/store/commission.js';
import { ProductInfo } from '@/mixins/ProductInfo';

export const ENV = 'prod';
export const ENVPROD = 'prod';
export const DOMAIN_COM = '1';
export const DOMAIN_COJP = '5';
export const DOMAIN_COM_NAME = '.com';
export const DOMAIN_COJP_NAME = '.co.jp';
export const OAUTH_DOMAIN = 'id.eresa.jp';
// 海外版ドメイン
const DEV_HOSTNAME_COM = 'devcom.d3q5486ablfp5z.amplifyapp.com';
const PROD_HOSTNAME_COM = 'search.eresa.io';
export const HOSTNAME_COM = ENV === ENVPROD ? PROD_HOSTNAME_COM : DEV_HOSTNAME_COM;
// websockアドレス
const DEV_WSS = 'wss://b5p90wls3k.execute-api.ap-northeast-1.amazonaws.com/dev';
const PROD_WSS = 'wss://6ezjdmjdg3.execute-api.ap-northeast-1.amazonaws.com/prod';
export const WSS = ENV === ENVPROD ? PROD_WSS : DEV_WSS;
// serverless-express
const DEV_EXPRESS = 'https://ulrw9fjvvl.execute-api.ap-northeast-1.amazonaws.com';
const PROD_EXPRESS = 'https://qju3fsi3k5.execute-api.ap-northeast-1.amazonaws.com';
export const EXPRESS = ENV === ENVPROD ? PROD_EXPRESS : DEV_EXPRESS;
export const EXPRESS_AGENT = 'eresa_web';
// stripe情報
export const STRIPE_DOMAIN_JP = 'jp';
export const STRIPE_DOMAIN_COM = 'io';
const DEV_COM_STRIPE_INFO = { priceIdMonth: 'price_1NXiZ7EfGpqN59upFOvMxak7', priceIdYear: 'price_1NXia1EfGpqN59upnQVjcI40' };
const DEV_JP_STRIPE_INFO = { priceIdMonth: 'price_1OmUmrKioXk4Y12Rxz7yeRiY', priceIdYear: 'price_1OmUp6KioXk4Y12RCSd3IkC9', priceIdCampaign: 'campaign_test' };
const PROD_COM_STRIPE_INFO = { priceIdMonth: 'price_1Msc3XEfGpqN59upI1OV5dru', priceIdYear: 'price_1Msc6gEfGpqN59uprLtkA7mG' };
const PROD_JP_STRIPE_INFO = { priceIdMonth: 'price_1OmQxqKioXk4Y12Rc3qeYd6r', priceIdYear: 'price_1OmR08KioXk4Y12RlF0XNZEO', priceIdCampaign: 'price_1Ood0cKioXk4Y12RPZp1mcw6' };
export const COM_STRIPE_INFO = ENV === ENVPROD ? PROD_COM_STRIPE_INFO : DEV_COM_STRIPE_INFO;
export const JP_STRIPE_INFO = ENV === ENVPROD ? PROD_JP_STRIPE_INFO : DEV_JP_STRIPE_INFO;
// 検索候補取得
export const DOMAIN_COJP_CANDIDATE_URL = 'https://completion.amazon.co.jp/api/2017/suggestions?mid=A1VC38T7YXB528&alias=aps&prefix=';
export const DOMAIN_COM_CANDIDATE_URL = 'https://completion.amazon.com/api/2017/suggestions?mid=ATVPDKIKX0DER&alias=aps&prefix=';
// Dplusアドレス
const DEV_DPLUS = 'https://d-tools.jp';
const PROD_DPLUS = 'https://dplus-tools.com';
export const DPLUS = ENV === ENVPROD ? PROD_DPLUS : DEV_DPLUS;

const CSV_NAME_INDEXES = ProductInfo.CSV_NAME_INDEXES;

export default {
  data: function () {
    return {
      DOMAIN_DEFAULT: this.isComEresa() ? DOMAIN_COM : DOMAIN_COJP,
    };
  },
  filters: {
    money(val) {
      return `${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"
        }${I18n.get('円')}`;
    },
    offerCount(val) {
      return val === null || val === undefined || val < 0 ? '-' : val;
    },
    ranking(val) {
      return val === null || val === undefined || val < 0 ? '-' : val;
    },
    // category(id, tree) {
    //   const name = tree.map((ele) => ele.name).join(' ');
    //   return name;
    // },
    dateFormatter(yyyymmdd) {
      if (yyyymmdd == void 0 || yyyymmdd < 0) {
        return '-'
      }
      const format = I18n.get('YYYY年MM月DD日');
      return moment(yyyymmdd, 'YYYYMMDD').format(format)
    }
  },
  methods: {
    getCategoryName(id, tree) {
      const names = [];
      if (!tree) return '';
      for (let i of tree) {
        names.push(i.name);
        if (i.catId === Number(id)) break;
      }
      return names.join('/');
    },
    getDateFromKeepaTime(keepaTime) {
      return new Date((Number(keepaTime) + 21564000) * 60000);
    },
    sleep(millisec) {
      return new Promise(resolve => setTimeout(resolve, millisec))
    },
    convertEncoding(str, charcode) {
      if (!str) return str
      const arr = str.split('').map((v) => v.charCodeAt());
      return Encoding.urlEncode(Encoding.convert(arr, charcode, 'UNICODE'));
      //return Encoding.convert(arr, charcode, 'UNICODE');
    },
    getLastKeepaHistory(oneCsv, isCart) {
      if (!oneCsv) return "-";
      const arr = !isCart
        ? _.last(_.chunk(oneCsv, 2).filter((a) => a[1] > 0))
        : _.last(
          _.chunk(oneCsv, 3)
            .filter((a) => a[1] > 0)
            .map((v) => [v[0], Number(v[1]) + Number(v[2])])
        ); // カート価格(idx=18)は3個目のデータがおそらく送料
      if (arr && arr.length > 1) return `${arr[arr.length - 1]}`;
    },
    getFBAFee(item, price) {
      const fbaFees = item.fbaFees;
      if (price != void 0 && this.isSmallAndLight(item, price)) {
        //SAL対象の場合はSAL優先
        return getFBAFeeFromSize(item, true);
      }
      else if (fbaFees == void 0) {
        return getFBAFeeFromSize(item);
      }
      return fbaFees.pickAndPackFee < 0 ? 0 : fbaFees.pickAndPackFee;
    },
    getCommission(item, price) {
      const domain = item.domainId;
      const cattree = item.categoryTree;
      const catId = cattree != void 0 && cattree.length > 0 ? cattree[0].catId : item.salesRankReference;
      if (price <= 0) {
        return 0;
      }
      if (domain == this.$store.getters.getComDomain) {
        return Math.round(calcCommission(domain,CommissionTables.CommissionTableCom, catId, cattree, price) * 100) / 100;
      }
      return Math.round(calcCommission(domain,CommissionTables.CommissionTableJp, catId, cattree, price));
    },
    isLowerCommisionItem(item) {
      if (!item.stats) {
        return false;
      }
      const domain = item.domainId;
      const cattree = item.categoryTree;
      const newprice = item.stats.current[CSV_NAME_INDEXES.NEW];
      if (domain != '5') {
        return false;
      }
      const lowerCommision = calcLowerCommissionCategory(domain, CommissionTables.CommissionTableJp, cattree, newprice);
      return lowerCommision == null ? false : true;
    },
    isDefaultDomain(domainId) {
      return this.getDomainId(domainId) == this.DOMAIN_DEFAULT;
    },
    isComDomain(domainId) {
      return this.getDomainId(domainId) != DOMAIN_COJP;
    },
    getDomainId(domainId) {
      return (domainId ?? this.DOMAIN_DEFAULT);
    },
    getDomainName(domainId) {
      return domainId == this.$store.getters.getComDomain ? DOMAIN_COM_NAME : DOMAIN_COJP_NAME;
    },
    getSizeTypeString(item) {
      if ((item.packageLength ?? 0) <= 0 || (item.packageWidth ?? 0) <= 0 || (item.packageHeight ?? 0) <= 0) {
        //サイズ不明
        return "";
      }
      const target = getSizeType(item);
      if (target != void 0) {
        return this.t(target.sname);
      }
      return this.t('規格外');
    },
    getListPrice(item) {
      const listprice = item.stats == void 0 ? -1 : item.stats.current[CSV_NAME_INDEXES.LISTPRICE];
      if (listprice >= 0) {
        return listprice;
      }
      if (item.csv == void 0 || item.csv[CSV_NAME_INDEXES.LISTPRICE] == void 0) {
        return listprice;
      }
      return this.getLastKeepaHistory(item.csv[CSV_NAME_INDEXES.LISTPRICE]);
    },
    isPremier(item) {
      if (item.stats == void 0) {
        return false;
      }
      const listprice = this.getListPrice(item);
      const newprice = item.stats.current[CSV_NAME_INDEXES.NEW];
      const usedprice = item.stats.current[CSV_NAME_INDEXES.USED];
      if (listprice <= 0) {
        //定価なしまたは無料商品→不明
        return false;
      }
      if (newprice > 0) {
        //新品が定価越え→プレミア
        return newprice > listprice;
      }
      //新品なしで中古が定価越え→プレミア
      return usedprice > listprice;
    },
    isSmallAndLight(item, price) {
      return isSal(item, price);
    },
    getCsvNameIndexes() {
      return CSV_NAME_INDEXES;
    },
    async getUsdJpy() {
      const query = await API.graphql(graphqlOperation(queries.exchangeRate, { currency: 'USD' }));
      const rates = JSON.parse(query.data.exchangeRate).rates;
      return rates.JPY;
    },
    createProductInfo(item) {
      return new ProductInfo(item);
    },
    downloadText(text, type, fileName) {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), text], { type });
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', fileName);
      const mouseEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
      anchor.dispatchEvent(mouseEvent);
    },
    isComEresa() {
      // 海外版ERESAからのアクセスかどうかを返す
      return window.location.hostname === HOSTNAME_COM;
    },
    getEresaDomain() {
      // jp or io
      return window.location.hostname === HOSTNAME_COM ? 'io' : 'jp';
    },
    t(text, newTexts) {
      let t = I18n.get(text).trim();
      if (newTexts != void 0) {
        let i = 0;
        for (const newText of newTexts) {
          t = t.replace(`{${i}}`, newText);
          i++;
        }
      }
      return t;
    },
    estimateSales(catid, domainId, x) {
      if (x < 1 || x > 10000) {
        return null;
      }
      const iscom = this.isComDomain(domainId);
      const catsrc = iscom ? RainforestConstants.CatalogCom : RainforestConstants.CatalogJp;
      const catalog = catsrc.find(item => item.catid === catid.toString());
      if (catalog == void 0 || catalog.rainforestCat == void 0) {
        return null;
      }

      const salesdata = RainforestConstants.SalesEstimation.find(item => item.cat === catalog.rainforestCat);
      if (salesdata == void 0) {
        return null;
      }

      const datax = salesdata.x;
      const datay = salesdata.y;
      const indices = [...generate(0, datax.length)]
        .map(i => [Math.pow(x - datax[i], 2), i])
        .sort((i1, i2) => i1[0] - i2[0])
        .map(d => d[1])
        .slice(0, 2);
      const x0 = datax[indices[0]];
      const x1 = datax[indices[1]];
      const y0 = datay[indices[0]];
      const y1 = datay[indices[1]];
      const a = (x - x0) / (x1 - x0);
      const y = parseInt(y0 + a * (y1 - y0));
      if (y <= 0) {
        return null;
      }
      return Math.round(y * (catalog.rate ?? 1.0));
    },
  },
};

function calcSpecialCommission(table, cattree, price) {
  const tax = 1 + (table.tax / 100);
  const cat = table.specialCategory.find(c => {
    if (cattree == void 0) {
      return false;
    }
    const r = cattree.map(tc => tc.catId.toString()).includes(c.catid);
    if (c.include == void 0) {
      return r;
    }
    return cattree.map(tc => tc.name).includes(c.include);
  });
  if (cat == void 0) {
    return null;
  }
  return (price * cat.rate / 100) * tax;
}
function calcLowerCommissionCategory(domain, table, cattree, price) {
  if (domain != '5') {
    return null;
  }
  if (isNaN(price) || price >= 750 || price <= 0) {
    return null;
  }
  const tax = 1 + (table.tax / 100);
  const isExcludeCategory = table.lowerCommisionExcludeCategory.find(c => {
    return cattree?.some(tc => tc.catId.toString() === c.catid);
  });

  if (isExcludeCategory) {
    return null;
  }

  const commission = Math.max((price * 0.05), 30) * tax;
  return commission;
}

function calcCommission(domain, table, catId, cattree, price) {
  const lowerPriceCommision = calcLowerCommissionCategory(domain, table, cattree, price);
  if (lowerPriceCommision != void 0) {
    return lowerPriceCommision;
  }

  const specialCommission = calcSpecialCommission(table, cattree, price);
  if (specialCommission != void 0) {
    return specialCommission;
  }
  const tax = 1 + (table.tax / 100);
  const formulaArr = [
    (price, rate, additional) => (price * rate + additional) * tax,
    (price, criteria, rate1, rate2) =>
      (price <= criteria ? price * rate1 : price * rate2) * tax,
    (price, criteria, rate1, rate2) =>
      (price <= criteria
        ? price * rate1
        : criteria * rate1 + (price - criteria) * rate2) * tax,
    (price, criteria, rate1, rate2) => {
      const rate = [rate1].concat(rate2);
      let commission = criteria.reduce((p, e, i, a) => p + (Math.min(price, e) - (i == 0 ? 0 : a[i - 1])) * rate[i], 0);
      const maxcriteria = criteria[criteria.length - 1];
      if (price > maxcriteria) {
        commission += (price - maxcriteria) * rate[rate.length - 1];
      }
      return commission * tax;
    },
  ];
  const commission = table.category;
  const cat = commission.find(c => c.catid == catId);
  if (cat == void 0) {
    return formulaArr[0](price, 0.15, 0);
  }
  else if (cat.criteria == void 0) {
    return formulaArr[0](price, cat.rate / 100, cat.additional ?? 0);
  }
  else if (cat.rate1 != void 0) {
    return formulaArr[1](price, cat.criteria * 100, cat.rate / 100, cat.rate1 / 100);
  }
  else if (cat.rate2 != void 0) {
    if (typeof cat.criteria === 'number') {
      return formulaArr[2](price, cat.criteria * 100, cat.rate / 100, cat.rate2 / 100);
    }
    return formulaArr[3](price, cat.criteria.map(c => c * 100), cat.rate / 100, cat.rate2.map(r => r / 100));
  }
  return formulaArr[0](price, cat.rate / 100, cat.additional ?? 0);
}

function getFBAFeeFromSize(item, sal = false) {
  if ((item.packageLength ?? 0) <= 0 || (item.packageWidth ?? 0) <= 0 || (item.packageHeight ?? 0) <= 0) {
    return 0;
  }
  const isCom = item.domainId == DOMAIN_COM;
  const table = isCom ? SizeTables.sizeCom : SizeTables.sizeJp;
  const target = getSizeType(item, sal) ?? table[table.length - 1];
  const munit = isCom ? 100 : 1; //アメリカ版はドル→セント
  if (target.add != void 0) {
    //追加料金有
    const wunit = isCom ? 0.00220462 : 0.001; //g→ポンド/kg
    const itemweight = item.packageWeight * wunit;
    const additional = Math.ceil(itemweight - (target.addfirst ?? 0));
    if (additional > 0) {
      return (target.fee + target.add * additional) * munit;
    }
  }
  return target.fee * munit;
}

function getSizeType(item, sal) {
  if ((item.packageLength ?? 0) <= 0 || (item.packageWidth ?? 0) <= 0 || (item.packageHeight ?? 0) <= 0) {
    return undefined;
  }
  let table = null;
  if (sal === true) {
    //FBA小型軽量商品プログラム適用あり（日本版商品のみ）
    table = item.domainId == DOMAIN_COJP ? SizeTables.salJp : [];
  }
  else {
    table = item.domainId == DOMAIN_COM ? SizeTables.sizeCom : SizeTables.sizeJp;
  }
  const lunit = item.domainId == DOMAIN_COM ? 25.4 : 10; //inch/cm→mm
  const wunit = item.domainId == DOMAIN_COM ? 453.592 : 1000; //ポンド/kg→g
  const target = table.find(v => {
    const size = (v.size ?? Infinity) * lunit;
    const length = (v.length ?? Infinity) * lunit;
    const width = (v.width ?? Infinity) * lunit;
    const height = (v.height ?? Infinity) * lunit;
    const weight = v.weight * wunit;
    return item.packageLength <= length
      && item.packageWidth <= width
      && item.packageHeight <= height
      && (item.packageLength + item.packageWidth + item.packageHeight) <= size
      && item.packageWeight <= weight;
  });
  return target;
}

function isSal(item, price) { //eslint-disable-line no-unused-vars
  return false; //SALは現在非対応
  /*
  //FBA小型軽量商品プログラムの適用判定
  const target = getSizeType(item, true);
  if (target == void 0) {
    return false;
  }
  //price(円／セント)なしの場合は価格は代表値としてAmazon価格or新品価格で判定
  let p = price ?? item.stats.current[CSV_NAME_INDEXES.AMAZON];
  if (p == void 0 || p <= 0) {
    p = item.stats.current[CSV_NAME_INDEXES.NEW];
  }
  if (p <= 0 || p > 1000) {
    return false;
  }
  if (item.isAdultProduct) {
    return false;
  }
  return true;
  */
}

function * generate(start, count) {
  let index = start;
  const end = start + count;
  while (index < end) {
    yield index++;
  }
}
