export default {
  "productInfoText": `
#{0}現在のデータ：

・カテゴリー：{1}
・カテゴリーランキング：{2}
・Amazon価格：{3}
・カートボックス価格：{4}
・新品最安値：{5}
・中古最安値：{6}
・新品出品者：{7}
・中古出品者：{8}
`,
  "avg90Text": `
#過去90日の平均データ：

・過去90日の平均カテゴリーランキング：{0}
・過去90日の新品平均最安値：{1}
・過去90日の中古平均最安値：{2}
・過去90日の新品平均出品者：{3}
・過去90日の中古平均出品者：{4}
`,
};
