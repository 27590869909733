<template>
  <div class="image-changer mb-5" v-if="item.imagesCSV">
    <div class="tab-content mb-4 text-center" id="pills-tabContent">
      <div
        class="tab-pane fade show active position-relative"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <img
          class="mainImage"
          :src="getImageSrc(item.imagesCSV.split(',')[0])"
          alt
          ref="mainImage"
        />
        <template v-if="flag != void 0">
          <img v-if="item.domainId == $store.getters.getComDomain" class="flag rounded-circle shadow" src="/img/flag_jp.png" alt :title="t('JP版の商品ページ')" @click="flagClicked($store.getters.getJpDomain, item)" />
          <img v-if="item.domainId == $store.getters.getJpDomain" class="flag rounded-circle shadow" src="/img/flag_com.png" alt :title="t('COM版の商品ページ')" @click="flagClicked($store.getters.getComDomain, item)" />
        </template>
        <template v-if="favorite != void 0">
          <!-- Change text-secondary class to text-danger when user click add-to-fav button -->
          <template v-if="fav">
            <button
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete from Favorite"
              @click="removeFavorite(item)"
              class="del-to-fav rounded-circle shadow"
            >
              <div>
                <svg
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                  ></path>
                </svg>
              </div>
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Add to Favorite"
              @click="addFavorite(item)"
              class="add-to-fav rounded-circle shadow"
            >
              <div style="fill: #42afe3">
                <svg
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                  ></path>
                </svg>
              </div>
            </button>
          </template>
        </template>
      </div>
    </div>

    <b-carousel fade controls class="carousel carousel-gray pt-2">
      <b-carousel-slide v-for="(g, i) in groups" class="d-flex" :key="`g${i}`">
        <template #img>
          <img
            v-for="(img, i) in g"
            :key="`img${i}`"
            class="subImage px-4 mx-2"
            :src="getImageSrc(img)"
            alt
            @click="$refs.mainImage.src = getImageSrc(img)"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import _ from "lodash";
import Utils from "@/mixins/utils";

export default {
  name: 'ImageChanger',
  props: ['item', 'favorite', 'flag'],
  mixins: [Utils],
  data() {
    return {
      groups: null,
      favorites: null,
      fav: false,
    };
  },
  async mounted() {
    this.groups = _.chunk(this.item.imagesCSV.split(','), 3);
    this.favorites = await this.$store.getters.getFavorites;
    this.fav = await this.favorites.hasFavorite(this.item.asin, this.item.domainId);
  },
  methods: {
    getImageSrc(imageFile) {
      return `https://images-na.ssl-images-amazon.com/images/I/${imageFile}`;
    },
    async addFavorite(item) {
      await this.favorites.addFavorite(this.$store.state.user.username, item.asin, item.title, item.domainId);
      this.fav = true;
    },
    async removeFavorite(item) {
      await this.favorites.removeFavorite(this.$store.state.user.username, item.asin, item.domainId);
      this.fav = false;
    },
    flagClicked(domainId, item) {
      this.$emit('flagClicked', domainId, item.asin);
    },
  },
};
</script>

<style scoped>
.mainImage {
  width: 100%;
  height: 100%;
  max-width: 180px;
  max-height: 180px;
  object-fit: contain;
}
.subImage {
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
}
.carousel {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.flag {
  position: absolute;
  width: 32px;
  height: 32px;
  object-fit: cover;
  left: 80%;
  top: 80%;
  cursor: pointer;
  z-index: 1;
}
</style>
