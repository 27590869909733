<template>
  <div class="page-content">
    <div class="container pb-3">
      <h1 class="font-weight-bold pb-5">{{ t('パスワード変更') }}</h1>

      <b-alert :show="errorMassage != ''" variant="danger">{{ errorMassage }}</b-alert>
      <div class="inputgroup">
        <div class="d-flex">
          <b-form-input type="password" v-model='password' :placeholder="t('現在のパスワードを入力してください。')" class="mb-2"></b-form-input>
        </div>
        <b-form-input type="password" v-model='newPassword' :placeholder="t('新パスワードを入力してください。')" class="mb-2"></b-form-input>
        <b-form-input type="password" v-model='newPasswordConfirm' :placeholder="t('新パスワードの確認を入力してください。')" class="mb-4"></b-form-input>
        <b-button class="btn bg-eresa text-white px-3" type="button" @click="changePassword">{{ t('パスワード変更') }}</b-button>
      </div>

      <b-modal ref="success" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" @ok="gotoAccount">
        {{ t('パスワードの変更が完了しました。') }}
      </b-modal>

    </div>
    <loading-screen v-if="isProcessing"></loading-screen>
  </div>
</template>
<script>
import { Auth, I18n } from 'aws-amplify';
import LoadingScreen from '@/components/LoadingScreen.vue';
import Utils from "@/mixins/utils";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
      isProcessing: false,
      errorMassage: '',
    };
  },
  mixins: [Utils],
  components: {
    LoadingScreen,
  },
  mounted() {
  },
  methods: {
    async changePassword() {
      this.errorMassage = '';
      if (this.password === '') {
        this.errorMassage = this.t('現在のパスワードを入力してください。');
        return;
      }
      if (this.newPassword === '') {
        this.errorMassage = this.t('新パスワードを入力してください。');
        return;
      }
      if (this.password === this.newPassword) {
        this.errorMassage = this.t('新パスワードが現在のパスワードと同じです。');
        return;
      }
      if (this.newPasswordConfirm !== this.newPassword) {
        this.errorMassage = this.t('新パスワードの確認を正しく入力してください。');
        return;
      }
      this.isProcessing = true;
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(cognitoUser, this.password, this.newPassword);
      }
      catch(e) {
        this.errorMassage = I18n.get(e.message);
        return;
      }
      finally {
        this.isProcessing = false;
      }
      this.$refs.success.show();
    },
    gotoAccount() {
      this.$router.push({ name: 'Account' });
    },
  },
};
</script>

<style scoped>
.inputgroup {
  max-width: 480px;
}
@media (max-width: 575.98px) {
  .inputgroup .form-control, .inputgroup .btn  {
    font-size: 10pt;
  }
}
</style>
