<template>
  <div class="page-content">
    <div class="container">
      <h1 class="font-weight-bold pb-5 d-none d-sm-block">{{ t('お気に入り＆閲覧履歴') }}</h1>
      <div class="font-weight-bold pb-5 title-sm d-sm-none">{{ t('お気に入り＆閲覧履歴') }}</div>
    </div>

    <div class="container mb-5 p-0">
      <b-card no-body class="font-weight-bold">
        <b-tabs pills card>
          <b-tab :title="t('お気に入り')" active>
            <favorites-page :subscriber="subscriber"></favorites-page>
          </b-tab>
          <b-tab :title="t('閲覧履歴')" @click="showBrowsingHistory = true">
            <browsing-history-page :subscriber="subscriber" :show="showBrowsingHistory"></browsing-history-page>
          </b-tab>
          <b-tab :title="t('セラー')" @click="showFavoriteSeller = true">
            <favorite-sellers-page :subscriber="subscriber" :show="showBrowsingHistory"></favorite-sellers-page>
          </b-tab>
          <b-tab v-if="subscriber === false" :title="t('検索条件')" disabled></b-tab>
          <b-tab v-if="subscriber === true" :title="t('検索条件')" @click="showSearchConditions = true">
            <search-conditions-page :show="showSearchConditions"></search-conditions-page>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import FavoritesPage from '@/components/FavoritesPage.vue';
import BrowsingHistoryPage from '@/components/BrowsingHistoryPage.vue';
import SearchConditionsPage from '@/components/SearchConditionsPage.vue';
import FavoriteSellersPage from '@/components/FavoriteSellersPage.vue';
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";

export default {
  name: 'Favorites',
  components: {
    FavoritesPage,
    BrowsingHistoryPage,
    SearchConditionsPage,
    FavoriteSellersPage
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      subscriber: null,
      showBrowsingHistory: false,
      showSearchConditions: false,
      showFavoriteSeller: false,
    }
  },
  async mounted() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.title-sm {
  font-size: 20px;
}
</style>
