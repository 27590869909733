<template>
  <div class="selector">
    <div v-for="(item, i) in items" class="text-center" :key="i">
      <b-button size="sm" class="ml-1 mt-1 bg-eresa">
        {{ item.text }} <b-badge variant="light" class="ml-1" @click="close(item.id)"><b-icon icon="x"></b-icon></b-badge>
      </b-button>
    </div>
    <b-button v-if="items.length > 0" size="sm" class="ml-1 mt-1 bg-clear" @click="clear">
      {{ t('クリア') }}
    </b-button>
  </div>
</template>

<script>
import Utils from '@/mixins/utils';

export default {
  name: 'ItemSelector',
  mixins: [Utils],
  data() {
    return {
      items: []
    };
  },
  methods: {
    add(id, text) {
      this.items.push({ id, text });
    },
    close(id) {
      const index = this.items.findIndex(cat => cat.id === id);
      this.items.splice(index, 1);
    },
    clear() {
      this.items.splice(0);
    },
    getItems() {
      return this.items;
    }
  },
};
</script>

<style scoped>
.selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.bg-clear {
  background-color: #379992;
}
</style>
