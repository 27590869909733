export default {
  "各種平均データ・売上個数予測": "Various average data and sales volume forecasts",
  "すべて": "All",
  "新品": "New",
  "中古": "Used",
  "コレクター": "Collector",
  "ランキング": "BSR",
  "30日": "30 days",
  "90日": "90 days",
  "180日": "180 days",
  "365日": "365 days",
  "全期間": "Entire period",
  "平均": "Average",
  "Amazon価格": "Amazon price",
  "カート価格": "Cart price",
  "新品最安値": "Lowest new price",
  "中古最安値": "Lowest used price",
  "コレクター最安値": "Lowest collector price",
  "プライム": "Prime",
  "プライム以外": "Non-Prime",
  "プライム以外（送料込）": "Non-Prime",
  "ほぼ新品": "Almost new",
  "非常に良い": "Very good",
  "良い": "Good",
  "可": "Acceptable",
  "新品出品数": "No. of new products",
  "中古出品数": "No. of used products",
  "コレクター出品数": "No. of collector products",
  "売上個数予測": "Sales Estimator",
  "週間": "Weekly",
  "月間": "Monthly",
  "※ランキング降下回数をご参考ください。": "*Please refer to the number of ranking drops.",
  "※こちらの数値は過去のデータを元に算出しております。一つの参考として活用ください。": "These figures are calculated based on historical data. Please use them as a reference.",
};
