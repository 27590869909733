<template>
  <div>
    <Loading
      v-if="!item || Object.keys(item).length === 0"
      class="container mb-5"
    ></Loading>
    <product
      v-else
      :item="item"
      :csv="csv"
      :stats="stats"
      :shopList="shopList"
      :isDetail="isDetail"
      :keepaGraphUrl="keepaGraphUrl"
    ></product>
  </div>
</template>

<script>
import Product from "@/components/Product.vue";
import Loading from "@/components/Loading.vue";
import axios from "axios";

export default {
  name: "Detail",
  components: {
    Product,
    Loading,
  },
  data: function () {
    return {
      item: null,
      csv: [],
      stats: {},
      shopList: [],
      keepaGraphUrl: "",
      isDetail: false,
    };
  },
  mixins: [],
  filters: {},
  created() {
    const setItem = (item) => {
      this.item = item;
      this.csv = item.csv;
      this.stats = item.stats;
    };
    this.getProduct()
      .then((item) => {
        setItem(item);
        this.keepaGraphUrl = 'https://eresa-html.s3-ap-northeast-1.amazonaws.com/graph.png';
        this.isDetail = true;
        return item;
      })
      .then(() => {
        this.getShopList()
          .then((shopList) =>
            shopList ? shopList.filter((e) => e !== null) : null
          )
          .then((shopList) => (this.shopList = shopList));
      })
      .catch((err) => console.error(err));

  },
  computed: {},
  methods: {
    async getProduct() {
      return axios.get('https://eresa-html.s3-ap-northeast-1.amazonaws.com/detail.json')
        .then((query) => query.data);
    },
    async getShopList() {
      return axios.get('https://eresa-html.s3-ap-northeast-1.amazonaws.com/shopList.json')
        .then((query) => query.data);
    },
  },
};
</script>
<style scoped>
</style>