<template>
  <div>
    <div v-if="asins === null && !showMessege" class="container mb-5">
      <Loading></Loading>
    </div>

    <div v-if="asins != void 0 && asins.length > 0" class="card shadow border-0 pb-4">
      <div class="card-body">
        <result-list :asins="asins" :key="resultListKey" :domainId="lastSearchDomain" nolink :noshoplist="noshoplist" :login="login"></result-list>
      </div>
    </div>
    <b-alert :show="showMessege" variant="info">{{lastSearchKeyword}}の検索に一致する商品はありませんでした。</b-alert>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Loading from "@/components/Loading.vue";
import Utils from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";
import { AppVersion } from "@/mixins/AppVersion";
import ResultList from "@/components/ResultList.vue";

const MAX_ROWS_DEFAULT = 10;

export default {
  name: "AppSearch",
  components: {
    Loading,
    ResultList,
  },
  mixins: [Utils, AuthUtil],
  data: function () {
    return {
      asins: [],
      resultListKey: 0,
      showMessege: false,
      lastSearchKeyword: null,
      lastSearchDomain: null,
      login: void 0,
      appver: void 0,
      noshoplist: void 0,
    };
  },
  async mounted() {
    const domainId = this.getDomainId(this.$route.query.domainId);
    const keyword = this.$route.query.keyword;
    const user = this.$route.query.user;
    const password = this.$route.query.password;
    this.login = this.$route.query.login == void 0 ? void 0 : '';
    this.appver = AppVersion.parse(this.$route.query.appver);
    this.noshoplist = this.appver.compareTo(AppVersion.parse('0.9.3')) >= 0 ? void 0 : '';
    if (domainId == void 0 || keyword == void 0) {
      return;
    }
    await this.search(domainId, keyword, user, password);
  },
  watch: {},
  computed: {},
  methods: {
    async search(domainId, keyword, user, password) {
      await this.signIn(user, password);

      if (keyword == void 0 || keyword === '') {
        console.info("input empty");
        return;
      }

      this.showMessege = false;
      this.lastSearchKeyword = keyword;
      this.lastSearchDomain = domainId;
      this.asins = null;
      const rslt = await API.graphql(
        graphqlOperation(queries.search, { words: this.lastSearchKeyword, domain: this.lastSearchDomain })
      );
      if (rslt.data.search == void 0) {
        this.showMessege = true;
        return;
      }
      this.resultListKey++;
      this.asins = [];
      rslt.data.search
        .slice(0, MAX_ROWS_DEFAULT)
        .forEach((item) => this.asins.push(JSON.parse(item).asin));
    },
  },
};
</script>

<style scoped>
</style>
