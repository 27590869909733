export default {
    "amazon": "Amazon",
    "mercari": "メルカリ",
    "rakuma": "ラクマ",
    "rakuten": "楽天",
    "yahoo_auction": "ヤフオク",
    "yahoo_shop": "Y!ショ",
    "au_pay": "AU Pay",
    "yahoo_mall": "Yモール",
    "qoo10": "Qoo10",
    "google": "Google"
}