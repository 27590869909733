<template>
  <div ref="control" class="control">
    <div class="overlay" @click="hide"></div>

    <div class="searchWrapper max-w-md">
      <!-- PC -->
      <div class="d-none d-md-block">
        <div class="d-flex flex-column search">
          <div class="d-flex">
            <div class="search input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <svg
                    width="1.1em"
                    height="1.1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    ></path>
                  </svg>
                </span>
              </div>
              <input
                ref="search"
                type="text"
                class="form-control py-4"
                :placeholder="t('商品名・ASIN・JAN')"
                :aria-label="t('商品名・ASIN・JAN')"
                aria-describedby="search-product"
                maxlength="200"
                v-model="searchWords"
                @keydown.enter="search"
                @keyup="searchKeyup"
                @blur="searchBlur"
              />
              <div class="input-group-append d-flex flex-column domainPart">
                <domain-selector :domain="$store.getters.getDomain" @selectDomain="selectDomain"></domain-selector>
              </div>
              <div class="input-group-append">
                <b-button
                  class="btn bg-eresa text-white px-3"
                  type="button"
                  @click="search"
                  id="search-product"
                  >{{ t('検索') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PC -->
      <!-- mobile -->
      <div class="d-md-none">
        <div class="d-flex flex-column search">
          <div class="d-flex">
            <div class="search input-group">
              <input
                ref="search"
                type="text"
                class="form-control py-4"
                :placeholder="t('商品名・ASIN・JAN')"
                :aria-label="t('商品名・ASIN・JAN')"
                aria-describedby="search-product"
                maxlength="200"
                v-model="searchWords"
                @keydown.enter="search"
                @keyup="searchKeyup"
                @blur="searchBlur"
              />
              <div class="input-group-append d-flex flex-column domainPart">
                <domain-selector :domain="$store.getters.getDomain" @selectDomain="selectDomain"></domain-selector>
              </div>
              <div class="input-group-append">
                <b-button
                  class="btn bg-eresa text-white px-3"
                  type="button"
                  @click="search"
                  id="search-product"
                  ><font-awesome-icon :icon="['fas', 'magnifying-glass']" style="font-size:24px" /></b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <candidate-window ref="candidateList" :parent-fixed="true" style="left: 40px" @selected="selectedKeyword" />
    </div>

  </div>
</template>

<script>
import CandidateWindow from '@/components/CandidateWindow.vue';
import DomainSelector from '@/components/DomainSelector.vue';
import Utils from '@/mixins/utils';

export default {
  name: 'SearchControl',
  components: {
    CandidateWindow,
    DomainSelector,
  },
  mixins: [Utils],
  data: function () {
    return {
      searchWords: null,
    };
  },
  methods: {
    show() {
      this.$refs.control.style.display = 'block';
    },
    hide() {
      this.$refs.control.style.display = 'none';
    },
    search() {
      if (this.searchWords) {
        this.hide();
        this.$router.push({
          name: 'Search',
          params: { words: this.searchWords },
        });
      }
    },
    selectDomain(domain) {
      this.$store.commit("setDomain", domain.toString());
    },
    searchKeyup(e) {
      const value = this.$refs.candidateList.select(e.keyCode);
      if (value.length > 0) {
        this.searchWords = value;
        return;
      }
      this.$refs.candidateList.showCandidates(e.target);
    },
    selectedKeyword(keyword) {
      this.searchWords = keyword;
      if (this.searchWords != void 0) {
        this.search();
      }
    },
    searchBlur(e) {
      if (e.relatedTarget != void 0) {
        this.$refs.candidateList.close();
      }
    },
  },
};
</script>

<style scoped>
.control {
  display: none;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 10000;
}

.searchWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 10001;
}

.domainPart {
  position: relative;
  background-color: #ddd;
}

.search {
  position: relative;
}

@media (max-width: 575.98px) {
  .searchWrapper {
    top: 20%;
    max-width: 90%;
  }
}
</style>
