<template>
  <div>
    <template v-if="subscriber === true">
      <header class="header bg-eresa" v-b-toggle.GoogleTrendGraph-collapseid @click="changeIcon">
        <b-icon :icon="headerIcon"></b-icon>
        <span class="ml-4 mr-4">Google Trends</span>
        <b-icon :icon="headerIcon"></b-icon>
      </header>
    </template>
    <template v-else-if="subscriber === false">
      <header class="header bg-eresa locktitle" style="opacity: 0.6">
        <span class="ml-4 mr-4">Google Trends<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></span>
        <lock-pro noicon></lock-pro>
      </header>
    </template>
    <b-collapse id="GoogleTrendGraph-collapseid">
      <div ref="graph" class="graph"></div>
    </b-collapse>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import LockPro from "@/components/LockPro.vue";

export default {
  name: "GoogleTrendGraph",
  components: { LockPro },
  props: ['subscriber'],
  data() {
    return {
      headerIcon: 'chevron-down',
      keyword: '',
      loaded: false,
    };
  },
  mixins: [Utils],
  mounted() {
  },
  methods: {
    async show(keyword) {
      if (this.subscriber !== true) {
        return;
      }
      this.keyword = keyword;
      if (this.headerIcon === 'chevron-down') {
        return;
      }
      const encodedkeyword = encodeURIComponent(keyword);
      const geo = this.isComEresa() ? 'US' : 'JP';
      const r = await fetch('/google_trend_graph.txt');
      const html = `<body>${await r.text()}</body>`
        .replace(`{keyword}`, keyword)
        .replace(/{geo}/g, geo)
        .replace(`{encodedkeyword}`, encodedkeyword);
      const iframe = document.createElement("iframe");
      iframe.style.border = '1px solid #ccc';
      iframe.style.minWidth = '320px';
      iframe.style.width = '60%';
      iframe.style.minHeight = '400px';
      this.$refs.graph.appendChild(iframe);
      const iframeDocument = iframe.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(html);
      iframeDocument.close();
      this.loaded = true;
    },
    async hide() {
      this.$refs.graph.innerText = '';
      this.loaded = false;
    },
    async changeIcon() {
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
      if (this.headerIcon === 'chevron-up' && !this.loaded) {
        await this.show(this.keyword);
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
  min-width: 320px;
  width: 60%;
  margin: 0 auto;
}
</style>
