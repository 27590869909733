<template>
  <b-modal ref="modal" size="xl" :title="t('AI分析')" ok-only centered :ok-title="t('Close')" @hidden="close">
    <div class="mt-4 result-content">
      {{ response }}
      <b-spinner v-if="loading" variant="primary" small></b-spinner>
      <b-icon v-if="chatting" class="square" animation="fade"></b-icon>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button id="AIChatWindow-copy" class="copy mr-2" :disabled="loading || chatting" @click="copy"><b-icon icon="clipboard"></b-icon> {{ t('コピー') }}</b-button>
        <b-tooltip ref="tooltipCopy" target="AIChatWindow-copy" :show.sync="tooltipCopy" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
        <b-button variant="primary" @click="$refs.modal.hide()"><b-icon icon="x"></b-icon> {{ t('閉じる') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil'
import { AIChat } from '@/mixins/AIChat'

export default {
  name: 'AIChatWindow',
  mixins: [Utils, AuthUtil],
  data() {
    return {
      response: '',
      loading: false,
      chatting: false,
      sock: null,
      tooltipCopy: false,
    };
  },
  methods: {
    async show(message, usegpt4 = false) {
      this.response = '';
      this.loading = true;
      this.chatting = false;
      this.$refs.modal.show();
      await this.showResult(message, usegpt4);
    },
    async close() {
      if (this.sock != void 0) {
        this.sock.close();
        this.sock = null;
      }
    },
    async showResult(message, usegpt4) {
      const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
      await sleep(100);
      try {
        const m = `${message}\n\n${this.t('postfix')}`;
        this.sock = AIChat.chatCompletionsStream(m, usegpt4,
          c => {
            if (this.loading) {
              this.loading = false;
              this.chatting = true;
              this.response = '';
            }
            this.response += c;
          },
          () => {
            this.loading = false;
            this.chatting = false;
            if (this.response.length <= 0) {
              this.response = this.t('エラーが発生しました。');
            }
        });
      }
      catch(e) {
        this.loading = false;
        this.chatting = false;
        this.response = this.t('エラーが発生しました。');
      }
    },
    copy() {
      // ※クリップボードはhttpsのみ動作
      navigator.clipboard.writeText(this.response).catch((e) => console.error(e));
      setTimeout(() => this.$refs.tooltipCopy.show = false, 1000);
    },
  },
};
</script>

<style scoped>
.result-content {
  white-space: pre-wrap;
}

.square {
  width: 0.5em;
  background-color: black;
}

.copy {
  background-color: #379992;
}
</style>
