<template>
  <div v-if="item.stats != void 0 && item.stats.current != void 0" :id="tableid">
    <div ref="table">
      <div class="price-table font-weight-bold">
        <div class="hr hrcond border-bottom" style="border-top-left-radius: 5px">{{ t('コンディション') }}</div>
        <div class="hr hrprice border-bottom text-center">{{ t('価格') }}
          <span v-if="nolink != void 0" class="small font-weight-bold">{{ t('（出品数）') }}</span>
          <router-link v-else
            :to="{ name: 'Offers', params: { asin: item.asin }, query: { isAll: 1, domain } }"
            class="itemlink small font-weight-bold"
            target="_blank"
          >{{ t('（出品数）') }}<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
          </router-link>
        </div>
        <div class="hr hrcalc text-center" style="border-top-right-radius: 5px">{{ t('損益分岐点') }}</div>
        <div class="hr hrfbm border-bottom text-center">{{ t('自社') }}</div>
        <div class="hr hrfba border-bottom text-center">FBA</div>
        <div class="border-bottom text-orange">
          <span v-if="nolink != void 0">Amazon</span>
          <a v-else role="button" rel="noreferrer" target="_blank" :href="`https://www.amazon${getDomainName(item.domainId)}/dp/${item.asin}/ref=nosim`" class="itemlink" style="text-decoration: underline">
            Amazon
            <b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
          </a>
        </div>
        <div class="border-bottom text-orange text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON]) }}</div>
        <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.AMAZON])) }}</div>
        <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.AMAZON] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.AMAZON]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.AMAZON])) }}</div>
        <div class="border-bottom d-flex justify-content-between">{{ t('新品') }}
          <div v-if="subscriber === true" class="d-inline-block">
            <b-spinner v-if="loadingNew" variant="primary" small></b-spinner>
            <b-icon class="subprice-icon ml-2" v-b-toggle="collapseidNew" :icon="newIcon" @click="changeIconNew"></b-icon>
          </div>
          <div v-else-if="subscriber === false" class="locktitle ml-2 subprice-icon">
            <b-icon icon="chevron-down"></b-icon>
            <lock-pro></lock-pro>
          </div>
        </div>
        <div class="border-bottom text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW]) }}
          <span v-if="nolink != void 0" class="small font-weight-bold">（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_NEW] | offerCount }}）</span>
          <router-link v-else
            :to="{ name: 'Offers', params: { asin: item.asin }, query: { isConditionNew: 1, domain } }"
            class="itemlink small font-weight-bold"
            target="_blank"
          >（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_NEW] | offerCount }}）<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
          </router-link>
        </div>
        <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW])) }}</div>
        <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW])) }}</div>
        <b-collapse :id="collapseidNew" class="subprice">
          <div class="subprice">
            <div class="border-bottom pl-2">{{ t('プライム') }}</div>
            <div class="border-bottom text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBA] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBA])) }}</div>
            <div class="border-bottom pl-2">{{ t('プライム以外') }}</div>
            <div class="border-bottom text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.NEW_FBM_SHIPPING])) }}</div>
          </div>
          <div class="note text-secondary small"><span class="pl-2"></span>{{ t('※送料込の価格になります。') }}</div>
        </b-collapse>
        <div class="border-bottom text-red d-flex justify-content-between" v-in-view="onInView">{{ showVeryGoodShipping ? t('非常に良い') : t('中古') }}          
          <div v-if="subscriber === true" class="d-inline-block">
            <b-spinner v-if="loadingUsed" variant="primary" small></b-spinner>
            <b-icon class="subprice-icon ml-2" v-b-toggle="collapseidUsed" :icon="usedIcon" @click="changeIconUsed"></b-icon>
          </div>
          <div v-else-if="subscriber === false" class="locktitle ml-2 subprice-icon">
            <b-icon icon="chevron-down"></b-icon>
            <lock-pro></lock-pro>
          </div>
        </div>
        <div class="border-bottom text-red text-right">{{ price( showVeryGoodShipping ? item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] : item.stats.current[CSV_NAME_INDEXES.USED]) }}
          <span v-if="nolink != void 0" class="small font-weight-bold">（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_USED] | offerCount }}）</span>
          <router-link v-else
            :to="{ name: 'Offers', params: { asin: item.asin }, query: { isConditionUsed: 1, domain } }"
            class="itemlink small font-weight-bold"
            target="_blank"
          >（{{ item.stats.current[CSV_NAME_INDEXES.COUNT_USED] | offerCount }}）<b-icon icon="box-arrow-up-right" class="small mb-1"></b-icon>
          </router-link>
        </div>
        <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED])) }}</div>
        <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED]) - getFBAFee(item)) }}</div>
        <b-collapse :id="collapseidUsed" class="subprice">
          <div class="subprice">
            <div class="border-bottom text-red pl-2">{{ t('ほぼ新品') }}</div>
            <div class="border-bottom text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_NEW_SHIPPING]) - getFBAFee(item)) }}</div>
            <div class="border-bottom text-red pl-2">{{ t('非常に良い') }}</div>
            <div class="border-bottom text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING]) - getFBAFee(item)) }}</div>
            <div class="border-bottom text-red pl-2">{{ t('良い') }}</div>
            <div class="border-bottom text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_GOOD_SHIPPING]) - getFBAFee(item)) }}</div>
            <div class="border-bottom text-red pl-2">{{ t('可') }}</div>
            <div class="border-bottom text-red text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING]) }}</div>
            <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING])) }}</div>
            <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING]) - getFBAFee(item)) }}</div>
          </div>
          <div class="note text-secondary small"><span class="pl-2"></span>{{ t('※送料込の価格になります。') }}</div>
        </b-collapse>
        <div class="border-bottom text-eresa">{{ t('カート') }}
          <img v-if="item.sellerCountryCode === 'US'" class="flag ml-1" src="/img/flag_com.png" alt />
          <img v-else-if="item.sellerCountryCode === 'JP'" class="flag ml-1 border" src="/img/flag_jp.png" alt />
          <img v-else-if="item.sellerCountryCode === 'CN'" class="flag ml-1" src="/img/flag_cn.png" alt />
        </div>
        <div class="border-bottom text-eresa text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING]) }}</div>
        <div class="fbm border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING])) }}</div>
        <div class="fba border-bottom text-secondary text-right">{{ price(item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING] - getCommission(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING]) - getFBAFee(item, item.stats.current[CSV_NAME_INDEXES.BUY_BOX_SHIPPING])) }}</div>
        <div>{{ t('参考価格') }}</div>
        <div class="text-right">{{ price(getListPrice(item)) }}</div>
        <div class="calcprofit price-row text-right text-secondary">
          <b-button v-b-toggle="collapseid" class="fbm mx-2 py-0 bg-eresa w-75"><b-icon icon="calculator" class="mr-1"></b-icon>{{ t('利益計算') }}</b-button>
        </div>
        <div class="calc">
          <b-collapse :id="collapseid" v-model="calcprofitVisible">
            <calc-profit :item="item"></calc-profit>
          </b-collapse>
        </div>
      </div>
    </div>
    <b-tooltip v-if="nofba" :target="tableid" placement="bottom"><span class="small">{{ t('FBA手数料が正常に取得ができないため、損益分岐点の計算が行われていません。お手数ですがFBA料金シミュレーターをご利用ください。') }}</span></b-tooltip>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Utils from "@/mixins/utils";
import AuthUtil from '@/mixins/authutil';
import CalcProfit from "@/components/Product/CalcProfit";
import LockPro from "@/components/LockPro.vue";
import inView from '@/components/InView';

export default {
  name: 'PriceTableSm',
  components: {
    LockPro,
    CalcProfit,
  },
  props: ['item', 'collapsekey', 'subscriber', 'nolink'],
  mixins: [Utils,AuthUtil],
  directives: {
    inView
  },
  data() {
    return {
      CSV_NAME_INDEXES: this.getCsvNameIndexes(),
      domain: this.isDefaultDomain(this.item.domainId) ? void 0 : this.item.domainId,
      collapseidNew: `pricetable-new-${this.collapsekey ?? this.item.asin}`,
      collapseidUsed: `pricetable-used-${this.collapsekey ?? this.item.asin}`,
      collapseid: `pricetable-calcprofit-${this.collapsekey ?? this.item.asin}`,
      tableid: `pricetable-tableid-${this.collapsekey ?? this.item.asin}`,
      newIcon: 'chevron-down',
      usedIcon: 'chevron-down',
      loadingNew: false,
      loadingUsed: false,
      calcprofitVisible: false,
      showVeryGoodShipping: false,
    }
  },
  computed: {
    nofba() {
      return this.item.fbaFees == void 0 && ((this.item.packageLength ?? 0) <= 0 || (this.item.packageWidth ?? 0) <= 0 || (this.item.packageHeight ?? 0) <= 0)
    }
  },
  watch: {
    item() {
      this.calcprofitVisible = false;
    }
  },
  async mounted() {
    this.calcprofitVisible = false;
    this.showVeryGoodShipping = await this.isOemUser();
  },
  methods: {
    async loadProductDetail(item, isNew) {
      if (!item._detailLoaded) {
        if (isNew) {
          this.loadingNew = true;
        }
        else {
          this.loadingUsed = true;
        }
        try {
          const r = await API.graphql(graphqlOperation(queries.getProductDetail, { asin: item.asin, isDetail: true, domain: item.domainId }));
          const res = JSON.parse(r.data.getProductDetail.json)[0];
          Object.keys(res).forEach((key) => this.$set(item, key, res[key]));
          item._detailLoaded = true;
        }
        finally {
          if (isNew) {
            this.loadingNew = false;
          }
          else {
            this.loadingUsed = false;
          }
        }
      }
    },
    price(val) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    async changeIconNew() {
      await this.loadProductDetail(this.item, true);
      this.newIcon = this.newIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    async changeIconUsed() {
      await this.loadProductDetail(this.item, false);
      this.usedIcon = this.usedIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
    },
    async onInView() {
      if(this.showVeryGoodShipping || await this.isOemUser()){ // inViewの発火タイミングの兼ね合いで直接権限を参照する
        this.loadProductDetail(this.item,false);
      }
    }
  },
};
</script>

<style scoped>
.price-table {
  display:grid;
  grid-template-columns: 1fr 10em 7em 7em;
}
.price-table > div, .subprice > div {
  padding: 3px;
  padding-bottom: 0px;
}

.price-table .hr {
  background-color: #f2f2f2;
  opacity: 0.6;
}
.price-table .hrcond, .price-table .hrprice {
  grid-row: 1/3;
  padding-top: 12px;
}
.price-table .hrcalc {
  grid-column: 3/5;
  padding-top: 0px;
  padding-bottom: 0px;
}
.price-table .hrfbm, .price-table .hrfba {
  padding-top: 0px;
  padding-bottom: 0px;
}
.price-table .hrcalc, .price-table .hrfbm, .price-table .hrfba {
  font-size: 9pt;
  padding-top: 0px;
}
.price-table .subprice {
  grid-column: 1/5;
  padding: 0px;
  display:grid;
  grid-template-columns: 1fr 10em 7em 7em;
}

.price-table .fbm, .price-table .fba {
  font-size: 8pt;
  padding-top: 7px;
}
.price-table .calcprofit {
  grid-column: 3/5;
}
.price-table .calc {
  grid-column: 1/5;
  margin: 0 0 0 auto;
}
.price-table .note {
  grid-column: 1/5;
}
.itemlink {
  color: inherit;
  text-decoration: underline;
}

.subprice-icon {
  width: 1em;
  height: 1em;
  top: 8px;
}

.flag {
  width: 18px;
  height: 12px;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .price-table {
    font-size: 8pt;
  }
  .price-table .hrcalc, .price-table .hrfbm, .price-table .hrfba {
    font-size: 6pt;
  }
  .price-table .fbm, .price-table .fba {
    font-size: 6pt;
    padding-top: 6px;
  }
}
</style>
