<template>
  <div>
    <b-container fluid class="form p-5">
      <b-row>
        <b-col class="mb-4">
          {{ t('アカウントを作成') }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field">{{ t('名前（会社名）') }}*
            <b-form-input class="mt-2" v-model="name" :placeholder="t('名前（会社名）を入力してください')" type="text" maxlength="100"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field">{{ t('メールアドレス') }}*
            <b-form-input class="mt-2" v-model="email" :placeholder="t('メールアドレスを入力してください')" type="email"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field mt-2">{{ t('パスワード') }}*
            <b-form-input class="mt-2" v-model="password" :placeholder="t('パスワードを入力してください')" type="password"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field mt-2">{{ t('会員種別') }}*
            <b-dropdown  :text="membershipTypeLabelList[selectedMembershipType]" split-variant="outline-primary" class="dropdown-eresa mt-2">
              <b-dropdown-item v-for="(item, i) in membershipTypeLabelList" :key="i" @click="selectedMembershipType = `${i}`">{{ item }}</b-dropdown-item>
            </b-dropdown>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field mt-2">{{ t('事業形態') }}*
            <b-form-group>
              <b-form-radio-group v-model="selectedOrganization" :options="organizationOptions"></b-form-radio-group>
            </b-form-group>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small class="navtext">{{ t('アカウントがある場合') }} <a role="button" href="#" @click="backToSignUp">{{ t('ログインに戻る') }}</a></small>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class='pt-2'>
            <input type="checkbox" id="confirm_terms_chk" v-model="checkTerms" />
            <small class="terms"><label for="confirm_terms_chk">{{ t('checklabelen') }}<a :href="pageTerms" target="_blank">{{ t('利用規約') }}</a>{{ t('checklabelja') }}</label></small>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="mt-5 register_button" variant="primary" :disabled="selectedOrganization == void 0 || !checkTerms" @click="register">{{ t('アカウント作成') }}</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small>{{ errorMassage }}</small>
        </b-col>
      </b-row>
    </b-container>
    <hr />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';
import Utils from '@/mixins/utils';

const PAGE_TERMS = 'https://eresa.jp/terms/';
const PAGE_COM_TERMS = 'https://eresa.io/terms-condition/';

export const ORG_PERSONAL = '0';
export const ORG_ORGANIZATION = '1';
export const COM_ORG_PERSONAL = '10';
export const COM_ORG_ORGANIZATION = '11';

export default {
  name: 'RegisterAccount',
  mixins: [Utils],
  data() {
    return {
      name: '',
      email: '',
      password: '',
      errorMassage: '',
      checkTerms: false,
      pageTerms: this.isComEresa() ? PAGE_COM_TERMS : PAGE_TERMS,
      organizationOptions: this.isComEresa()
        ? [{ text: this.t('個人・個人事業主'), value: COM_ORG_PERSONAL }, { text: this.t('法人・他組織'), value: COM_ORG_ORGANIZATION }]
        : [{ text: this.t('個人・個人事業主'), value: ORG_PERSONAL }, { text: this.t('法人・他組織'), value: ORG_ORGANIZATION }],
      selectedOrganization: null,
      membershipTypeLabelList: this.t('startplan').split('/'),
      selectedMembershipType: '0',
    };
  },
  mounted() {
    this.email = this.$route.query.email;
    const mt = this.$route.query.mt;
    if (mt == '1' || mt == '2') {
      this.selectedMembershipType = mt;
    }
    if (this.$route.query.page === 'confirmSignUp' && this.$route.query.email != void 0) {
      this.$router.push({ name: 'ConfirmSignUp', query: { email: this.email } });
      return;
    }
  },
  methods: {
    backToSignUp() {
      if (window.webkit != void 0 && window.webkit.messageHandlers.closePageHandler != void 0) {
        window.webkit.messageHandlers.closePageHandler.postMessage('');
      }
      this.$router.push({ name: 'login' });
    },
    async register() {
      this.errorMassage = '';
      try {
        if (this.name.length <= 0) {
          throw new Error('名前（会社名）を入力してください。');
        }
        const signUpParams = {
          username: this.email,
          password: this.password,
          clientMetadata: {
            domain: this.getEresaDomain(),
            aff_id: this.$route.query.aff_id,
            name: this.name,
            organization: this.selectedOrganization,
            membershipType: this.selectedMembershipType,
          }
        };
        await Auth.signUp(signUpParams);
        this.$router.push({ name: 'ConfirmSignUp', query: { email: this.email } });
      }
      catch(e) {
        this.errorMassage = I18n.get(e.message);
      }
    }
  },
};
</script>

<style scoped>
.form {
  min-width: 320px;
  max-width: 480px;
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}
.field {
  width: 100%;
  font-size: 14px;
}
.form-control {
  padding: 24px 16px 24px 16px !important;
}
::placeholder {
  font-size: 10pt;
}
.terms {
  font-size: 12pt;
}
.navtext {
  font-size: 9pt;
}
.navtext a {
  color: var(--link-color);
}
.register_button {
  background: linear-gradient(107.27deg, #4b82ed 0%, #1d4daa 100%);
  color: white;
  width: 100%;
}
</style>
