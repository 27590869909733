<template>
  <div class="d-flex pb-5">
    <h1 class="font-weight-bold d-none d-sm-block"><slot></slot></h1>
    <div class="font-weight-bold title-sm d-sm-none"><slot></slot></div>
    <a v-if="href" :href="href" target="_blank" class="ml-3 mt-1"><font-awesome-icon :icon="['fas', 'book']" style="color: #888; font-size: 20px" /></a>
  </div>
</template>

<script>
export default {
  name: 'ManualLink',
  props: ['href'],
};
</script>

<style scoped>
.title-sm {
  font-size: 20px;
}
</style>
