export default {
  "売れ筋ランキング": "Best-selling ranking",
  "現在ランク": "Current rank",
  "30日平均": "30-day average",
  "90日平均": "90-day average",
  "180日平均": "180-day average",
  "1日増減率": "Percentage change per day",
  "7日増減率": "7-day change ratio",
  "30日増減率": "30-day change ratio",
  "90日増減率": "90-day change ratio",
  "※増減率は-99～1000の範囲を指定できます。": "*The rate of increase or decrease can be specified in the range of -99 to 1000.",
  "在庫なし": "No stock",
  "在庫あり": "In stock",
  "発売前／予約注文可": "Pre-release / Pre-orders available",
  "不明": "Unknown",
  "入荷待ち": "Waiting list (waiting for goods to arrive)",
  "入荷遅延": "Introduction",
  "現在価格": "Current price",
  "新品": "New",
  "中古": "Used",
  "カート": "Cart",
  "参考価格": "Reference price",
  "現在価格なし ※検索結果の参考価格には直近の価格が表示される場合があります。": "No current price *Reference prices in search results may show the most recent price.",
  "新品(FBA)": "New (FBA)",
  "中古(FBA)": "Used (FBA)",
  "詳細条件": "Detailed conditions",
  "カテゴリー": "Category",
  "単一バリエーションのみ検索": "Search only single variations",
  "キーワード": "Keywords.",
  "商品名・ブランド名など": "Product name, brand name, etc.",
  "発売日": "Release date",
  "90日間の在庫切れ率": "90-day out-of-stock rate",
  "出品者数": "Number of exhibitors",
  "売上ランキング降下": "Falling sales ranking",
  "30日降下回数": "Number of 30-day drops",
  "90日降下回数": "Number of 90-day drops",
  "180日降下回数": "Number of 180-day drops",
  "30日降下率": "30-day drop rate",
  "90日降下率": "90-day drop rate",
  "180日降下率": "180-day drop rate",
  "評価": "Evaluation",
  "評価(1.0～5.0)": "Rating (1.0-5.0)",
  "レビュー数": "Number of reviews",
  "セラー": "Seller",
  "セラーID": "Seller ID",
  "カート取得セラー種別": "Cart acquisition seller type",
  "パッケージ寸法": "Package dimensions",
  "サイズ名": "Size name",
  "長さ(cm)": "Length (cm)",
  "幅(cm)": "Width (cm)",
  "高さ(cm)": "Height (cm)",
  "重量(kg)": "Weight (kg)",
  "検索条件を保存": "Save search criteria",
  "保存する": "Save",
  "キャンセル": "Cancel",
  "条件名を入力してください": "Please enter a condition name.",
  "リセット": "Reset",
  "検索": "Search",
  "から": "From",
  "まで": "To",
  "指定なし": "No designation",
  "Amazon以外（自己配送またはFBA）": "Non-Amazon (self-delivery or FBA)",
  "自己配送": "Self-delivery",
  "サイズ区分": "Size classification",
  "未選択": "Not selected",
  "検索条件を保存しました。お気に入りの検索条件からご利用いただけます。": "Your search criteria have been saved. You can use them from your favorite search criteria.",
}
