import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

/**
 * 検索条件を管理するクラス
 */
export class SearchConditions {
  /**
   * 条件一覧を取得します。
   * @param username
   * @returns
   */
  static async loadAll(username) {
    return await SearchConditions._load(username);
  }

  /**
   * 条件一覧を取得します。
   * @param username
   * @param domain
   * @returns
   */
  static async load(username, domain) {
    return (await SearchConditions.loadAll(username)).filter(item => item.domain == domain);
  }

  /**
   * 条件数を取得します。
   * @param username
   * @param domain
   * @returns
   */
  static async count(username, domain) {
    return (await SearchConditions.load(username, domain)).length;
  }

  /**
   * 条件を追加します。
   * @param username
   * @param name
   * @param condition
   * @param domain
   */
  static async add(username, name, condition, domain) {
    const conditions = await SearchConditions._load(username);
    const date = moment().utc().format();
    conditions.push({ id: crypto.randomUUID(), name, condition: JSON.stringify(condition), domain, date });
    await SearchConditions.update(username, conditions);
  }

  /**
   * 条件をクリアします。
   * @param username
   */
  static async clear(username) {
    await SearchConditions.update(username, []);
  }

  /**
   * 条件を追加または更新します。
   * @param username
   * @param conditions
   */
  static async update(username, conditions) {
    try {
      await API.graphql(
        graphqlOperation(mutations.updateSearchConditions, {
          input: {
            owner: username,
            conditions,
          },
        })
      );
    }
    catch {
      console.warn('no conditions');
      await API.graphql(
        graphqlOperation(mutations.createSearchConditions, {
          input: {
            owner: username,
            conditions,
          },
        })
      ).catch((err) => console.error(err));
    }
  }

  static async _load(username) {
    const query = await API.graphql(graphqlOperation(queries.getSearchConditions, { owner: username }));
    if (query.data.getSearchConditions != void 0) {
      return query.data.getSearchConditions.conditions.map(item => ({ id: item.id, name: item.name, condition: item.condition, domain: item.domain, date: item.date })) ?? [];
    }
    return [];
  }

  /**
   * 共有の条件を追加します。
   * @param username
   * @param conditionId
   * @returns id
   */
  static async addSharedSearchCondition(username, conditionId) {
    const conditions = await SearchConditions._load(username);
    const item = conditions.find(item => item.id === conditionId);
    if (item == void 0) {
      return;
    }
    const newId = crypto.randomUUID();
    await API.graphql(
      graphqlOperation(mutations.createSharedSearchCondition, {
        input: {
          id: newId,
          username,
          conditionId,
          name: item.name,
          condition: item.condition,
          domain: item.domain,
          date: item.date,
        },
      })
    );
    return newId;
  }

  /**
   * 共有の条件を取得します。
   * @param id
   * @returns
   */
  static async loadSharedSearchCondition(id) {
    const query = await API.graphql(graphqlOperation(queries.getSharedSearchCondition, { id }));
    return query.data.getSharedSearchCondition;
  }
}
