<template>
  <div>
    <div class="d-flex flex-wrap">
      <b-button v-if="subscriber === true && isShow(aiButtons.optimizeProductTitles)" size="sm" class="btn bg-eresa text-white mt-2 mr-2" @click="_optimizeProductTitles">{{ t('商品タイトルの最適化') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
      <lock-pro-button v-else-if="subscriber === false" :text="t('商品タイトルの最適化')" icon="fa-solid fa-brain" class="mt-2 mr-2"></lock-pro-button>

      <b-button v-if="subscriber === true && isShow(aiButtons.generateProductSpecificationText)" size="sm" class="btn bg-eresa text-white mt-2 mr-2" @click="_generateProductSpecificationText">{{ t('商品仕様文の生成') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
      <lock-pro-button v-else-if="subscriber === false" :text="t('商品仕様文の生成')" icon="fa-solid fa-brain" class="mt-2 mr-2"></lock-pro-button>

      <b-button v-if="subscriber === true && isShow(aiButtons.optimizeProductSpecificationText)" size="sm" class="btn bg-eresa text-white mt-2 mr-2" @click="_optimizeProductSpecificationText">{{ t('商品仕様文の最適化') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
      <lock-pro-button v-else-if="subscriber === false" :text="t('商品仕様文の最適化')" icon="fa-solid fa-brain" class="mt-2 mr-2"></lock-pro-button>

      <b-button v-if="subscriber === true && isShow(aiButtons.suggestDifferentiation)" size="sm" class="btn bg-eresa text-white mt-2 mr-2" @click="_suggestDifferentiation">{{ t('差別化ポイントの提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
      <lock-pro-button v-else-if="subscriber === false" :text="t('差別化ポイントの提案')" icon="fa-solid fa-brain" class="mt-2 mr-2"></lock-pro-button>

      <b-button v-if="subscriber === true && isShow(aiButtons.suggestSeoKeywords)" size="sm" class="btn bg-eresa text-white mt-2 mr-2" @click="_suggestSeoKeywords">{{ t('SEOキーワードの提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
      <lock-pro-button v-else-if="subscriber === false" :text="t('SEOキーワードの提案')" icon="fa-solid fa-brain" class="mt-2 mr-2"></lock-pro-button>
    </div>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import LockProButton from '@/components/LockProButton.vue';
import AiChatWindow from '@/components/AiChatWindow';
import Utils from '@/mixins/utils';
import { AIChat } from '@/mixins/AIChat';
import AiButtons from "@/mixins/AiButtons";

export default {
  name: 'ProductAiButtons',
  components: {
    LockProButton,
    AiChatWindow,
  },
  mixins: [Utils],
  props: ['item', 'subscriber'],
  data() {
    return {
      productDetailSettings: null,
      aiButtons: AiButtons,
    }
  },
  async mounted() {
    this.productDetailSettings = await this.$store.getters.getProductDetailSettings;
    if(!this.productDetailSettings) {
      this.productDetailSettings = {};
    }
  },
  methods: {
    /**
     * aiButtonが表示対象か判定
     * @param AiButton aiButton 対象のボタン
     * @return boolean
     */
    isShow (aiButton) {
      //有料会員でない場合はすべて表示
      if(!this.subscriber) {
        return true;
      }

      const displayAiButtonsIndex = this.productDetailSettings?.displayAiButtonsIndex;
      const isShow = displayAiButtonsIndex ? displayAiButtonsIndex[aiButton.name] : true; // 未設定の場合は表示
      return isShow ?? true;
    },
    _optimizeProductTitles() {
      const message = AIChat.createMessageFromProductAiButtons(this.item, 'optimizeProductTitles');
      this.$refs.aichat.show(message, true);
    },
    _generateProductSpecificationText() {
      const message = AIChat.createMessageFromProductAiButtons(this.item, 'generateProductSpecificationText');
      this.$refs.aichat.show(message, true);
    },
    _optimizeProductSpecificationText() {
      const message = AIChat.createMessageFromProductAiButtons(this.item, 'optimizeProductSpecificationText');
      this.$refs.aichat.show(message, true);
    },
    _suggestDifferentiation() {
      const message = AIChat.createMessageFromProductAiButtons(this.item, 'suggestDifferentiation');
      this.$refs.aichat.show(message, true);
    },
    _suggestSeoKeywords() {
      const message = AIChat.createMessageFromProductAiButtons(this.item, 'suggestSeoKeywords');
      this.$refs.aichat.show(message, true);
    },
  },
};
</script>

<style scoped>
</style>
