<template>
  <div class="condition mt-1">
    <div v-if="title != void 0" class="text-secondary valuelabel-xs small d-sm-none">{{ title }}</div>
    <div v-if="title != void 0" class="text-secondary valuelabel small d-none d-sm-inline">{{ title }}</div>
    <input type="text" class="form-control valuetext text-right ml-2 mr-2" maxlength="9" v-model="value" @change="valueChanged()" />
    <div class="d-flex flex-column">
      <b-button :pressed.sync="isgt" @click="gtclick" class="btngt mr-2 small d-sm-none">{{ t('以上') }}</b-button>
      <b-button :pressed.sync="islt" @click="ltclick" class="btnlt mr-2 small d-sm-none">{{ t('以下') }}</b-button>
      <b-button :pressed.sync="isgt" @click="gtclick" class="btngt mr-2 d-none d-sm-inline">{{ t('以上') }}</b-button>
      <b-button :pressed.sync="islt" @click="ltclick" class="btnlt mr-2 d-none d-sm-inline">{{ t('以下') }}</b-button>
    </div>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";

export default {
  name: 'TrackingValueCondition',
  mixins: [Utils],
  props: ['initvalue', 'csv', 'domain', 'title', 'hasSign', 'minvalue', 'maxvalue', 'coef', 'digit', 'description'],
  data: function () {
    return {
      value: null,
      isgt: false,
      islt: true,
    };
  },
  computed: {
    query: function() {
      const res = [];
      const c = this.coef == void 0 ? 1 : parseInt(this.coef);
      if (this.value != void 0) {
        for (const csv of this.csv.split(',')) {
          const q = [];
          q.push(`"thresholdValue": ${this.value * c}`);
          q.push(`"domain": ${this.domain}`);
          q.push(`"csvType": ${csv}`);
          q.push(`"isDrop": ${this.islt}`);
          res.push(JSON.parse(`{ ${q.join(',')} }`));
        }
        return res;
      }
      return null;
    }
  },
  async created() {
    if (this.initvalue != void 0) {
      this.value = this.initvalue;
    }
  },
  methods: {
    formatNumber(value, digit) {
      if (digit != void 0) {
        const d = Math.pow(10, digit);
        return Math.floor(value * d) / d;
      }
      return parseInt(value);
    },
    valueChanged() {
      if (this.value != void 0) {
        let value = this.formatNumber(this.value.replace(/,/g, ''), this.digit);
        if (this.hasSign == void 0 && value < 0) {
          value = 0;
        }
        if (this.minvalue != void 0) {
          value = Math.max(value, this.minvalue);
        }
        if (this.maxvalue != void 0) {
          value = Math.min(value, this.maxvalue);
        }
        this.value = isNaN(value) ? null : value;
      }
    },
    gtclick() {
      if (this.islt) {
        this.isgt = true;
        this.islt = false;
      }
      else {
        this.isgt = false;
        this.islt = true;
      }
    },
    ltclick() {
      if (this.isgt) {
        this.isgt = false;
        this.islt = true;
      }
      else {
        this.isgt = true;
        this.islt = false;
      }
    },
    clear() {
      this.value = null;
      this.isgt = false;
      this.islt = true;
    },
    setValue(data) {
      if (data.thresholdValues != void 0) {
        for (const item of data.thresholdValues) {
          const value = parseInt(item.thresholdValueCSV[item.thresholdValueCSV.length - 1])
          if (item.csvType == this.csv && value >= 0) {
            const c = this.coef == void 0 ? 1 : parseInt(this.coef);
            this.value = this.formatNumber(value / c, this.digit);
            this.isgt = !item.isDrop;
            this.islt = item.isDrop;
            break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.condition {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.valuelabel {
  margin-top: 10px;
}
.valuelabel-xs {
  margin-top: 10px;
  min-width: 120px;
  max-width: 140px;
}
.valuetext {
  width: 72px;
  padding: 4px;
  border-bottom-style: solid;
  border-color: transparent transparent #000 transparent;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance:none;
  font-size: 12pt;
}
.btngt {
  background-color: #ccc;
  border-color: #ccc;
  font-size: 8pt;
  padding: 0px 2px 0px 2px;
}
.btngt.active {
  background-color: #b9575e !important;
  border-color: #79171e !important;
}
.btngt.small {
  font-size: 6pt;
}
.btnlt {
  background-color: #ccc;
  border-color: #ccc;
  font-size: 8pt;
  padding: 0px 2px 0px 2px;
}
.btnlt.active {
  background-color: #57b9b2 !important;
  border-color: #177972 !important;
}
.btnlt.small {
  font-size: 6pt;
}
</style>
