import _ from 'lodash';
import moment from 'moment';
import utils from '@/mixins/utils';

export const CSV_NAME_INDEXES = {
  AMAZON: 0, //  Amazon price history
  NEW: 1, //  Marketplace New price history.
  USED: 2, //  Marketplace Used price history
  SALES: 3, //  Sales Rank history. Not every product has a Sales Rank. Variation items usually do not have individual sales ranks.
  LISTPRICE: 4, //  List Price history
  COLLECTIBLE: 5, //  Collectible price history
  REFURBISHED: 6, //  Refurbished price history
  NEW_FBM_SHIPPING: 7, //  3rd party (not including Amazon) New price history including shipping costs, only fulfilled by merchant (FBM).
  LIGHTNING_DEAL: 8, //  Lightning Deal price history. Lightning deals are special, relevant important information below.
  WAREHOUSE: 9, //  Amazon Warehouse price history.
  NEW_FBA: 10, //  Price history of the lowest 3rd party (not including Amazon/Warehouse) New offer that is fulfilled by Amazon
  COUNT_NEW: 11, //  New offer count history (= count of marketplace merchants selling the product as new)
  COUNT_USED: 12, //  Used offer count history
  COUNT_REFURBISHED: 13, //  Refurbished offer count history
  COUNT_COLLECTIBLE: 14, //  Collectible offer count history
  EXTRA_INFO_UPDATES: 15, //  History of past updates to all offers-parameter related data
  RATING: 16, //  The product’s rating history. A rating is an integer from 0 to 50 (e.g. 45 = 4.5 stars)
  COUNT_REVIEWS: 17, //  The product’s review count history.
  BUY_BOX_SHIPPING: 18, //  The price history of the New buy box. If no offer qualified for the buy box (or if the buy box is an used offer) the price has the value -1. Including shipping costs.
  USED_NEW_SHIPPING: 19, //  “Used - Like New” price history including shipping costs.
  USED_VERY_GOOD_SHIPPING: 20, //  “Used - Very Good” price history including shipping costs.
  USED_GOOD_SHIPPING: 21, //  “Used - Good” price history including shipping costs.
  USED_ACCEPTABLE_SHIPPING: 22, //  “Used - Acceptable” price history including shipping costs.
  COLLECTIBLE_NEW_SHIPPING: 23, //  “Collectible - Like New” price history including shipping costs.
  COLLECTIBLE_VERY_GOOD_SHIPPING: 24, //  “Collectible - Very Good” price history including shipping costs.
  COLLECTIBLE_GOOD_SHIPPING: 25, //  “Collectible - Good” price history including shipping costs.
  COLLECTIBLE_ACCEPTABLE_SHIPPING: 26, //  “Collectible - Acceptable” price history including shipping costs.
  REFURBISHED_SHIPPING: 27, //  Refurbished price history including shipping costs.
  EBAY_NEW_SHIPPING: 28, //  price history of the lowest new price on the respective eBay locale, including shipping costs.
  EBAY_USED_SHIPPING: 29, //  price history of the lowest used price on the respective eBay locale, including shipping costs.
  TRADE_IN: 30, //  the trade in 27 price history. Amazon trade-in is not available for every locale.
  RENTAL: 31, //  Rental price history. Requires use of the rental and offers parameter. Amazon Rental is only available for Amazon US.
};

const DOMAIN_COM = '1';

/**
 * 商品情報
 */
export class ProductInfo {
  /**
   * @constructor
   * @param item string
   */
  constructor(item) {
    this._item = item;
  }

  /**
   * CSV_NAME_INDEXES
   */
  static get CSV_NAME_INDEXES() {
    return CSV_NAME_INDEXES;
  }

  /**
   * item
   */
  get item() {
    return this._item;
  }

  /**
   * パッケージサイズを表す文字列
   */
  get packageSizeString() {
    return `${this._lengthStr(this.item.packageLength)} x ${this._lengthStr(this.item.packageWidth)} x ${this._lengthStr(this.item.packageHeight)} ${this._weightStr(this.item.packageWeight)}`;
  }

  /**
   * パッケージサイズを表す文字列配列
   */
  get packageSizeStrings() {
    return [this._lengthStr(this.item.packageLength), this._lengthStr(this.item.packageWidth), this._lengthStr(this.item.packageHeight), this._weightStr(this.item.packageWeight)];
  }

  /**
   * 容積重量（航空）を表す文字列
   */
  get volumetricWeightString() {
    const weight = this.item.packageLength * this.item.packageWidth * this.item.packageHeight / 6000;
    //NaNの場合は-kgを返す
    if (isNaN(weight)) {
      return '-kg';
    }
    
    const weightKg = weight / 1000;
    const roundWeight = Math.ceil(weightKg * 2) / 2;
    return `${roundWeight}kg`;
  }

  /**
   * 商品サイズを表す文字列
   */
  get itemSizeString() {
    return `${this._lengthStr(this.item.itemLength)} x ${this._lengthStr(this.item.itemWidth)} x ${this._lengthStr(this.item.itemHeight)} ${this._weightStr(this.item.itemWeight)}`;
  }

  /**
   * JAN/EANコードを表す文字列
   */
  get eanString() {
    return this.item.eanList ? this.item.eanList[0] : '-';
  }

  /**
   * 発売日を表す文字列
   */
  get releaseDateString() {
    return this.dateFormat(this.item.releaseDate);
  }

  /**
   * 取り扱い開始日を表す文字列
   */
  get listedSinceString() {
    const listedSince = this.item.listedSince > 0 ? this._getYYYYMMDDFromKeepaTime(this.item.listedSince) : this.item.releaseDate;
    return this.dateFormat(listedSince);
  }

  /**
   * 取り扱い開始日からの月数
   */
  get listedMonths() {
    const listedSince = this.item.listedSince > 0 ? this._getYYYYMMDDFromKeepaTime(this.item.listedSince) : this.item.releaseDate;
    if (listedSince == void 0 || listedSince < 0) {
      return void 0;
    }
    return moment().diff(moment(listedSince, 'YYYYMMDD'), 'months');
  }

  /**
   * ランキング、カテゴリーを表す文字列
   */
  get ranking() {
    if (this.item.salesRanks != void 0) {
      const cat = this.item.categoryTree?.find(c => this.item.salesRanks[c.catId]);
      if (this.item.salesRankReference >= 0 && cat == void 0) {
        return { catId: this.item.salesRankReference, ranking: _.last(_.flatten(_.chunk(this.item.csv[3], 2).filter(s => s[1] > 0))) ?? -1 };
      }
      else if (this.item.categoryTree != void 0) {
        for (const category of this.item.categoryTree) {
          if (this.item.salesRanks[category.catId] != void 0) {
            return { catId: category.catId, ranking: _.last(_.flatten(_.chunk(this.item.salesRanks[category.catId], 2).filter(s => s[1] > 0))) ?? -1 };
          }
        }
      }
    }
    return { catId: { catId: '-' }, ranking: -1 };
  }

  /**
   * 価格を文字列で返します。
   * @param index numeric
   * @param valueOnly boolean 単位なしの価格のみ
   * @returns 価格を表す文字列
   */
  priceString(index, valueOnly = false) {
    return ProductInfo.price(this.item.domainId, this.item.stats.current[index], valueOnly);
  }

  /**
   * 価格を文字列で返します。
   * @param domainId string
   * @param val numeric
   * @param valueOnly boolean 単位なしの価格のみ
   * @returns 価格を表す文字列
   */
  static price(domainId, val, valueOnly = false) {
    if (domainId == DOMAIN_COM) {
      const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      const unit = valueOnly ? '' : '$';
      return `${unit}${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : '-'}`;
    }
    else {
      const unit = valueOnly ? '' : utils.methods.t('円');
      return `${val !== null && val >= 0 ? Number(val).toLocaleString() : '-'}${unit}`;
    }
  }

  /**
   * csvデータの平均値を返します。
   * @param days numeric 30 or 90 or 180 or 365 or -1(ALL)
   * @param index csv index
   * @returns 平均値 or -1（データなし）
   */
  getCsvAvg(days, index) {
    if (![-1, 30, 90, 180, 365].includes(days)) {
      throw new Error('invalid argument: days');
    }
    const shipping = _.inRange(
      index,
      CSV_NAME_INDEXES.BUY_BOX_SHIPPING,
      CSV_NAME_INDEXES.EBAY_USED_SHIPPING
    ) || index === CSV_NAME_INDEXES.NEW_FBM_SHIPPING;
    const data = this._convertDateHistory(this.item.csv[index], days, shipping).map(v => v[1]).filter(v => v > 0);
    return data.length > 0 ? Math.round(data.reduce((p, c) => p + c) / data.length) : -1;
  }

  /**
   * ランキングの平均値を返します。
   * @param days numeric 30 or 90 or 180 or 365 or -1(ALL)
   * @returns { value, cat }
   */
  getSalesAvg(days) {
    if (![-1, 30, 90, 180, 365].includes(days)) {
      throw new Error('invalid argument: days');
    }
    const km90 = (new Date(new Date().toISOString().slice(0, 10)).getTime() - 90 * 1000 * 3600 * 24) / 60000 - 21564000;
    if (this.item.salesRankReference < 0 && this.item.categoryTree != void 0) {
      const bestIndex = this.item.categoryTree.map(c => {
        const salesRank = this.item.salesRanks == void 0 ? void 0 : this.item.salesRanks[c.catId];
        return salesRank == void 0 ? -1 : _.last(_.flatten(_.chunk(salesRank, 2).filter(s => s[0] >= km90).filter(s => s[1] > 0))) ?? -1;
      }).reduce((p, e, i) => p < 0 ? (e < 0 ? -1 : i) : p, -1);
      if (bestIndex >= 0) {
        const cat = this.item.categoryTree[bestIndex];
        const rankingData = this._convertDateHistory(this.item.salesRanks[cat.catId], days).map(v => v[1]).filter(v => v > 0);
        const value = rankingData.length > 0 ? Math.round(rankingData.reduce((p, c) => p + c) / rankingData.length) : -1;
        return { value, cat };
      }
    }
    if (days < 0) {
      const value = this.getCsvAvg(days, CSV_NAME_INDEXES.SALES);
      return { value, cat: this.item.categoryTree != void 0 && this.item.categoryTree.length > 0 ? this.item.categoryTree[0] : null };
    }
    return { value: this.item.stats[`avg${days}`][CSV_NAME_INDEXES.SALES], cat: this.item.categoryTree != void 0 && this.item.categoryTree.length > 0 ? this.item.categoryTree[0] : null };
  }

  /**
   * 日毎のカート履歴を返します。
   * @param days numeric 30 or 90 or 180 or 365
   * @returns array [[date, sellerId]]
   */
  getBuyBoxHistory(days) {
    if (![30, 90, 180, 365].includes(days)) {
      throw new Error('invalid argument: days');
    }
    if (this._buyBoxHistory == void 0) {
      const arr = _.chunk(this.item.buyBoxSellerIdHistory, 2).map(([d, v]) => [moment(this.getDateFromKeepaTime(d)).format('YYYY-MM-DD HH:mm:ss'), v]);
      let prevSeller = -1;
      const data = [];
      const baseDate = moment().format('YYYY-MM-DD');
      for (let i = 365; i >= 0; i--) {
        const m = moment(baseDate).add(-i, 'days');
        const values = arr.filter(v => moment(v[0].slice(0, 10)).diff(m) === 0);
        if (values.length <= 0) {
          //データなし期間の補完
          data.push([m.format('YYYY-MM-DD HH:mm:ss'), prevSeller]);
        }
        else {
          //日毎の最後のエントリーを代表値に設定
          const value = values[values.length - 1];
          data.push(value);
          prevSeller = value[1];
        }
      }
      this._buyBoxHistory = data;
    }
    const m = moment().add(-days, 'days');
    return this._buyBoxHistory.filter(v => moment(v[0]).diff(m) >= 0);
  }

  /**
   * 日毎の履歴を返します。
   * @param historyData array [[date, value]]
   * @param days numeric 30 or 90 or 180 or 365 or -1(ALL)
   * @param shipping _SHIPPING形式のデータ
   * @returns array [[date, value]]
   */
  _convertDateHistory(historyData, days, shipping = false) {
    if (![-1, 30, 90, 180, 365].includes(days)) {
      throw new Error('invalid argument: days');
    }
    const km = (new Date(new Date().toISOString().slice(0, 10)).getTime() - days * 1000 * 3600 * 24) / 60000 - 21564000;
    let chunk = shipping ? _.chunk(historyData, 3) : _.chunk(historyData, 2);
    if (days > 0) {
      chunk = chunk.filter(s => s[0] >= km);
    }
    let arr = chunk.map(([d, v, s]) => [moment(this.getDateFromKeepaTime(d)).format('YYYY-MM-DD HH:mm:ss'), s == void 0 ? v : (v > 0 ? v + (s > 0 ? s : 0) : v)]);
    const data = [];
    for (const item of arr) {
      if (data.length > 0 && data[data.length - 1][0].slice(0, 10) === item[0].slice(0, 10)) {
        continue;
      }
      const values = arr.filter(v => v[0].slice(0, 10) === item[0].slice(0, 10));
      // 日毎の最後に有効なエントリーを代表値に設定
      const value = values.reverse().find(v => v[1] >= 0) ?? [item[0], -1];
      data.push(value);
    }
    return data;
  }

  _lengthStr(length) {
    return `${length > 0 ? length / 10 : '-'}cm`;
  }

  _weightStr(weight) {
    if (weight == void 0 || weight <= 0) {
      return '-kg';
    }
    if (weight < 100) {
      return `${weight}g`;
    }
    return `${Math.round(weight / 10) / 100}kg`;
  }

  getDateFromKeepaTime(keepaTime) {
    return new Date((Number(keepaTime) + 21564000) * 60000);
  }

  dateFormat(yyyymmdd) {
    if (yyyymmdd == void 0 || yyyymmdd < 0) {
      return '-';
    }
    return moment(yyyymmdd, 'YYYYMMDD').format(utils.methods.t('YYYY年MM月DD日'));
  }

  _getYYYYMMDDFromKeepaTime(keepaTime) {
    if (keepaTime > 0) {
      return moment((Number(keepaTime) + 21564000) * 60000).format('YYYYMMDD');
    }
    return void 0;
  }
}
