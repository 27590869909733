export default {
    "検索設定": "検索設定",
    "商品リサーチ詳細検索の既定のページ当たり件数": "商品リサーチ詳細検索の既定のページ当たり件数",
    "カテゴリーランキングTOP1000の既定の期間": "カテゴリーランキングTOP1000の既定の期間",
    "現在ランク": "現在ランク",
    "過去30日平均": "過去30日平均",
    "過去90日平均": "過去90日平均",
    "過去180日平均": "過去180日平均",
    "10件": "10件",
    "50件": "50件",
    "100件": "100件",
    "150件": "150件",
    "200件": "200件",
}
