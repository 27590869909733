export default {
  methods: {
    setAdScript(ifad, id, adid, script) {
      const adscr = document.createElement('script');
      adscr.innerHTML = script;

      const adLink = document.getElementById(id);
      adLink.appendChild(adscr);
      setTimeout(() => {
        const ad = ifad.document.getElementById(adid);
        adLink.appendChild(ad);
        const a = ad.childNodes[1];
        const img = a.firstChild;
        if (img != void 0) {
          img.style.maxWidth = '100%';
          img.style.height = 'auto';
        }
        ad.style.display = 'block';
      }, 500);
    }
  }
};
