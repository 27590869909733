<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import Utils from "@/mixins/utils";

export default {
  extends: Line,
  name: "LineChart",
  //props: ["options"],
  props: ['domain', 'hasRight'],
  mixins: [reactiveProp, Utils],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 0 },
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'linear',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD'
              },
              tooltipFormat: 'YYYY/MM/DD'
            },
            ticks: {
              source: 'auto',
              autoSkip: true,
              autoSkipPadding: 75,
              maxRotation: 0,
              sampleSize: 100
            },
          }],
          yAxes: [{
            id: 'yleft',
            position: 'left',
            gridLines: {
              drawBorder: true
            },
            scaleLabel: {
              display: true,
            }
          }]
        },
        tooltips: {
          intersect: false,
          mode: 'index',
          callbacks: {
            label: this.domain == this.$store.getters.getComDomain ? this.tooltipsCallbackCom : this.tooltipsCallback
          }
        }
      },
    };
  },
  mounted() {
    if (this.hasRight != void 0) {
      this.options.scales.yAxes.push({
        id: 'yright',
        position: 'right',
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          fontColor: '#666',
        },
      });
    }
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    tooltipsCallbackCom(tooltipItem, myData) {
      var label = myData.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      const unit = label.indexOf(this.t('ランキングpart')) > -1 ? '{0}位' : label.indexOf(this.t('出品数part')) > -1 ? '{0}' : '${0}';
      const dec = unit === '${0}' ? 2 : 0;
      label += this.t(unit, [`${parseFloat(tooltipItem.value).toFixed(dec)}`]);
      return label;
    },
    tooltipsCallback(tooltipItem, myData) {
      var label = myData.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      const unit = label.indexOf(this.t('ランキングpart')) > -1 ? '{0}位' : label.indexOf(this.t('出品数part')) > -1 ? '{0}' : '{0}円';
      label += this.t(unit, [`${parseFloat(tooltipItem.value).toFixed(0)}`]);
      return label;
    },
  }
};
</script>